























































  import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
  import ImageContainer from "./components/imageContainer.vue"
  import draggable from "@/../node_modules/vuedraggable/src/vuedraggable.js";

@Component({
  components: {
    "image-container": ImageContainer,
    "draggable": draggable
  }
})
export default class PCVImageGallery extends Vue {

  IsClear: boolean = false;
  m_drag: boolean = false;
  m_listImage: any[] = [];
  m_selectionUnique: any = {};

  @Prop({ type: Array, default: () => [] }) readonly listImage!: object[];
  @Prop({ type: Number, default: 0 }) readonly canSelect!: number;
  @Prop({ type: Number, default: 1 }) readonly selectMultiple!: number;
  @Prop({ type: Number, default: 0 }) readonly canDrag!: number;
  @Prop({ type: String, default: "" }) readonly dragGroup!: string;
  @Prop({ type: String, default: "" }) readonly searchTerm!: string;
  @Prop({ type: String, default: "" }) readonly imageBaseUrl!: string;


  mounted() {
      this.m_listImage = this.listImage;
  }

  @Watch("listImage")
  onListImageChange(value: any[]){
    this.m_listImage = value;

    if (!this.selectMultiple) {
        let elementSelected = this.m_listImage.find((element) => element.selected);
        if (elementSelected) {
          this.m_selectionUnique = elementSelected;
        }
    }

  }


  get getListImageSelected(): any[] {
    return this.m_listImage.filter((img: any) => img.selected);
  }

  get getListImageSorted(): any[] {

    let searchTermUpperCaseTrim: string = this.searchTerm.toLowerCase().trim();

    if (searchTermUpperCaseTrim.length === 0) {
      return this.m_listImage.slice(0);
    } else {
      return this.m_listImage.slice(0).filter((image: any) => image.name.toLowerCase().includes(searchTermUpperCaseTrim.trim()));
    }

  }

  get getListImage(): any[] {
    return this.m_listImage;
  }

  get getImageCodeOrder(): number[] {
    return this.m_listImage.map((img: any) => img.code );
  }

  get dragOptions() {
    return {
      animation: 200,
      group: this.dragGroup,
      disabled: !this.canDrag,
      ghostClass: "ghost"
    };
  }

  onSetSelected(newImage: any) {
      if (this.m_selectionUnique != newImage && this.m_selectionUnique.selected) {
          this.m_selectionUnique.selected = false;
      }
      newImage.selected = !newImage.selected;
      this.m_selectionUnique = newImage;
  }

  onDragEnd(event: any): void {
    this.m_drag = false;
    this.$emit("on-drag-end", this.getImageCodeOrder, event.oldIndex, event.newIndex);
  }

  clear() {
    this.IsClear = true;
  }

}


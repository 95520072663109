import { PaxModel } from "./paxModel";
import { initObject } from "../utilities/utils";

export class RoomModel {

    code: number = 0;
    file: string = "";
    listPax: PaxModel[] = [];
    maxOccupancy: number = 0;
    name: string = "";

    constructor(value: any) {
        initObject(this, value);
    }

}
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
Vue.use(vueCustomElement);

import pcvTable from "@/components/pcvNewTable/pcvTable.vue";
Vue.customElement('pcv-table', (new pcvTable()).$options);

import pcvAvailability from "@/components/pcvAvailability/pcvAvailability.vue";
Vue.customElement('pcv-availability', (new pcvAvailability()).$options);

import pcvCropper from "@/components/PCVCropper.vue";
Vue.customElement('pcv-cropper', (new pcvCropper()).$options);

import pcvImageGallery from "@/components/pcvImageGallery/pcvImageGallery.vue";
Vue.customElement('pcv-image-gallery', (new pcvImageGallery()).$options);

import pcvInventory from "@/components/pcvInventory/pcvInventory.vue";
Vue.customElement('pcv-inventory', (new pcvInventory()).$options);

import pcvEditCalendar from "@/components/pcvEditCalendar/pcvEditCalendar.vue";
Vue.customElement('pcv-edit-calendar', (new pcvEditCalendar()).$options);

import pcvBus from "@/components/pcvTours/pcvBus/pcvBus.vue";
Vue.customElement('pcv-bus', (new pcvBus()).$options);

import pcvToursInvcprod from "@/components/pcvTours/pcvToursInvcprod/pcvToursInvcprod.vue";
Vue.customElement('pcv-tours-invcprod', (new pcvToursInvcprod()).$options);

import pcvToursPaxAssign from "@/components/pcvTours/pcvToursPaxAssign/pcvToursPaxAssign.vue";
Vue.customElement('pcv-tours-pax-assign', (new pcvToursPaxAssign()).$options);

import pcvToursRoomSharing from "@/components/pcvTours/pcvToursRoomSharing/pcvToursRoomSharing.vue";
Vue.customElement('pcv-tours-room-sharing', (new pcvToursRoomSharing()).$options);

import pcvCalendar from "@/components/pcvCalendar/pcvCalendar.vue";
Vue.customElement('pcv-calendar', (new pcvCalendar).$options);

import pcvWebIcon from "@/components/pcvWebIcon.vue";
Vue.customElement('pcv-web-icon', (new pcvWebIcon()).$options);

import pcvImageGalleryWithCategory from "@/components/pcvImageGalleryWithCategory/pcvImageGalleryWithCategory.vue";
Vue.customElement('pcv-image-gallery-with-category', (new pcvImageGalleryWithCategory()).$options);
import { PaxModel } from "../../models/paxModel";
import { LanguageBusModel } from "./languageBusModel";
import { InitialisationModel } from "./initialisationModel";
import { PickupPointModel } from "../../models/pickUpPointModel";

export class PCVBusModel {

    readonly listPickup: PickupPointModel[] = [];

    private _nbRowSelected: string = "10";
    // #region nbRowSelected
    get nbRowSelected(): string {
        return this._nbRowSelected;
    }
    set nbRowSelected(value: string) {
        this._nbRowSelected = value;
    }
    // #endregion

    private _structureSeat: string[][] = [];
    // #region structureSeat
    get structureSeat(): string[][] {
        return this._structureSeat;
    }
    set structureSeat(value: string[][]) {
        this._structureSeat = value;
    }
    // #endregion

    private _listPax: PaxModel[] = [];
    // #region listPax
    get listPax(): PaxModel[] {
        return this._listPax;
    }
    set listPax(value: PaxModel[]) {
        this._listPax = value;
    }
    // #endregion

    private _seatNumberingDirection: string = "rightToLeft";
    // #region seatNumberingDirection
    get seatNumberingDirection(): string {
        return this._seatNumberingDirection;
    }
    set seatNumberingDirection(value: string) {
        this._seatNumberingDirection = value;
    }
    // #endregion

    private _itemDrag: string | PaxModel | null = null;
    // #region itemDrag
    get itemDrag(): string | PaxModel | null {
        return this._itemDrag;
    }
    set itemDrag(value: string | PaxModel | null) {
        this._itemDrag = value;
    }
    // #endregion

    private _originSeatDrag: number[] = [0,0];
    // #region originSeatDrag
    get originSeatDrag(): number[] {
        return this._originSeatDrag;
    }
    set originSeatDrag(value: number[]) {
        this._originSeatDrag = value;
    }
    // #endregion

    private _itemClicked: string | PaxModel | null = null;
    // #region itemClicked
    get itemClicked(): string | PaxModel | null {
        return this._itemClicked;
    }
    set itemClicked(value: string | PaxModel | null) {
        this._itemClicked = value;
    }
    // #endregion

    private _paxMouseOver: PaxModel | null = null;
    // #region paxMouseOver
    get paxMouseOver(): PaxModel | null {
        return this._paxMouseOver;
    }
    set paxMouseOver(value: PaxModel | null) {
        this._paxMouseOver = value;
    }
    // #endregion

    readonly language: LanguageBusModel = new LanguageBusModel(null);

    get getStructurePax(): (PaxModel | null)[][] {

        if (this.listPax.length > 0) {
          let tmpStructurePax: (PaxModel | null)[][] = [];
          for (let row = 0; row < this.structureSeat.length; row++) {
            tmpStructurePax.push([null, null, null, null]);
          }
    
          this.listPax.forEach((pax: PaxModel) => {
            if (pax.row > 0 && pax.col > 0) {
              tmpStructurePax[pax.row - 1][pax.col - 1] = pax;
            }
          })
          return tmpStructurePax;
        } else {
          return [];
        }
    
      }

      get getIsConception() {
        if (this.listPax.length > 0) {
            return false;
        } else {
            return true;
        }
      }

    constructor(value: InitialisationModel | null) {

        if (value){
            this.listPickup = value.listPickup;

            if (value.listPax) {
                let tmpListPax: PaxModel[] = [];
                value.listPax.forEach((pax: any) => {

                    let tmpPax = new PaxModel(pax);
                    if (this.listPickup.length > 0 && tmpPax.busPickup === 0) {
                        tmpPax.busPickup = this.listPickup[0].code;
                    }

                    tmpListPax.push(tmpPax);
                })

                this.listPax = tmpListPax;
            }
            if (value.language) {
                this.language = value.language;
            }
            if (value.structure) {
                this.structureSeat = [];

                let StructureLength = value.structure.length;

                for (let row = 0; row < StructureLength; row++) {
                    let rowArray: string[] = [];
                    for (let col = 0; col < 4; col++) {
                        rowArray.push(value.structure[row][col]);
                    }
                    this.structureSeat.push(rowArray);
                }

                this.nbRowSelected = value.structure.length.toString();

                if (this.getIsConception) {
                    let index = this.structureSeat.length;
                    let row: number;
                    for (row = index; row < 20; row++) {
                        this.structureSeat.push(["", "", "", ""]);
                    }
                }
            }
        }

    }
  
  }
import { ProductModel } from "./productModel";
import { EnumPaginationToursPaxAssign } from "../utilities/enum";
import { InitialisationToursPaxAssignModel } from "./initialisationToursPaxAssignModel";
import { LanguageToursPaxAssignModel } from "./languageToursPaxAssignModel";
import { PCVToursModel } from "../../models/pcvToursModel";
import { PaxModel } from "../../models/paxModel";
import { RoomModel } from "../../models/roomModel";
import { pcvToursPaxAssignAPI } from "../utilities/api";
import { BusDataModel } from "../../models/busDataModel";

export class PCVToursPaxAssignModel extends PCVToursModel {
    
    readonly language: LanguageToursPaxAssignModel = new LanguageToursPaxAssignModel(null);
    readonly listProduct: ProductModel[] = [];
    readonly listPax: PaxModel[] = [];
    readonly api: pcvToursPaxAssignAPI = new pcvToursPaxAssignAPI("", 0, "");
    readonly file: string = "";
    readonly dateFormat: string = "YYYY-MM-DD";
    
    private _currentPage: EnumPaginationToursPaxAssign = EnumPaginationToursPaxAssign.ProductSelection;
    // #region currentPage
    get currentPage(): EnumPaginationToursPaxAssign {
        return this._currentPage;
    }
    set currentPage(value: EnumPaginationToursPaxAssign) {
        this._currentPage = value;
    }
    // #endregion

    private _previousPage: EnumPaginationToursPaxAssign[] = [];
    // #region previousPage
    get previousPage(): EnumPaginationToursPaxAssign[] {
        return this._previousPage;
    }
    set previousPage(value: EnumPaginationToursPaxAssign[]) {
        this._previousPage = value;
    }
    // #endregion

    private _productSelected: ProductModel | null = null;
    // #region productSelected
    get productSelected(): ProductModel | null {
        return this._productSelected;
    }
    set productSelected(value: ProductModel | null) {
        this._productSelected = value;
    }
    // #endregion


    private _listRoomProductSelected: RoomModel[] = []
    // #region listRoomProductSelected
    get listRoomProductSelected(): RoomModel[] {
        return this._listRoomProductSelected;
    }
    set listRoomProductSelected(value: RoomModel[]) {
        this._listRoomProductSelected = value;
    }
    // #endregion

    private _busData: BusDataModel | null = null;
    // #region busData
    get busData(): BusDataModel | null {
        return this._busData;
    }
    set busData(value: BusDataModel | null) {
        this._busData = value;
    }
    // #endregion

    private _busPaxEditable: PaxModel[] = [];
    // #region busData
    get busPaxEditable(): PaxModel[] {
        return this._busPaxEditable;
    }
    set busPaxEditable(value: PaxModel[]) {
        this._busPaxEditable = value;
    }
    // #endregion

    private _listRoomModified: RoomModel[] = []
    // #region listRoomProductSelected
    get listRoomModified(): RoomModel[] {
        return this._listRoomModified;
    }
    set listRoomModified(value: RoomModel[]) {
        this._listRoomModified = value;
    }
    // #endregion

    private _listRoomDeleted: number[] = []
    // #region listRoomProductSelected
    get listRoomDeleted(): number[] {
        return this._listRoomDeleted;
    }
    set listRoomDeleted(value: number[]) {
        this._listRoomDeleted = value;
    }
    // #endregion

    constructor(init: InitialisationToursPaxAssignModel){
        super();
        if (init) {
            this.listProduct = init.listProduct;
            this.listPax = init.listPax;
            this.file = init.file;
            this.api = new pcvToursPaxAssignAPI(init.baseURL, init.pageCode, init.file);
            this.language = init.language;
            this.dateFormat = init.dateFormat;
        }
    }

}






















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PCVTableData from './models/pcvTableData';
import PCVTableColumn from './models/pcvTableColumn';
import PCVTableColumnTypeEnum from './models/pcvTableColumnTypeEnum';
import PCVCellEdited from "./pcvCellEdited.vue";
import XLSX from "xlsx";
import Moment from "moment";

@Component({
  components: { 
    "pcv-cell-edited" : PCVCellEdited
  }
})
export default class PCVTable extends Vue {

  //#region Prop 

    @Prop({ type: Array, default: () => [] }) readonly rows!: any[];
    @Prop({ type: String, default: "id" }) readonly rowKey!: string;
    @Prop({ type: Array, default: () => [] }) readonly columns!: PCVTableColumn[];
    @Prop({ type: String, default: "YYYY-MM-DD" }) readonly dateFormat!: string;
    @Prop({ type: Number, default: 1 }) readonly hasSelection!: number;
    @Prop({ type: Number, default: 0 }) readonly hasSelectionMultiple!: number;
    @Prop({ type: Number, default: 0 }) readonly canChangeColumnOrder!: number;
    @Prop({ type: Number, default: 0 }) readonly isTestMode!: number;
    
  //#endregion

  //#region Data

    data: PCVTableData = new PCVTableData();
    
    isClear: boolean = false;
    lastCellEditedKey: number = -1;
    cellEdited:[any?, PCVTableColumn?] = [];

  //#endregion

  //#region Computed

    get inputPagination(): string {
      return (this.data.currentPage + 1).toString();
    }

    set inputPagination(value: string) {
      if (value === "" || isNaN(parseInt(value))) {
        this.data.currentPage = 0;
      } else {
        let newPage: number = parseInt(value);
        let pageCount: number = this.getPageCount;

        if (newPage <= pageCount && newPage > 0) {
          this.data.currentPage = newPage - 1;
        } else if (newPage > pageCount) {
          this.data.currentPage = pageCount - 1;
        } else if (newPage <= 0) {
          this.data.currentPage = 0;
        }
      }
      this.$forceUpdate();
    }

    get getPCVTableColumnTypeEnum(): typeof PCVTableColumnTypeEnum{
      return PCVTableColumnTypeEnum;
    }

    get getColumnType(): { [columnKey: string]: PCVTableColumnTypeEnum } {

      let returnedColumnType: { [columnKey: string]: PCVTableColumnTypeEnum } = {};
      this.columns.forEach((column) => {
        returnedColumnType[column.key] = column.type;
      })
      return returnedColumnType;

    }

    get getPageCount(): number {
      let pageCount: number = Math.ceil(this.getRowsFiltered.length / this.data.itemsPerPage);
      return Math.max(1, pageCount);
    }

    get getIsPreviousPageUnavailable(): boolean {
      return this.data.currentPage === 0;
    }

    get getIsNextPageUnavailable(): boolean {
      return (this.data.currentPage + 1) === this.getPageCount;
    }

    //#region RowsTable

      get getRowsFiltered(): any[] {

        let returnedRows = this.rows;
        for(let termColumn in this.data.filterTermsHeader) {
          let term = this.data.filterTermsHeader[termColumn].toUpperCase();
          if (term.trim().length > 0) {
            returnedRows = returnedRows.filter((row: any) => {
              return (row[termColumn + "_formated"].toUpperCase().includes(term));
            });
            this.data.currentPage = 0;
          }
        }
        return returnedRows;
      }

      get getIsSelectAllActive(): boolean {
        var t0 = performance.now();         
        let isSelectAllActive: boolean = !this.getRowsFiltered.filter((row: any) => !row.skip).some((row: any) => !row.sel)
        
        if (this.isTestMode) {
          var t1 = performance.now();
          console.log("Call to getIsSelectAllActive took " + (t1 - t0) + " milliseconds.");
        }

        return isSelectAllActive;
      }

      get getRowsSorted(): any[] {
        var t0 = performance.now();
        
        if (this.data.columnSorted.key.length > 0) {
          let rowsFiltered: any[] = this.getRowsFiltered.slice(0);

          if (this.data.columnSorted.type == PCVTableColumnTypeEnum.Text) {
            var collator = this.stringCollator("fr");
            return rowsFiltered
              .sort((a, b) => collator.compare(a[this.data.columnSorted.key], b[this.data.columnSorted.key])
            );
          } else if (this.data.columnSorted.type == PCVTableColumnTypeEnum.Date || this.data.columnSorted.type == PCVTableColumnTypeEnum.Datetime) {
            var collator = this.stringCollator("fr");
            var rowsSorted = rowsFiltered
                .sort((a, b) => collator.compare(a[this.data.columnSorted.key], b[this.data.columnSorted.key]));
                //.sort((a, b) => new Date(a[this.data.columnSorted.key]).getTime() - new Date(b[this.data.columnSorted.key]).getTime());
                //.sort((a, b) => Moment(a[this.data.columnSorted.key]).diff(Moment(b[this.data.columnSorted.key])));
              
            if (this.isTestMode) {
              var t1 = performance.now();
              console.log("Call to getRowsSorted took " + (t1 - t0) + " milliseconds.");
            }
            return rowsSorted
          } else {
            return rowsFiltered
              .sort((a, b) => a[this.data.columnSorted.key] - b[this.data.columnSorted.key]);
          }
        } else {
          return this.getRowsFiltered;
        }
      }

      get getRowsSortReversed(): any[] {

        let rowsSorted = this.getRowsSorted;

        if (this.data.isSortReversed) {
          rowsSorted = rowsSorted.slice(0);
          rowsSorted.reverse();
        }

        return rowsSorted;
      }

      get getRowsPaginated(): any[] {

        return this.getRowsSortReversed.slice(
          this.data.currentPage * this.data.itemsPerPage,
          this.data.currentPage * this.data.itemsPerPage + this.data.itemsPerPage
        );
      }

      get getTableStyle(): any {
        let totWidth = this.hasSelection ? 25 : 0;

        this.columns.forEach( (column: PCVTableColumn) => {
          totWidth += column.minWidth;
        })

        return { width: totWidth + 'px' };
      }

    //#endregion

  //#endregion

  //#region Methods

    //#region General

      clear() {
        this.isClear = true;
      }

      setData(data: any) {
        let currentPage : number = data.currentPage;
        this.data = data;
        
        //nextTick nécessaire sinon le this.rows est vide
        this.$nextTick( () => { 
          this.updateDataAll();
        });

        // Set currentPage after GetRowsFiltered (this.data.currentPage = 0)
        this.$nextTick( () => this.data.currentPage = currentPage );
      }

      styleFromColumnType(column: PCVTableColumn): any {
        if (column.type === PCVTableColumnTypeEnum.Icon) {
          return { textAlign: 'center', color: '#009FCD' };
        } else if (column.type === PCVTableColumnTypeEnum.Currency) {
          return { textAlign: 'right' };
        } else if ((column.type === PCVTableColumnTypeEnum.Date) || (column.type === PCVTableColumnTypeEnum.Datetime)) {
          return { textAlign: 'center' };
        } else if (column.type === PCVTableColumnTypeEnum.Checkbox) {
          return { textAlign: 'center' };
        } else if (column.type === PCVTableColumnTypeEnum.HTML) {
          return { whiteSpace: 'normal' };
        }
      }

      // Watch exécuté 2 fois de suite car le this.$set du updateData change l'array
      // @Watch("rows")
      // onRowsChange(value: any[]) {
      //   console.log('Watch->' + this.rows.length)
      //   this.updateDataAll();

      //   // if (!this.hasSelectionMultiple) {
      //   //   let elementSelected = this.rows.find((element) => element.sel);
      //   //   if (elementSelected) {
      //   //     this.data.selectionSingle = elementSelected;
      //   //   }
      //   //   else if (this.rows.length > 0) {
      //   //     this.data.selectionSingle = this.rows[0];
      //   //   }
      //   // }
      // }

      updateDataAll() {
        var t0 = performance.now();

        this.rows.forEach( (row: any) => {
          this.updateDataRow(row);  
        })

        if (this.isTestMode) {
          var t1 = performance.now();
          console.log("Call to updateDataAll took " + (t1 - t0) + " milliseconds.");
        }
      }

      updateDataRow(row: any) {
        this.columns.forEach( (column: PCVTableColumn) => {
          this.updateData(row, column);
        });
      }

      updateData(row: any, column: PCVTableColumn) {
        this.$set(row, column.key + "_formated", this.formattingCell(row[column.key], column.type));
      }

      mounted() {
        //20-02-20 Pas nécessaire car rows jamais défini au mounted
        //this.updateDataAll();
      }

      formattingCell(value: any, columnType: PCVTableColumnTypeEnum, options?: any): string {
        let tmpValue: string = "";

        switch (columnType) {
          case PCVTableColumnTypeEnum.Date:

            try {
              let y: string = value.substring(0, 4);
              if (y == "0001") {
                return ""
              }

              let m: string = value.substring(5, 7);
              let d: string = value.substring(8, 10);

              switch (this.dateFormat) {
                case 'YYYY-MM-DD':
                  tmpValue = y + '-' + m + '-' + d;
                  break;
                case 'MM-DD-YYYY':
                  tmpValue = m + '-' + d + '-' + y;
                  break;
                case 'DD-MM-YYYY':
                  tmpValue = d + '-' + m + '-' + y;
                  break;                  
              }
            }
            catch {
            }

            break;
          case PCVTableColumnTypeEnum.Datetime:

            try {
              let y: string = value.substring(0, 4);
              if (y == "0001") {
                return ""
              }

              let m: string = value.substring(5, 7);
              let d: string = value.substring(8, 10);
              let h: string = value.substring(11, 13);
              let i: string = value.substring(14, 16);
              let s: string = value.substring(17, 19);
              
              switch (this.dateFormat) {
                case 'YYYY-MM-DD':
                  tmpValue = y + '-' + m + '-' + d;
                  break;
                case 'MM-DD-YYYY':
                  tmpValue = m + '-' + d + '-' + y;
                  break;
                case 'DD-MM-YYYY':
                  tmpValue = d + '-' + m + '-' + y;
                  break;                  
              }
              tmpValue = tmpValue + ' - '  + h + ':' + i + ':' + s;
            }
            catch {
            }

            break;
          case PCVTableColumnTypeEnum.Currency:
            var valNumber: number = Number(value);
            if (!isNaN(valNumber)) {
              //tmpValue = valNumber.toFixed(2);

              var iValue = Math.round(Math.abs(valNumber) * 100);
              var iPart = Math.floor(iValue / 100);
              var fPart = (iValue % 100);

              var str = iPart.toString();
              var space = ''
              var i = 0;
              for (var idx = str.length - 1; idx >= 0; idx--) {
                tmpValue = str[idx] + space + tmpValue;
                i++;
                if (i == 3)
                {
                  space = ' ';
                  i = 0;
                }
                else
                {
                  space = '';
                }
              }

              if (value < 0) {
                tmpValue = '- ' + tmpValue;
              }

              tmpValue += ".";
              if (fPart >= 10) {
                tmpValue += fPart.toString();
              } else {
                tmpValue += '0' + fPart.toString();
              }
            } else {
              tmpValue = "0.00";
            }
            break;
          case PCVTableColumnTypeEnum.Integer:
            var valNumber: number = Number(value);
            if (!isNaN(valNumber)) {
              tmpValue = parseInt(value).toString();
            } else {
              tmpValue = "0";
            }
            break;
          default:
            tmpValue = value;
        }

        return tmpValue;
      }

      stringCollator(lang: string): Intl.Collator {
        return new Intl.Collator(lang, { numeric: false });
      }

      onClickSort(column: PCVTableColumn) {
        if (column == this.data.columnSorted) {
          if (this.data.isSortReversed) {
            this.data.isSortReversed = false;
            this.data.columnSorted = new PCVTableColumn();
          } else {
            this.data.isSortReversed = true;
          }
        } else {
          this.data.isSortReversed = false;
          this.data.columnSorted = column;
        }
      }

      onClickCell(row: any, column: PCVTableColumn) {
        if ((column.type == PCVTableColumnTypeEnum.Icon) && (row[column.key] == '-1'))
        {
          return;
        }

        if (column.editable) {
          this.cellEdited = [row, column];
        } else {
          if (this.hasSelection && column.selectable && this.lastCellEditedKey != row[this.rowKey]) {
            if (this.hasSelectionMultiple) {
              row.sel = !row.sel;
            } else {
              this.data.selectionSingle = row;
            }
            this.onSelectionChange(row);
          }
        }
        this.$emit("table-click", row, column);
      }

      onDoubleClickCell(row: any, column: PCVTableColumn) {
        this.$emit("table-double-click", row, column);
      }

      onMouseOverCell(row: any, column: PCVTableColumn) {
        this.$emit("table-mouse-over", row, column);
      }

      onMouseOutCell(row: any, column: PCVTableColumn) {
        this.$emit("table-mouse-out", row, column);
      }

      onKeyPressEnterFilter(column: PCVTableColumn, value: string) {
        this.$set(this.data.filterTermsHeader, column.key, value);
      }

      onKeyPressEnterPagination(value: string) {
        this.inputPagination = value;
      }

    //#endregion

    //#region Selection

      onCheckAll() {
        var t0 = performance.now();         
       
        let newSelValue: boolean = !this.getIsSelectAllActive;
        let rowsToUpdate: any = this.getRowsFiltered.filter( (row: any) => {
          return (!row.skip) && (row.sel != newSelValue)
        });

        rowsToUpdate.forEach( (row: any) => {
          row.sel = newSelValue;
        });

        if (this.isTestMode) {
          var t1 = performance.now();
          console.log("Call to onCheckAll took " + (t1 - t0) + " milliseconds.");
        }

        this.$emit("table-selection-all-change", rowsToUpdate, newSelValue);
      }

      //La valeur du row.sel n'est pas encore changée.
      //Utiliser pour annuler le click sur le checkbox (BankReconciliation) sinon utiliser onSelectionChange
      onSelectionClick(row: any, event: Event) {
        this.$emit("table-selection-click", row, event);
      }

      onSelectionChange(row: any) {
        this.$emit("table-selection-change", row);
      }
      
      public setSelection(row: any) {
        this.data.selectionSingle = row;
      }

      public getSelection(): any[] | any {
        if (this.hasSelectionMultiple) {
          return this.rows.filter((row) => row.sel);
        } else {
          return this.data.selectionSingle;
        }
      }
    
    //#endregion

    //#region Cell Editable

      onTableDataChange(oldValue: any, newValue: any) {
        if (oldValue !== newValue) {
            if (this.cellEdited[0] !== undefined && this.cellEdited[1] !== undefined) {
                let row: any = this.cellEdited[0];
                let column: PCVTableColumn = this.cellEdited[1];
                this.$emit("table-data-change", row, column, oldValue, newValue);
            }
        }
      }

      isCellEdited(row: any, column: PCVTableColumn): boolean {
        if (this.cellEdited[0] !== undefined && this.cellEdited[1] !== undefined) {
          return (this.cellEdited[0][this.rowKey] === row[this.rowKey] && this.cellEdited[1].key === column.key);
        } else {
          return false;
        }
      }

      onCloseCellEdited() {
        if (this.cellEdited[0] !== undefined && this.cellEdited[1] !== undefined) {
            this.updateData(this.cellEdited[0], this.cellEdited[1]);
            this.lastCellEditedKey = this.cellEdited[0][this.rowKey];
            window.setTimeout( () => { this.lastCellEditedKey = -1; }, 2000);
        }
        this.cellEdited = []
      }

      onPressTab(rowIndex: number, columnIndex: number) {
        if (this.cellEdited[0] !== undefined && this.cellEdited[1] !== undefined) {
          this.updateData(this.cellEdited[0], this.cellEdited[1]);
         
         //loop sur le restant de la row
          for (var i = columnIndex + 1; i < this.columns.length; i++) {
            if (this.columns[i] && this.columns[i].editable) {
              this.cellEdited[1] = this.columns[i];
              return;
            }
          }

          let nextRowIndex: number = rowIndex + 1;
          if (nextRowIndex >= this.getRowsPaginated.length) {
            nextRowIndex = 0;
          }
          for (var i = 0; i < this.columns.length; i++) {
            if (this.columns[i] && this.columns[i].editable) {
              this.cellEdited = [this.getRowsPaginated[nextRowIndex], this.columns[i]];
              return;
            }
          }
        }
      }

      onPressShiftTab(rowIndex: number, columnIndex: number) {
        if (this.cellEdited[0] !== undefined && this.cellEdited[1] !== undefined) {
          this.updateData(this.cellEdited[0], this.cellEdited[1]);

          //loop sur le restant de la row
          for (var i = columnIndex - 1; i >= 0; i--) {
            if (this.columns[i] && this.columns[i].editable) {
              this.cellEdited[1] = this.columns[i];
              return;
            }
          }

          let previousRowIndex: number = rowIndex - 1;
          if (previousRowIndex < 0) {
            previousRowIndex = this.getRowsPaginated.length - 1;
          }
          for (var i = this.columns.length - 1; i >= 0; i--) {
            if (this.columns[i] && this.columns[i].editable) {
              this.cellEdited = [this.getRowsPaginated[previousRowIndex], this.columns[i]];
              return;
            }
          }
        }
      }

    //#endregion

    //#region Pagination
    
      onClickToggleDisplayFilter() {
        this.data.isFilterDisplayed = !this.data.isFilterDisplayed;
      }

      onClickGenerateXLSX() {
        try {

          let dataCSV: any[] = this.rows.map((row) => {
            let rowsMapped: any[] = [];
            this.columns.forEach((column: PCVTableColumn) => {
              if (column.type !== PCVTableColumnTypeEnum.Image && column.type !== PCVTableColumnTypeEnum.Icon) {
                rowsMapped.push(this.formattingCell(row[column.key], column.type));
              }
            })
            return rowsMapped;
          });

          let headerCSV: any[] = [];

          this.columns.forEach((column: PCVTableColumn) => {
            if (column.type !== PCVTableColumnTypeEnum.Image && column.type !== PCVTableColumnTypeEnum.Icon) {
              headerCSV.push(column.label);
            }
          })

          dataCSV.unshift(headerCSV);

          const ws = XLSX.utils.aoa_to_sheet(dataCSV);

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          XLSX.writeFile(wb, Moment().format(this.dateFormat) + ".csv");

        } catch(ex) {
          console.log(ex);
        }
      }

      onClickChangeColumnsOrder() {
        this.$emit("table-change-columns-order");
      }

      onChangeItemsPerPage() {
        this.$emit("table-change-items-per-page", this.data.itemsPerPage);
      }

      firstPage() {
        if (!this.getIsPreviousPageUnavailable) {
          this.data.currentPage = 0;
        }
      }

      previousPage() {
        if (!this.getIsPreviousPageUnavailable) {
          this.data.currentPage--;
        }
      }

      nextPage() {
        if (!this.getIsNextPageUnavailable) {
          this.data.currentPage++;
        }
      }

      lastPage() {
        if (!this.getIsNextPageUnavailable) {
          this.data.currentPage = Math.ceil(this.getRowsFiltered.length / this.data.itemsPerPage) - 1;
        }
      }


    //#endregion

  //#endregion

  //#region Watch

  @Watch("data.itemsPerPage")
  onChangeItemPerPage(value: number){
    this.data.currentPage = 0;
  }

  //#endregion

}

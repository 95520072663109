import { initObject } from "../utilities/utils";
import { PaxModel } from './paxModel';

export class BusDataModel {

    busStructure: string[][] = [];
    listPax: PaxModel[] = [];
    nbPlaces: number = 0;
    nbRows: number = 0;

    constructor(value: any) {
        initObject(this, value);
    }

}
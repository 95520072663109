

































import { Vue, Component, Prop } from "vue-property-decorator";
import LanguageToursRoomSharingModel from "../models/languageToursRoomSharingModel";
import RoomModel from "../models/roomModel";
import draggable from "@/../node_modules/vuedraggable/src/vuedraggable.js";

@Component({
  components: {
    "draggable": draggable
  }
})
export default class RoomSharingRoom extends Vue {

    @Prop({ type: Object }) readonly propData!: RoomModel;
    @Prop({ type: Object }) readonly propLanguage!: LanguageToursRoomSharingModel;

    get getDragOptions() {
        return {
            animation: 200,
            group: "pcv-tours-room-sharing",
            ghostClass: "ghost"
        };
    }

    get getRoomStatus()
    {
        return {
            "Available": (this.propData.listPax.length < this.propData.maxOccupancy),
            "Full": (this.propData.listPax.length === this.propData.maxOccupancy),
            "Error": (this.propData.listPax.length > this.propData.maxOccupancy)
        }
    }
}











































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import moment, { months } from 'moment';
import EditableInput from "@/components/common/EditableInput.vue";
import { InitCalendar, CalendarItem } from "./models";

@Component
export default class PCVCalendar extends Vue {

    @Prop({ type: Object, default: () => new InitCalendar() }) readonly propInit!: InitCalendar;
    IsClear: boolean = false;
    calendarMoment: moment.Moment = moment();
    languageDaysOfWeekAbbr: string[] = [];
    languageMonth: string[] = [];
    listItems: CalendarItem[] = [];
    
    @Watch("propInit", { deep: true, immediate: true})
    onWatchPropInit(value: any) {
        let tmpPropInit =  new InitCalendar(value);
        this.languageDaysOfWeekAbbr = tmpPropInit.languageDate.daysOfWeekAbbr;
        this.languageMonth = tmpPropInit.languageDate.month;
        this.listItems = tmpPropInit.listItems;
        this.calendarMoment = moment(tmpPropInit.currentDate);
    }

    mounted() {
        let tmpPropInit =  new InitCalendar(this.propInit);
        this.languageDaysOfWeekAbbr = tmpPropInit.languageDate.daysOfWeekAbbr;
        this.languageMonth = tmpPropInit.languageDate.month;
        this.listItems = tmpPropInit.listItems;

        if (this.listItems.length > 0) {
            this.calendarMoment = moment(this.listItems[0].dateFrom);
        }
    }

    clear() {
        this.IsClear = true;
    }

    get getListItemsInMonth() {

        let tmpListItems: CalendarItem[] = this.listItems.filter((item) => {

            if ( moment(item.dateFrom).isBetween(this.getCalendar[0].days[0], this.getCalendar[4].days[6], "day", "[]")) {
                return true;
            }

            if (moment(item.dateTo).isBetween(this.getCalendar[0].days[0], this.getCalendar[4].days[6], "day", "[]")) {
                return true;
            }

            if (moment(item.dateFrom).isBefore(this.getCalendar[0].days[0]) && moment(item.dateTo).isAfter(this.getCalendar[4].days[6])) {
                return true;
            }

            return false;

        });

        return tmpListItems;

    }

    getListItemsInDays(day: moment.Moment) {
        return this.getListItemsInMonth.filter((item) => day.isBetween(item.dateFrom, item.dateTo, "day", "[]"));
    }

    get monthSelected(): number {
        return this.calendarMoment.month() + 1;
    }

    set monthSelected(value: number) {
        this.calendarMoment = moment(this.calendarMoment.month(value - 1));
    }

    get yearSelected(): number {
        return this.calendarMoment.year();
    }

    set yearSelected(value: number) {
        this.calendarMoment = moment(this.calendarMoment.year(value));
    }

    get getCalendar() {
        const startWeek = this.calendarMoment.startOf('month').week();
        const endWeek = startWeek + 5;

        let calendar = []
        for (var week = startWeek; week <= endWeek; week++) {
            calendar.push({
                week: week,
                days: Array(7).fill(0).map((n, i) => moment(this.calendarMoment).week(week).startOf('week').add(n + i, 'day'))
            })
        }
        return calendar;
    }

    onClickPreviousMonth() {
        this.calendarMoment = moment(this.calendarMoment.subtract(1, "month"));
    }

    onClickNextMonth() {
        this.calendarMoment = moment(this.calendarMoment.add(1, "month"));
    }

    @Emit("current-date-updated")
    emitCurrentDateUpdated(date: string) {
        return date;
    }

    @Watch("calendarMoment")
    onWatchCalendarMoment(value: moment.Moment) {
        this.emitCurrentDateUpdated(value.format("YYYY-MM-DD"))
    }
}


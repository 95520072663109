import { initObject } from "../../utilities/utils";
import RoomModel from "./roomModel";
import LanguageToursRoomSharingModel from "./languageToursRoomSharingModel";

export default class InitialisationToursRoomSharingModel {

    listRoom: RoomModel[] = [];
    language: LanguageToursRoomSharingModel = new LanguageToursRoomSharingModel(null);

    constructor(value: any) {
        initObject(this, value);
    }
}
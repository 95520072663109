









































































































































































import { Vue, Component, Prop, Watch, Emit, Model } from 'vue-property-decorator'
import { IPCVTableColumn, PCVTableColumn, IPCVTableFilterMethod } from './models/PCVTable';

import EditableInput from "./../common/EditableInput.vue";
import XLSX from 'xlsx';
import Moment from 'moment';

function StringCollator(lang: string): Intl.Collator {
  return new Intl.Collator(lang, { numeric: true });
}

@Component({
  components: {
    "editable-input": EditableInput
  }
})
export default class PCVTable extends Vue {

  // #region Interface (model, prop)

  @Model('propSingleSelection', { type: Object }) propSingleSelection!: any;

  checkedRadioProxy: any = null;

  @Watch("propSingleSelection")
  onWatchModel(newValue: any) {
    this.checkedRadioProxy = newValue;
  }

  beforeMount(){
    if (this.propSingleSelection) {
      this.checkedRadioProxy = this.propSingleSelection;
    }
  }

  get checkedRadio(): any {
    return this.checkedRadioProxy;
  }

  set checkedRadio(newValue: any) {
    this.checkedRadioProxy = newValue;
    this.emitModel(newValue);
  }

  @Emit("propSingleSelection")
  emitModel(newValue: any) {
    return newValue;
  }

  @Prop({ type: Array, default: () => [] }) propData!: any[];
  @Prop({ type: Array, default: () => [] }) propColumns!: IPCVTableColumn[];
  @Prop({ type: String, default: "id" }) propDataKey!: string;

  @Prop({ type: String, default: "None" }) propSelection!: string;
  @Prop({ type: Object, default: () => { } }) propTableStyle!: any;
  @Prop({ type: Boolean, default: false }) propFilterDisplayed!: boolean;
  @Prop({ type: String, default: "YYYY-MM-DD" }) propDateFormat!: string;
  @Prop({ type: Boolean, default: false }) propChangeColumnsDisplayed!: boolean;

  // #endregion

  // #region Local State (data / computed)


  pageActif: number = 0;
  pageInput: string = (this.pageActif + 1).toString();
  itemsPerPage: number = 20;

  isSortedReverse: boolean = false;
  sortedColumn: IPCVTableColumn = new PCVTableColumn("", "", "");

  checkAll: boolean = false;
  checkAllManuallyClick: boolean = false;

  columnEdited: { itemId: string, columnId: string } | null = null;

  filterTerms: any = {};
  isFilterDisplayed: boolean = false;

  isClear: boolean = false;

  @Watch("propData")
  onWatchData() {
    if (this.propSelection === "Single" && !this.checkedRadio && this.propData.length > 0) {
      this.checkedRadio = this.propData[0];
    }
    
  }

  get getDataFiltered(): any[] {
    let data: any = [];
    let arrayTerms: string[] = Object.values(this.filterTerms);

    if (arrayTerms.find((term: string) => term.trim() !== "")) {
      data = this.filterMethod(this.propData, this.propColumns, this.filterTerms);
    } else {
      data = this.propData;
    }

    return data;
  }

  get getDataSorted(): any[] {

    var dataFiltered: any[] = this.getDataFiltered.slice(0);

    if (this.sortedColumn.id !== "") {
      if (this.sortedColumn.type === "String") {
        var collator = StringCollator("fr");

        return dataFiltered
          .slice(0)
          .sort((a, b) =>
            collator.compare(a[this.sortedColumn.id], b[this.sortedColumn.id])
          );
      } else {
        return dataFiltered
          .slice(0)
          .sort((a, b) => a[this.sortedColumn.id] - b[this.sortedColumn.id]);
      }
    } else {
      return dataFiltered;
    }

  }

  get getDataSortedReverse(): any[] {
    let tmpDataSorted = this.getDataSorted.slice(0);

    if (this.isSortedReverse) {
      tmpDataSorted.reverse();
    }

    return tmpDataSorted;
  }

  get getDataPaginated(): any[] {
    let tmpDataSorted = this.getDataSortedReverse.slice(0);

    let dataPage = tmpDataSorted.slice(
      this.pageActif * this.itemsPerPage,
      this.pageActif * this.itemsPerPage + this.itemsPerPage
    );

    return dataPage;
  }

  get getPageCount(): number {
    return Math.ceil(this.getDataFiltered.length / this.itemsPerPage) > 0 ? Math.ceil(this.getDataFiltered.length / this.itemsPerPage) : 1;
  }

  get getIsPreviousPageUnavailable(): boolean {
    return this.pageActif === 0;
  }

  get getIsNextPageUnavailable(): boolean {
    return (this.pageActif + 1 === Math.ceil(this.getDataFiltered.length / this.itemsPerPage) || this.getDataFiltered.length === 0);
  }


  // #endregion

  // #region Events (watch / lifecycle)

  @Watch("checkedRadio")
  onWatchCheckedRadio(newValue: any, OldValue: any) {
    if (OldValue) {
      OldValue.sel = false;
    }
    newValue.sel = true;
  }

  @Watch("pageActif")
  onWatchPageActif(newValue: number) {
    this.pageInput = (newValue + 1).toString();
  }

  @Watch("filterTerms")
  onFilterTermsChange() {
    this.pageActif = 0;
    this.checkAll = false;
  }

  mounted(): void {
    if (this.propFilterDisplayed) {
      this.isFilterDisplayed = this.propFilterDisplayed;
    }
  }


  // #endregion

  // #region Non-Reactive Properties

  onClickToggleDisplayFilter() {
    this.isFilterDisplayed = !this.isFilterDisplayed;
  }


  filterMethod(rows: any[], columns: IPCVTableColumn[], terms: any): any[] {
    let returnRows: any = rows;
    let termsNotEmpty: any = {};

    let self = this;

    Object.keys(terms).map(function(columnKey: string) {
      if (terms[columnKey] !== "") {
        termsNotEmpty[columnKey] = terms[columnKey].toLowerCase().trim();
      }
    });

    if (Object.keys(termsNotEmpty).length) {
      returnRows = rows.filter(function(row: any) {
        for (var column in termsNotEmpty) {

          let columnFound = columns.find((_column) => { return _column.id === column });

          if (!(self.formatting(row[column], columnFound)).toLowerCase().includes(termsNotEmpty[column])) {
            return false;
          }
        }
        return true;
      });
    }

    return returnRows;
  }

  onClickChangeColumns(): void {
    this.$emit("table-change-columns");
  }

  onClickGenerateXLSX(): void {
    try {

      let dataCSV: any[] = this.propData.map((item) => {
        let tmpData: any[] = [];
        this.propColumns.forEach((column: any) => {
          if (column.type !== 'Image' && column.type !== 'Icon') {
            tmpData.push(this.formatting(item[column.id], column));
          }
        })
        return tmpData;
      });

      let headerCSV: any[] = [];

      this.propColumns.forEach((column: any) => {
        if (column.type !== 'Image' && column.type !== 'Icon') {
          headerCSV.push(column.label);
        }
      })

      dataCSV.unshift(headerCSV);

      const ws = XLSX.utils.aoa_to_sheet(dataCSV);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

      XLSX.writeFile(wb, Moment().format(this.propDateFormat) + ".csv");

    } catch {

    }

  }

  getItemsSelected(): any[] {
    return this.propData.filter((item) => { return item.sel });
  }

  styleFromColumnType(column: IPCVTableColumn): any {

    if (column.type === 'Icon') {
      return { textAlign: 'center' };
    } else if (column.type === 'Currency') {
      return { textAlign: 'right' };
    }

  }

  onChangeSelectPagination($event: Event) {
    if ($event.target) {
      let tmpItemsPerPage: number | undefined = parseInt((<HTMLInputElement>$event.target).value)

      if (tmpItemsPerPage) {
        this.itemsPerPage = tmpItemsPerPage;
        this.pageActif = 0;
      } else {
        this.itemsPerPage = 20;
      }
    }
  }

  onClickCheck(item: any): void {

    if (this.propSelection === "Multi" && this.checkAll) {
      this.checkAll = false;
    }

    this.$emit("table-selection-change", item);

  }

  clear() {
    this.isClear = true;
  }

  onClickSelectAll(): void {

    this.checkAll = !this.checkAll;

    var dataFiltered = this.getDataFiltered;

    if (this.checkAll) {

      dataFiltered.forEach(function(part, index) {
        dataFiltered[index].sel = true;
      });

    } else {

      dataFiltered.forEach(function(part, index) {
        dataFiltered[index].sel = false;
      });

    }

    this.checkAllManuallyClick = true;

    this.$emit("table-selection-all-change", this.checkAll);

  }

  onClick(item: any, column: any, value: string) {

    this.$emit("table-click", item, column, value);

    if (this.propSelection === "Multi"){
      item.sel = !item.sel;
    } else if (this.propSelection === "Single") {
      this.checkedRadio = item;
    }

    if (column.editable) {
      this.columnEdited = { itemId: item[this.propDataKey], columnId: column.id };
    }
  }

  onDoubleClick(item: any, column: any, value: string) {
    this.$emit("table-double-click", item, column, value);
  }

  setSortedColumn(column: IPCVTableColumn) {
    if (column.type !== 'Icon' && column.type !== 'Image') {
      if (this.sortedColumn != column) {
        this.isSortedReverse = false;
        this.sortedColumn = column;
      } else {
        if (this.isSortedReverse) {
          this.isSortedReverse = false;
          this.sortedColumn = new PCVTableColumn("", "", "");
        } else {
          this.isSortedReverse = true;
        }
      }
    }
  }

  formatting(value: any, column: any): string {

    let tmpReturnValue: string = "";

    switch (column.type) {
      case 'Currency':
        if (!isNaN(Number(value))) {
          tmpReturnValue = Number(value).toFixed(2);
        } else {
          tmpReturnValue = "0.00";
        }
        break;
      case 'Integer':
        if (!isNaN(Number(value))) {
          tmpReturnValue = parseInt(value).toString();
        } else {
          tmpReturnValue = "0";
        }
        break;
      case 'Date':
        if (isNaN(Date.parse(value))) {
          var tmpDate = Moment();
        } else {
          var tmpDate = Moment(value);
        }

        tmpReturnValue = tmpDate.format(this.propDateFormat);
        break;

      case 'Datetime':
        if (isNaN(Date.parse(value))) {
          var tmpDate = Moment();
        } else {
          var tmpDate = Moment(value);
        }

        tmpReturnValue = tmpDate.format(this.propDateFormat + " - HH:mm:ss");
        break;

      case 'Select':
        let optionFound = column.options.find((option: any) => option.value === value);

        if (optionFound) {
          tmpReturnValue = optionFound.label;
        } else {
          tmpReturnValue = "";
        }
        break;

      default:
        tmpReturnValue = value;
    }

    return tmpReturnValue;

  }

  emitTableDataChange(oldValue: any, newValue: any) {

    if (this.columnEdited) {
      var itemId = this.columnEdited.itemId;
      var columnId = this.columnEdited.columnId;
    } else {
      return
    }

    let item = this.getDataPaginated.find((data) => data[this.propDataKey] === itemId);
    let column = this.propColumns.find((column) => column.id === columnId);

    this.$emit("table-data-change", item, column, oldValue, newValue);

  }

  onHideEditable() {

    if (this.columnEdited) {
        this.columnEdited = null;
    }

  }

  onPressTab(): any {
    if (this.columnEdited) {
      var originalColumnId: string = this.columnEdited.columnId;
      var originalItemId: string = this.columnEdited.itemId;
    } else {
      return
    }

    let indexColumn = this.propColumns.findIndex((column: IPCVTableColumn) => {
      return column.id === originalColumnId;
    })

    for (var i = indexColumn + 1; i < this.propColumns.length; i++) {
      let column: IPCVTableColumn = this.propColumns[i];
      if (column) {
        if (column.editable) {
          return this.columnEdited = { itemId: originalItemId, columnId: column.id };
        }
      }
    }

    let itemId = this.getDataPaginated.findIndex((item: any) => {
      return item[this.propDataKey] === originalItemId;
    })

    for (var y = itemId + 1; y < this.getDataPaginated.length; y++) {

      let item = this.getDataPaginated[y]

      if (item) {

        for (var i = 0; i < this.propColumns.length; i++) {
          let column: IPCVTableColumn = this.propColumns[i];
          if (column) {
            if (column.editable) {
              return this.columnEdited = { itemId: item[this.propDataKey], columnId: column.id };
            }
          }
        }

      }
    }


    for (var y = 0; y < itemId - 1; y++) {

      let item = this.getDataPaginated[y]

      if (item) {

        for (var i = 0; i < this.propColumns.length; i++) {
          let column: IPCVTableColumn = this.propColumns[i];
          if (column) {
            if (column.editable) {
              return this.columnEdited = { itemId: item[this.propDataKey], columnId: column.id };
            }
          }
        }

      }
    }

    this.columnEdited = null;

  }

  //-----


  onPressShiftTab() {
    if (this.columnEdited) {
      var originalColumnId: string = this.columnEdited.columnId;
      var originalItemId: string = this.columnEdited.itemId;
    } else {
    return
    }


    let indexColumn = this.propColumns.findIndex((column: IPCVTableColumn) => {
      return column.id === originalColumnId;
    })

    for (var i = indexColumn - 1; i >= 0; i--) {
      let column: IPCVTableColumn = this.propColumns[i];
      if (column) {
        if (column.editable) {
          return this.columnEdited = { itemId: originalItemId, columnId: column.id };
        }
      }
    }

    let itemId = this.getDataPaginated.findIndex((item: any) => {
      return item[this.propDataKey] === originalItemId;
    })

    for (var y = itemId - 1; y >= 0; y--) {

      let item = this.getDataPaginated[y]

      if (item) {

        for (var i = this.propColumns.length - 1; i >= 0; i--) {
          let column: IPCVTableColumn = this.propColumns[i];
          if (column) {
            if (column.editable) {
              return this.columnEdited = { itemId: item[this.propDataKey], columnId: column.id };
            }
          }
        }

      }
    }

    for (var y = this.getDataPaginated.length - 1; y > itemId + 1; y--) {

      let item = this.getDataPaginated[y]

      if (item) {

        for (var i = this.propColumns.length; i >= 0; i--) {
          let column: IPCVTableColumn = this.propColumns[i];
          if (column) {
            if (column.editable) {
              return this.columnEdited = { itemId: item[this.propDataKey], columnId: column.id };
            }
          }
        }

      }
    }

    this.columnEdited = null;

  }

  onPressEnterInPaginationInput(): void {

    if (isNaN(parseInt(this.pageInput))) {
      this.pageInput = (this.pageActif + 1).toString();
    } else {

      let newPage: number = parseInt(this.pageInput);

      let pageCount: number = this.getPageCount;

      if (newPage <= pageCount && newPage > 0) {

        this.pageActif = newPage - 1;

      } else if (newPage > pageCount) {

        this.pageActif = pageCount - 1;
        this.pageInput = pageCount.toString();

      } else if (newPage <= 0) {

        this.pageActif = 0;
        this.pageInput = "1";

      } else {

        this.pageInput = (this.pageActif + 1).toString();

      }

    }
  }

  firstPage() {
    if (!this.getIsPreviousPageUnavailable) {
      this.pageActif = 0;
    }
  }

  lastPage() {
    if (!this.getIsNextPageUnavailable) {
      this.pageActif = Math.ceil(this.getDataFiltered.length / this.itemsPerPage) - 1;
    }
  }

  nextPage() {
    if (!this.getIsNextPageUnavailable) {
      this.pageActif++;
    }
  }

  previousPage() {
    if (!this.getIsPreviousPageUnavailable) {
      this.pageActif--;
    }
  }

  // #endregion


}

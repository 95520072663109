import { LanguageDate } from "../../models/languageDate";

export class InitInventory {

    product : string = "";
    class: string = "";
    currentDate: string = "";
	dateFrom: string = "";
    dateTo: string = "";
    dateFormat: string = "YYYY-MM-DD";
    inventory: { [ date: string ]: number } = {};
    defaultDays: boolean[] = [false, false, false, false, false, false, false];

    imgGenerate1: string = '../_Images/Generate4.png';
    imgGenerate2: string = '../_Images/Generate5.png';

    languageInventory: LanguageInventory = new LanguageInventory();

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }
}

export class LanguageInventory {
    product: string = "Produit";
    class: string = "Classe";
    dateFrom: string = "Date - du";
    dateTo: string = "Date - au";
    quantity: string = "Quantité";
    add: string = "Ajouter";
    release: string = "Relâcher";
  generate: string = "Générer";
  languageDate: LanguageDate = new LanguageDate();
}

export class InventoryModel {
    inventory: number = 0;
    isReduced: boolean = false;

    constructor(inventory: number, isReduced: boolean) {
        this.inventory = inventory;
        this.isReduced = isReduced;
    }
}







































import { Vue, Component, Prop, Watch, Emit, PropSync } from "vue-property-decorator";

@Component
export default class ImageContainer extends Vue {

  @Prop({ type: String, default: "" }) readonly src!: string;
  @Prop({ type: Number, default: 0 }) readonly width!: number;
  @Prop({ type: Number, default: 0 }) readonly height!: number;
  @Prop({ type: Number, default: 0 }) readonly canSelect!: boolean;
  @Prop({ type: Number, default: 1 }) readonly selectMultiple!: boolean;

  @Prop({type: String, default: ""}) name!: string;
  @PropSync('selected', { type: Boolean }) syncedSelected!: boolean

  

  m_isOnLoad: boolean = true;
  m_ErrorOnLoad: boolean = false;

  get getImageStyle() : string {
      return 'width:' + this.width + 'px; height:' + this.height + 'px';
  }

  onClickImage(): void {
      if (this.canSelect) {
          if (this.selectMultiple) {
              this.syncedSelected = !this.syncedSelected;
          } else {
              this.$emit("setSelected");
          }
      }
  }

  onloadImage() {
      this.m_isOnLoad = false;
  }

  onErrorImage() {
      this.m_isOnLoad = false;
      this.m_ErrorOnLoad = true;
  }

  onUpdateName(newName: string) {
      this.m_isOnLoad = true;
      this.$emit("update:name", {newName: newName, callback: this.updateCompleted});
  }

  updateCompleted() {
      this.m_isOnLoad = false;
  }

}


import { PaxModel } from "../../models/paxModel";
import { initObject } from "../../utilities/utils";

export class PaxByPickupPoint {
    code: number = 0;
    time: string = "";
    name: string = "";
    listPax: PaxModel[] = [];

    constructor(value: any) {
        initObject(this, value);
    }

}
import { LanguageDate } from "../../models/languageDate";
import moment from 'moment';

export class InitEditCalendar {
    languageDate: LanguageDate = new LanguageDate();
      
    inventory: { [ date: string ]: number } = {};
    
    readonly: boolean = false;

    currentDate: string = moment().format("YYYY-MM-DD");

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }
}
import { EnumRoomingType } from "../../utilities/enum";
import { LanguageInvcprodModel } from './languageInvcprodModel';
import { initObject } from "../../utilities/utils";
import { PickupPointModel } from "../../models/pickUpPointModel";
import DateWithFixedDurationModel from "./dateWithFixedDurationModel";

export class InitialisationModel {

    dateReservation: string = "";
    dateFrom: string = "";
    dateTo: string = "";
    product: string = "";
    nbPax: number = 1;
    roomingType: EnumRoomingType = EnumRoomingType.Basic;
    withBus: boolean = false;
    hasInventory: boolean = false;
    pageCode: number = 0;
    dateFormat: string = "YYYY-MM-DD";
    baseURL: string = "";
    file: string = "";
    listPickup: PickupPointModel[] = [];
    listDate: DateWithFixedDurationModel[] = [];
    isFixedDuration: boolean = false;
    defaultIndex: number = -1;
    hasOption: boolean = false;

    language: LanguageInvcprodModel = new LanguageInvcprodModel(null);

    constructor(value: any) {
        initObject(this, value);
    }

}
import PCVTableColumnTypeEnum from './pcvTableColumnTypeEnum';

export default class PCVTableColumn {
    key: string = "";
    label: string = "";
    type: PCVTableColumnTypeEnum = PCVTableColumnTypeEnum.Text;
    icon: string = "";
    editable: boolean = false;
    sortable: boolean = true;
    filterable: boolean = true;
    selectable: boolean = true;
    minWidth: number = 0;
    styleWidth: any = { };
    cssClass: string = "";
}
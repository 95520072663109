






















































import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { RoomPaxManagementModel } from "../models/roomPaxManagementModel";
import { PaxModel } from '../models/paxModel';
import { RoomModel } from '../models/roomModel';

@Component
export default class RoomPaxManagement extends Vue {

    @Prop({ type: Object }) readonly propData!: RoomPaxManagementModel;

    roomSelected: RoomModel = this.propData.listRoom[0];

    get getListPassengerNotAssigned(): PaxModel[] {
        return this.propData.listPax.filter((pax: PaxModel) => {
            let valueReturn: boolean = true;
            this.propData.listRoom.forEach((room: RoomModel) => {
                room.listPax.forEach((paxInRoom: PaxModel) => {
                    if (paxInRoom.code === pax.code) {
                        valueReturn = false;
                    }
                })
            })
            return valueReturn;
        })
    }

    beforeMount() {
        this.roomSelected = this.propData.listRoom[0];
    }

    onClickRoom(room: RoomModel) {
        this.roomSelected = room;
    }

    addPaxInRoom(pax: PaxModel) {
        this.roomSelected.listPax.push(pax);
    }

    removePaxInRoom(pax: PaxModel) {
        
        if (this.propData.file.length == 0 || this.propData.file === pax.file) {
            let paxIndex = this.roomSelected.listPax.findIndex((paxInRoom: PaxModel) => paxInRoom.code === pax.code);

            if (paxIndex !== -1) {
                this.$delete(this.roomSelected.listPax, paxIndex);
            }
        }
    }

    getClassRoom(room: RoomModel){
        return room === this.roomSelected ? "pcv-tours-invcprod-room-selected" : "";
    }

    getRoomTitle(room: RoomModel, index: string): string {

        if (room.name.length === 0) {
            return this.propData.language.newRoom + ' ' + (parseInt(index) + 1).toString();
        } else {
            return room.name;
        }
    }

    getClassPax(pax: PaxModel): string {
        if (this.propData.file.length !== 0 && this.propData.file !== pax.file) {
            return "pcv-tours-pax-room-disabled";
        } else {
            return "";
        }
    }

}









































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import PCVDatepicker from "@/components/common/PCVDatepicker.vue";
import { InitialisationAvailabilityModel } from "./models/initialisationAvailabilityModel";
import { PCVAvailabilityModel } from "./models/pcvAvailabilityModel";
import { ClassModel } from './models/classModel';
import { ProductModel } from './models/productModel';
import { CategoryModel } from './models/categoryModel';
import moment from 'moment';
import DateWithFixedDurationModel from './models/dateWithFixedDurationModel';
import { LanguageAvailabilityModel } from "./models/languageAvailabilityModel";

const formatter = new Intl.NumberFormat('en-CA', {
    minimumFractionDigits: 2
})

@Component({
    components: {
        "date-picker": PCVDatepicker
    },
    filters: {
        currency(value: string): string {
            return formatter.format(parseFloat(value)).replace(",", " ");
        },
        dateFormat(value: string, dateFormat: string): string {
            return moment(value).format(dateFormat);
        }
    }
})
export default class PCVAvailability extends Vue {

    @Prop({ type: String, default: "" }) readonly propProductSelected!: string;
    @Prop({ type: Object, default: () => new InitialisationAvailabilityModel() }) propInit!: InitialisationAvailabilityModel;

    isClear: boolean = false;
    isLoading: boolean = false;
    dataAvailability: PCVAvailabilityModel = new PCVAvailabilityModel(this.propInit);

    listClasses: ClassModel[] = [];
    selectedClassCode: number = 0;
    selectedCategoryCode: number = 0;

    isFixedDuration: boolean = false;
    defaultIndex: number = -1;
    listDates: DateWithFixedDurationModel[] = [];
    dateIsLoading: boolean = false;
    selectedDate: DateWithFixedDurationModel = new DateWithFixedDurationModel();

    get selectedDateProxy(): DateWithFixedDurationModel {
        return this.selectedDate;
    }

    set selectedDateProxy(value: DateWithFixedDurationModel) {
        this.propInit.dateFrom = value.dateFrom;
        this.propInit.dateTo = value.dateTo;
        this.selectedDate = value;
    }

    get getDateFormat(): string {
        if (this.propInit.dateFormat){
            return this.propInit.dateFormat
        } else {
            return "YYYY-MM-DD";
        }
    }

    get getDuration(): string {
       let tmpFrom = moment(this.propInit.dateFrom);
       let tmpTo = moment(this.propInit.dateTo);

        let durationNight = tmpTo.diff(tmpFrom, "days");
        let durationDay = durationNight + 1;

        return `${durationDay} ${this.propInit.language.days} / ${durationNight} ${this.propInit.language.nights}`
    }

    get getCurrentClass(): ClassModel | undefined {
        return this.listClasses.find(element => element.code === this.selectedClassCode)
    }

    get getCurrentCategory(): CategoryModel | undefined {
        if (this.getCurrentClass) {
            return this.getCurrentClass.listCategory.find(element => element.code === this.selectedCategoryCode)
        } else {
            return undefined;
        }
    }

    get getListCategory(): CategoryModel[] {
        if (this.getCurrentClass) {
            return this.getCurrentClass.listCategory;
        } else {
            return [];
        }
    }

    get getListProduct(): ProductModel[] {
        if (this.getCurrentCategory) {
            return this.getCurrentCategory.listProduct;
        } else {
            return [];
        }
    }

    getProductCSS(p_Product: ProductModel) : string[] {
        var returnedClass: string[] = [];

        if (p_Product.name === this.propProductSelected) {
            returnedClass.push("pcv-availability-table-bold");
        } else {
            returnedClass.push("pcv-availability-table-mauve");
        }

        if ((p_Product.availability < 1) && (p_Product.availability !== -1)) {
            returnedClass.push("pcv-availability-table-unavailable");
        }

        return returnedClass;
    }

    getCategoryCSS(p_CategoryCode: number): string {
      if (this.selectedCategoryCode === p_CategoryCode) {
        return "pcv-availability-table-selected"
      } else {
        return ""
      }
    }

    getAvailabilityText(p_Product: ProductModel) {
      if (p_Product.availability === -3) {
          return p_Product.errorMessage;
      } else if (p_Product.availability === -2) {
          return this.dataAvailability.language.noPricing;
      } else if (p_Product.availability === -1) {
          return this.dataAvailability.language.unlimited;
      } else {
          return p_Product.availability;
      }
    }

    mounted() {
        this.propInit.language = new LanguageAvailabilityModel();
        this.dataAvailability = new PCVAvailabilityModel(this.propInit);
    }

    @Watch("propInit")
    onWatchPropInit(value: InitialisationAvailabilityModel) {
        this.dataAvailability = new PCVAvailabilityModel(value);
    }

    @Watch("propProductSelected")
    onWatchPropProductSelected(value: string) {
        if (value)
        {
            this.loadDates();
        }
    }

    @Watch("propInit.dateFrom")
    onWatchDateFrom(value: string) {
        if (this.propInit.dateTo !== "") {
            var dateFrom = moment(value);
            var dateTo = moment(this.propInit.dateTo);
            if (dateFrom.isAfter(dateTo)) {
                this.propInit.dateTo = value;
            }
        }

        this.reloadClasses();
    }

    @Watch("propInit.dateTo")
    onWatchDateTo(value: string) {
        if (this.propInit.dateFrom !== "") {
            var dateFrom = moment(this.propInit.dateFrom);
            var dateTo = moment(value);
            if (dateFrom.isAfter(dateTo)) {
                this.$nextTick(() => {
                    this.propInit.dateTo = this.propInit.dateFrom;
                })
            }
        }

        this.reloadClasses();
    }

    @Watch("selectedClassCode")
    onWatchSelectedClassCode(value: ClassModel | null) {
        if ((this.getCurrentClass) && (this.getCurrentClass.listCategory.length > 0)) {
            this.selectedCategoryCode = this.getCurrentClass.listCategory[0].code;
        }
    }

    loadDates() {
        this.dateIsLoading = true;

        let promise = this.dataAvailability.api.fetchDates(this.propProductSelected);
        promise.then( (result) => {
            this.isFixedDuration = result?.isFixedDuration ?? false;
            this.defaultIndex = result?.defaultIndex ?? -1;
            this.listDates = result?.listDates ?? [];
            this.dateIsLoading = false;

            if (this.listDates.length > 0 && this.defaultIndex > -1) {
                this.selectedDateProxy = this.listDates[this.defaultIndex];
            }

            if (!this.isFixedDuration) {
                this.reloadClasses();
            }

        }).catch( (error) => {
            console.log(error);
            this.dateIsLoading = false;
        });
    }

    reloadClasses() {
        if (this.isLoading) {
            return ;
        }

        let selectedClass: number = this.selectedClassCode;
        let selectedCategory: number = this.selectedCategoryCode;

        this.selectedClassCode = 0;
        this.selectedCategoryCode = 0;
        this.listClasses = [];

        if ((this.propProductSelected) && (this.propProductSelected.length > 0) && (this.propInit.dateFrom.length > 0) && (this.propInit.dateTo.length > 0)) {
            this.isLoading = true;
            let resultPromise = this.dataAvailability.api.fetchClasses(this.propProductSelected, this.propInit.dateFrom, this.propInit.dateTo);

            resultPromise.then((listClass: ClassModel[]) => {
                this.isLoading = false;
                this.listClasses = listClass;

                if (this.listClasses.length > 0) {
                    this.selectedClassCode = this.listClasses[0].code;

                    let classFound = this.listClasses.find(element => element.code === selectedClass);
                    if (classFound) {
                        let categoryFound = classFound.listCategory.find(element => element.code === selectedCategory);
                        if (!categoryFound) {
                            selectedCategory = 0;
                        }
                    } else {
                        selectedClass = 0;
                        selectedCategory = 0;
                    }
                }

                if (selectedClass > 0) {
                    this.selectedClassCode = selectedClass;
                }
                if (selectedCategory > 0) {
                    this.$nextTick(() => {
                        this.selectedCategoryCode = selectedCategory;
                    });
                }
            }).catch((error) => {
                this.isLoading = false;
                console.log(error);
            });
        }
    }

    onClickRefresh() {
        this.reloadClasses();
    }

    onClickCategory(p_Category: CategoryModel) {
        this.selectedCategoryCode = p_Category.code;
    }

    clear() {
        this.isClear = true;
    }

    formatDate(value: string) : string {
        return moment(value).format(this.propInit.dateFormat);
    }
}


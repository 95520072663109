import "es6-promise/auto";
import Axios, { AxiosInstance } from 'axios';
import { RoomModel } from "../../models/roomModel";
import { BusDataModel } from "../../models/busDataModel";
import moment from "moment";

export class pcvToursPaxAssignAPI {

  private axios: AxiosInstance;
  private pageCode: number = 0;
  private file: string = "";

  constructor(baseURL: string, pageCode: number, file: string) {
    this.axios = Axios.create({
      baseURL: baseURL
    })
    this.pageCode = pageCode;
    this.file = file;
  }

  fetchBusData(
    product: string,
    pricingClassCode: number,
    dateFrom: string,
    dateTo: string
  ): Promise<any> {
  
    return this.axios.post("GetBusData.ashx", 
      {
        file: this.file,
        product: product,
        pricingClass: pricingClassCode,
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD")
      },
      {
        params: {
          pageCode: this.pageCode
        }
      }
    ).then((response) => {
  
      if (response.data) {
        return new BusDataModel(response.data);
      } else {
        return new BusDataModel(null);
      }
  
    })
  }

  postComplete(
    product: string,
    pricingClassCode: number,
    pricingClassName: string,
    dateFrom: string,
    dateTo: string,
    listRoomDelete: number[],
    listRoom: RoomModel[]
  ): Promise<any> {
  
    return this.axios.post("Complete.ashx", 
      {
        product: product,
        pricingClass_code: pricingClassCode,
        pricingClass_name: pricingClassName,
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        listRoomDelete: listRoomDelete,
        listRoom: listRoom,
        file: this.file
      },
      {
        params: {
          pageCode: this.pageCode
        }
      }
    ).then((response) => {
  
      return response;
  
    })
  }

}

export enum EnumPagination {
    DateClassCategory = 0,
    RoomSharingType = 1,
    RoomPax = 2,
    SharableRoom = 3,
    PaxSelection = 4,
    BusAssignment = 5,
    ChildrenAssignment = 6,
    Confirmation = 7,
    ProductOption = 8
}

export enum EnumSharingType  {
    NotShared = 0,
    NewShared = 1,
    ExistingShared = 2
}

export enum EnumConfirmationType {
  NewRooms = 0,
  NewSharedRoom = 1,
  ExistingSharedRoom = 2,
  Basic = 3,
  Bus = 4,
  Children = 5
}
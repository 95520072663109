import { LanguageDate } from "@/models/languageDate";
import moment from 'moment';

export class InitCalendar {
    languageDate: LanguageDate = new LanguageDate();
    listItems: CalendarItem[] = [];
    currentDate: string = moment().format("YYYY-MM-DD");

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }
}

export class CalendarItem {
    dateFrom: string = "";
    dateTo: string = "";
    classCSS: string = "";
}
import InitialisationToursRoomSharingModel from "./initialisationToursRoomSharingModel";
import LanguageToursRoomSharingModel from "./languageToursRoomSharingModel";
import RoomModel from "./roomModel";

export default class PCVToursRoomSharingModel {
    
    readonly language: LanguageToursRoomSharingModel = new LanguageToursRoomSharingModel(null);
    readonly listRoom: RoomModel[] = [];
    
    constructor(init: InitialisationToursRoomSharingModel) {
        if (init)
        {
            this.language = init.language;
            this.listRoom = init.listRoom;
        }
    }
}












import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVToursPaxAssignModel } from '../models/pcvToursPaxAssignModel';
import PCVTable from '@/components/pcvOldTable/PCVTable.vue';
import { PCVTableColumn } from '@/components/pcvOldTable/models/PCVTableColumn';
import { ProductModel } from '../models/productModel';
import { typeFunctionNext } from '../../utilities/utils';
import { EnumPaginationToursPaxAssign } from '../utilities/enum';
import { RoomModel } from '../../models/roomModel';
import { PaxModel } from '../../models/paxModel';
import { EnumRoomingType } from '../../utilities/enum';

@Component({
    components: {
        "pcv-table": PCVTable
    }
})
export default class ProductSelection extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursPaxAssignModel;

    get getColumns(): PCVTableColumn[] {
        return [
            new PCVTableColumn("productCode", this.propData.language.product, "String"),
            new PCVTableColumn("pricingClassName", this.propData.language.class, "string"),
            new PCVTableColumn("dateFrom", this.propData.language.dateFrom, "Date"),
            new PCVTableColumn("dateTo", this.propData.language.dateTo, "Date")
        ]
    };

    beforeMount(){
        this.sendFunctionNext();
    }

    logicNext() {
        if (this.propData.productSelected) {
            this.propData.listRoomProductSelected = [];
            this.propData.productSelected.listRoom.forEach((room) => {
                let tmpNewRoom = new RoomModel(room);
                tmpNewRoom.listPax = [];
                room.listPax.forEach((pax) => {
                    tmpNewRoom.listPax.push(new PaxModel(pax));
                })
                this.propData.listRoomProductSelected.push(tmpNewRoom);
            })

            this.propData.previousPage.push(this.propData.currentPage);
            if (this.propData.productSelected.roomingType === EnumRoomingType.Room) {
                this.propData.currentPage = EnumPaginationToursPaxAssign.RoomPaxAssign;
            } else if (this.propData.productSelected.roomingType === EnumRoomingType.Basic) {
                this.propData.currentPage = EnumPaginationToursPaxAssign.PaxAssignSelection;
            }
        }
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    sendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
        return errorMsg;
    }

    onDoubleClick(item: ProductModel) {
        this.propData.productSelected = item;
        this.logicNext();
    }

}


































































import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { InitialisationToursPaxAssignModel } from "./models/initialisationToursPaxAssignModel";
import { PCVToursPaxAssignModel } from "./models/pcvToursPaxAssignModel";
import ProductSelection from "./components/productSelection.vue";
import RoomPaxAssign from "./components/roomPaxAssign.vue";
import PaxAssignSelection from "./components/paxAssignSelection.vue";
import BusAssignment from "./components/busAssignment.vue";
import { typeFunctionNext } from '../utilities/utils';
import { EnumPaginationToursPaxAssign } from './utilities/enum';

@Component({
    components: {
        "product-selection": ProductSelection,
        "room-pax-assign": RoomPaxAssign,
        "pax-assign-selection": PaxAssignSelection,
        "bus-assignment": BusAssignment
    }
})
export default class PCVToursPaxAssign extends Vue {

    @Prop({ type: Object, default: () => new InitialisationToursPaxAssignModel(null) }) readonly propInit!: InitialisationToursPaxAssignModel;

    data: PCVToursPaxAssignModel = new PCVToursPaxAssignModel(new InitialisationToursPaxAssignModel(null));
    functionNext: typeFunctionNext = () => {};
    showCompleteButton: boolean = false;

    isClear: boolean = false;

    get getEnumPagination() : typeof EnumPaginationToursPaxAssign {
        return EnumPaginationToursPaxAssign;
    }

    get getIsDisabled(): boolean {
        return this.data.previousPage.length === 0;
    }

    get getNextImage1(): string {
        return this.showCompleteButton ? this.data.imgComplete1 : this.data.imgNext1;
    }

    get getNextImage2(): string {
        return this.showCompleteButton ? this.data.imgComplete2 : this.data.imgNext2;
    }

    get getNextLabel(): string {
        return this.showCompleteButton ? this.data.language.complete : this.data.language.next;
    }

    updateShowCompleteButton(value: boolean) {
        this.showCompleteButton = value;
    }

    @Watch("propInit")
    onWatchPropInit(newInit: InitialisationToursPaxAssignModel) {
        this.data = new PCVToursPaxAssignModel(new InitialisationToursPaxAssignModel(newInit));
    }

    beforeMount(){
        //this.data = new PCVToursInvcprodModel(this.propInit);
    }

    setFunctionNext(newFunctionNext: typeFunctionNext) {
        this.functionNext = newFunctionNext;
    }

    onClickPreviousPage() {

        let thePriviousPage = this.data.previousPage.pop();
        if (typeof thePriviousPage !== "undefined") {
            this.data.currentPage = thePriviousPage;
        }

    }

    clear() {
        this.isClear = true;
    }

    emitError(errorMsg: string) {
        this.$emit("on-error", errorMsg, this.data.language.errorCloseButton);
    }

    @Emit("on-complete")
    emitComplete(){

    }

    @Emit("on-cancel")
    emitCancel(){}

}


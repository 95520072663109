
















































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { fetchOptionProduct, typeFunctionNext } from '../../utilities/utils';
import { RoomModel } from '../../models/roomModel';
import { EnumPagination, EnumConfirmationType } from '../utilities/enum';

@Component
export default class ChildrenAssignment extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;
    selectAllChecked: boolean = false;

    beforeMount() {
        this.sendFunctionNext();
        if (!this.propData.roomChildrenSelected && this.propData.listRoom.length > 0) {
            this.propData.roomChildrenSelected = this.propData.listRoom[0];
        }
    }

    @Watch("propData.listRoom")
    onWatchListRoom(){
      if (!this.propData.roomChildrenSelected && this.propData.listRoom.length > 0) {
        this.propData.roomChildrenSelected = this.propData.listRoom[0];
      }
    }

    @Watch("selectAllChecked")
    onWatchSelectAllChecked(value: boolean) {

        if (value) {
            this.propData.listChildren = [];
            this.propData.listChildren = this.propData.listPax;
        } else if (this.propData.listChildren.length === this.propData.listPax.length) {
            this.propData.listChildren = [];
        }

    }

    onChange() {
        if (this.selectAllChecked) {
            if (this.propData.listChildren.length !== this.propData.listPax.length) {
                this.selectAllChecked = false;
            }
        }
    }

    @Emit("receive-function-next")
    sendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
        return errorMsg;
    }

    logicNext() {
        if (this.propData.listChildren.length > 0 && this.propData.roomChildrenSelected) {
          this.propData.confirmationType = EnumConfirmationType.Children;
          this.propData.previousPage.push(this.propData.currentPage);
          this.propData.listOptionSelected = [];
          if (this.propData.hasOption)
          {
            fetchOptionProduct(this.propData);
            this.propData.currentPage = EnumPagination.ProductOption;
          }
          else
          {
            this.propData.currentPage = EnumPagination.Confirmation;
          }
        } else {
          if (this.propData.listChildren.length == 0) {
            this.emitError(this.propData.language.errorNoPaxSelected);
          } else if (!this.propData.roomChildrenSelected) {
            this.emitError(this.propData.language.errorNoRoomSelected);
          }
        }
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    onClickRoom(room: RoomModel) {
        this.propData.roomChildrenSelected = room;
    }

    getClassRoom(room: RoomModel){
        return this.propData.roomChildrenSelected == room ? 'pcv-tours-invcprod-room-selected' : '';
    }

}




































































































  import { Vue, Component, Watch, Prop, Model, Emit } from "vue-property-decorator";

  import moment from 'moment';
import { LanguageDate } from '@/models/languageDate';

  const formatStandard = ["YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY"];


  const formatAlternativValid = [
    [ 
      "YYYYMD",
      "YYMD"
    ],
    [
      "DMYYYY",
      "DMYY"
    ],
    [
     "MDYYYY",
     "MDYY"
    ]
  ];

  

  @Component
  export default class PCVDatepicker extends Vue {

    isOpen: boolean = false;
    calendar: any[] = [];
    momentInputValue: moment.Moment | null = null;
    monthSelected: string = "";
    yearSelected: string = "";
    inputValue: string = "";

    @Model('change', { type: String }) readonly propModel!: string
    @Prop({ type: Object, default: { month: [] } }) readonly propLanguage!: LanguageDate;
    @Prop({ type: String, default: "YYYY-MM-DD" }) readonly propDateFormat!: string;

    calendarMoment: moment.Moment = moment();

    get getInputValue(): string {
      if (this.momentInputValue) {
        return this.momentInputValue.format(this.propDateFormat);
      } else {
        return "";
      }
    }

    get getTodayDate(): string {
      return moment().format(this.propDateFormat);
    }

    @Emit("change")
    EmitDateChange(value: string ): string {
      return value;
    }

    mounted() {

      if (this.propModel.trim() !== "") {
        this.momentInputValue = moment(this.propModel);
        this.inputValue = this.momentInputValue.format(this.propDateFormat);
      } else {
        this.momentInputValue = null;
      }

      window.addEventListener("click", this.OnClickExternDatepicker, true);
    }

    beforeDestroy() {
      window.removeEventListener("click", this.OnClickExternDatepicker, true);
    }

    OnClickExternDatepicker(e: Event) {
      // close dropdown when clicked outside
      if (e.target) {
        if (!this.$el.contains(<Node>e.target)) {
          this.isOpen = false;
        }
      }
    }

    @Watch("momentInputValue")
    watchIsOpen(newValue: moment.Moment | null) {
      if (newValue) {
        this.EmitDateChange(newValue.format("YYYY-MM-DD"));
      } else {
        this.EmitDateChange("");
      }
    }

    @Watch("propModel")
    watchModel(newValue: string) {
      if (newValue.trim() !== "") {
        this.momentInputValue = moment(newValue);
        this.inputValue = this.momentInputValue.format(this.propDateFormat);
      } 
    }

    onPressTab(){
      this.isOpen = false;
      this.updateMomentValue(this.inputValue);
    }

    onChangeInputValue(event: Event) {
      if (event.target) {
        this.updateMomentValue((<HTMLInputElement>event.target).value)
      }
    }

    updateMomentValue(newDateString: string){
      if (moment(newDateString, formatAlternativValid[formatStandard.indexOf(this.propDateFormat)]).isValid()) {
          this.momentInputValue = moment(newDateString, formatAlternativValid[formatStandard.indexOf(this.propDateFormat)]);
        } else {
          this.momentInputValue = null;
        }
        this.isOpen = false;
    }

    openDatepicker() {
      if (!this.isOpen) {
        if (this.momentInputValue) {
          this.calendarMoment = moment(this.momentInputValue);
        } else {
          this.calendarMoment = moment();
        }
        this.calendarMoment.date(1);
        this.yearSelected = this.calendarMoment.format("YYYY");
        this.monthSelected = this.calendarMoment.format("M");
        this.setCalendar();
        this.isOpen = true;
      }
    }

    setCalendar() {
      const startWeek = this.calendarMoment.startOf('month').week();
      const endWeek = startWeek + 5;

      let calendar = []
      for (var week = startWeek; week <= endWeek; week++) {
        calendar.push({
          week: week,
          days: Array(7).fill(0).map((n, i) => moment(this.calendarMoment).week(week).startOf('week').add(n + i, 'day'))
        })
      }

      this.calendar = calendar;

    }

    onClickDay(day: moment.Moment) {
      this.momentInputValue = day;
      this.isOpen = false;
    }

    onChangeSelectedMonth() {
      this.calendarMoment.month(parseInt(this.monthSelected) - 1);
      this.setCalendar();
    }

    onChangeSelectedYear() {
      this.calendarMoment.year(parseInt(this.yearSelected));
      this.setCalendar();
    }

    getClassDay(day: moment.Moment) {

      if (this.momentInputValue && day.isSame(this.momentInputValue)){
        return "pcv-datepicker-day-selected";
      } else {
        return day.format('MM') === this.calendarMoment.format('MM') ? 'pcv-datepicker-day-in-month' : 'pcv-datepicker-not-in-month';
      }

    }

    nextMonth() {
      this.calendarMoment.add(1, "M");
      this.setCalendar();
      this.yearSelected = this.calendarMoment.format("YYYY");
      this.monthSelected = this.calendarMoment.format("M");
    }

    previousMonth() {
      this.calendarMoment.subtract(1, "M");
      this.setCalendar();
      this.yearSelected = this.calendarMoment.format("YYYY");
      this.monthSelected = this.calendarMoment.format("M");
    }

    setToday() {
      this.momentInputValue = moment();
      this.isOpen = false;
    }

  }












import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { EnumPagination, EnumConfirmationType } from '../utilities/enum';
import { fetchOptionProduct, typeFunctionNext } from '../../utilities/utils';
import { RoomModel } from '../../models/roomModel';
import { RoomPaxManagementModel } from '../../models/roomPaxManagementModel';
import RoomPaxManagement from "../../components/roomPaxManagement.vue";

@Component({
    components: {
        "room-pax-management": RoomPaxManagement
    }
})
export default class RoomPax extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    get getRoomPaxManagementData(): RoomPaxManagementModel {
        return new RoomPaxManagementModel(this.propData.listRoom, this.propData.listPax, this.propData.language);
    }

    beforeMount() {
        this.SendFunctionNext();

        let nbPaxNotInRoom: number = this.propData.listPax.length;
        this.propData.listRoom.forEach((room: RoomModel) => nbPaxNotInRoom -= room.listPax.length)

        if (nbPaxNotInRoom === this.propData.listPax.length) {
            let paxIndex = 0;
            let paxLenght = this.propData.listPax.length;
            if (paxIndex < paxLenght) {
                let roomLenght = this.propData.listRoom.length;
                let nbPaxMaximum: number = this.propData.categorySelected.nbPaxMaximum;
                let boolQuit: boolean = false;
                for (let roomIndex = 0; roomIndex < roomLenght; roomIndex++) {
                    if (boolQuit) {
                        break;
                    }
                    for (let nbPaxMaximumIndex = 0; nbPaxMaximumIndex < nbPaxMaximum; nbPaxMaximumIndex++) {
                        if (paxIndex < paxLenght) {
                            this.propData.listRoom[roomIndex].listPax.push(this.propData.listPax[paxIndex]);
                            paxIndex++;
                        } else {
                            boolQuit = true;
                            break;
                        }
                    }
                }
            }
        }
    }


    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

    logicNext() {
        let nbPaxMaximum: number = this.propData.categorySelected.nbPaxMaximum;
        let nbPaxMinimum: number = this.propData.categorySelected.nbPaxMinimum;
        let roomCapacityValid: boolean = this.propData.listRoom.every((room) => {
            return ((room.listPax.length >= nbPaxMinimum) && (room.listPax.length <= nbPaxMaximum));
        })

        if (roomCapacityValid) {
            this.propData.confirmationType = EnumConfirmationType.NewRooms;
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.listOptionSelected = [];

            if (this.propData.hasOption)
            {
                fetchOptionProduct(this.propData);
                this.propData.currentPage = EnumPagination.ProductOption;
            }
            else
            {
                this.propData.currentPage = EnumPagination.Confirmation;
            }
        } else {
            this.emitError(this.propData.language.errorRoomCapacity);
        }
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }


    @Emit("on-error")
    emitError(errorMsg: string) {
        return errorMsg;
    }

}


import { PaxModel } from "./paxModel";

export class PaxSelectionViewModel {

    listPaxSelected: PaxModel[];
    listPax: PaxModel[];
    language: any;
    hasInventory: boolean = false;
    inventory: number = 0;

    constructor(listPaxSelected: PaxModel[], listPax: PaxModel[],language: any, hasInventory?: boolean, inventory?: number) {
        this.listPaxSelected = listPaxSelected;
        this.listPax = listPax;
        this.language = language;

        if (hasInventory) {
            this.hasInventory = hasInventory;
        }

        if (inventory) {
            this.inventory = inventory;
        }

    }

}





import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class PCVWebIcon extends Vue {
    
    @Prop({ type: String, default: "fas" }) prefix!: string;
    @Prop({ type: String, default: "" }) icon!: string;

    get getPrefix() {
        if (this.prefix) {
            return this.prefix;
        } else {
            return "fas";
        }
    }

}

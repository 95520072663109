import { RoomModel } from "./roomModel";
import { PaxModel } from "./paxModel";

export class RoomPaxManagementModel{

    listRoom: RoomModel[];
    listPax: PaxModel[];
    language: any;
    file: string = "";

    constructor(listRoom: RoomModel[], listPax: PaxModel[],language: any, file?: string) {
        this.listRoom = listRoom;
        this.listPax =listPax;
        this.language = language;
        if (file) {
            this.file = file;
        }
    }
}




















































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { InitialisationModel } from "./models/initialisationModel";
import { EnumPagination } from "./utilities/enum";
import { PCVToursInvcprodModel } from "./models/pcvToursInvcprodModel";

import DateClassCategory from "./components/dateClassCategory.vue";
import RoomSharingType from "./components/roomSharingType.vue";
import RoomPax from "./components/roomPax.vue";
import ShareableRoom from "./components/shareableRoom.vue";
import PaxSelection from './components/paxSelection.vue';
import BusAssignment from './components/busAssignment.vue';
import Confirmation from "./components/confirmation.vue";
import ChildrenAssignment from './components/childrenAssignment.vue';
import { typeFunctionNext } from './../utilities/utils';
import ProductOption from "./components/productOption.vue";

@Component({
  components: {
    "date-class-category": DateClassCategory,
    "room-sharing-type": RoomSharingType,
    "room-pax": RoomPax,
    "shareable-room": ShareableRoom,
    "pax-selection": PaxSelection,
    "bus-assignment": BusAssignment,
    "confirmation": Confirmation,
    "children-assignment": ChildrenAssignment,
    "product-option": ProductOption,
  }
})
export default class PCVToursInvcprod extends Vue {

  @Prop({ type: Object, default: () => new InitialisationModel(null) }) readonly propInit!: InitialisationModel;

  data: PCVToursInvcprodModel = new PCVToursInvcprodModel(new InitialisationModel(null));
  functionNext: typeFunctionNext = () => {};
  showCompleteButton: boolean = false;

  isClear: boolean = false;

  get getEnumPagination() : typeof EnumPagination {
    return EnumPagination;
  }

  get getIsDisabled(): boolean {
    return this.data.previousPage.length === 0;
  }

  get getNextImage1(): string {
    return this.showCompleteButton ? this.data.imgComplete1 : this.data.imgNext1;
  }

  get getNextImage2(): string {
    return this.showCompleteButton ? this.data.imgComplete2 : this.data.imgNext2;
  }

  get getNextLabel(): string {
    return this.showCompleteButton ? this.data.language.complete : this.data.language.next;
  }

  updateShowCompleteButton(value: boolean) {
    this.showCompleteButton = value;
  }

  @Watch("propInit", { immediate: true, deep: true })
  onWatchPropInit(newValue: InitialisationModel){
    this.data = new PCVToursInvcprodModel(new InitialisationModel(newValue));
  }

  beforeMount(){
    //this.data = new PCVToursInvcprodModel(this.propInit);
  }

  setFunctionNext(newFunctionNext: typeFunctionNext) {
    this.functionNext = newFunctionNext;
  }

  onClickPreviousPage() {

    let thePriviousPage = this.data.previousPage.pop();
    if (typeof thePriviousPage !== "undefined") {
      this.data.currentPage = thePriviousPage;
    }

  }

  clear() {
    this.isClear = true;
  }
  emitError(errorMsg: string)
  {
    this.$emit("on-error", errorMsg, this.data.language.errorCloseButton);
  }

  @Emit("on-complete")
  emitComplete(){

  }

  @Emit("on-cancel")
  emitCancel(){}

}







    import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
    import { typeFunctionNext } from '../../utilities/utils';
    import { PCVToursPaxAssignModel } from '../models/pcvToursPaxAssignModel';
    import RoomPaxManagement from "../../components/roomPaxManagement.vue";
    import { RoomPaxManagementModel } from '../../models/roomPaxManagementModel';
import { RoomModel } from '../../models/roomModel';

    @Component({
        components: {
            "room-pax-management": RoomPaxManagement
        }
    })
    export default class RoomPaxAssign extends Vue {

        @Prop({ type: Object }) readonly propData!: PCVToursPaxAssignModel;

        get getRoomPaxManagementData(): RoomPaxManagementModel {
            if (this.propData.productSelected) {
                return new RoomPaxManagementModel(this.propData.listRoomProductSelected, this.propData.listPax, this.propData.language, this.propData.file);
            } else {
                return new RoomPaxManagementModel([], this.propData.listPax, this.propData.language, this.propData.file);
            }       
        }

        beforeMount() {
            this.sendFunctionNext();
            this.emitShowCompleteButton(true);
        }

        beforeDestroy() {
            this.emitShowCompleteButton(false);
        }

        postComplete(listRoom: RoomModel[]) {
            if (this.propData.productSelected) {

                let product = this.propData.productSelected;

                let resultPromise = this.propData.api.postComplete(
                   product.productCode,
                    product.pricingClassCode,
                    product.pricingClassName,
                    product.dateFrom,
                    product.dateTo,
                    [],
                    listRoom
                )

                resultPromise.then((result: any) => {
                    if (result.data.success) {
                        this.$emit("on-complete");
                    } else {
                        this.emitError(result.data.errorMessage);
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        }

        logicNext() {
            
            let listRoomPaxFinal: RoomModel[] = this.getRoomPaxManagementData.listRoom;
            let listRoomToSend: RoomModel[] = [];
            let roomEmpty = listRoomPaxFinal.find((room) => room.listPax.length == 0);

            if (roomEmpty) {
                this.emitError(this.propData.language.errorRoomEmpty);
            } else {
                if (this.propData.productSelected) {
                    this.propData.productSelected.listRoom.forEach((room) => {
                        let roomFound = listRoomPaxFinal.find((roomFinal) => roomFinal.code === room.code);
                        if (roomFound) {
                            if (roomFound.listPax.length !== room.listPax.length) {
                                listRoomToSend.push(roomFound);
                            } else {
                                let isDifferent: boolean = false;
                                let listPaxLength = roomFound.listPax.length;
                                for (let index = 0; index < listPaxLength; index++) {
                                    let paxFound = room.listPax.find((pax) => typeof roomFound != "undefined" && pax.code == roomFound.listPax[index].code);
                                    if (!paxFound) {
                                        isDifferent = true;
                                        break;
                                    }
                                }

                                if (isDifferent) {
                                    listRoomToSend.push(roomFound);
                                }
                            }
                        }
                    })
                }

                if (listRoomToSend.length > 0) {
                    this.postComplete(listRoomToSend);
                } else {
                    this.emitError(this.propData.language.errorNoChange);
                }
            }
        }

        functionNext: typeFunctionNext = () => {
            this.logicNext();
        }

        @Emit("receive-function-next")
        sendFunctionNext(){
            return this.functionNext;
        }

        @Emit("on-error")
        emitError(errorMsg: string){
            return errorMsg;
        }

        @Emit("update-show-complete-button")
        emitShowCompleteButton(value: boolean){
            return value;
        }

    }


import PCVTableColumn from './pcvTableColumn';

export default class PCVTableData {
    currentPage: number = 0;
    filterTermsHeader: any = {};
    columnSorted: PCVTableColumn = new PCVTableColumn();
    isSortReversed: boolean = false;
    isFilterDisplayed: boolean = false;
    itemsPerPage: number = 20;
    selectionSingle: any = {};
}









import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { fetchOptionProduct, typeFunctionNext } from '../../utilities/utils';
import { EnumPagination, EnumConfirmationType } from '../utilities/enum';
import { PaxModel } from './../../models/paxModel';
import { InitialisationModel } from '../../pcvBus/models/initialisationModel';
import PCVBus from '../../pcvBus/pcvBus.vue';

@Component({
  components: {
    "pcv-bus-component": PCVBus
  }
})
export default class BusAssignment extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    get getBusInit(): InitialisationModel {
        return new InitialisationModel({
            structure: this.propData.busData.busStructure,
            listPax: this.getListPax,
            language: this.propData.language.languageBus,
            listPickup: this.propData.listPickup
        })
    }

    get getListPax(): PaxModel[] {
        return <PaxModel[]>this.propData.listBusPaxSelected.concat(this.propData.busData.listPax);
    }

    updateBusPax(busPax: PaxModel[]) {
        this.propData.listBusPaxSelected = busPax.filter((pax) => !pax.locked);
    }

    beforeMount() {
        this.SendFunctionNext();

        if (this.propData.listBusPaxSelected.length == 0) {
            this.propData.listRoom[0].listPax.forEach((pax: PaxModel) => {
                this.propData.listBusPaxSelected.push(new PaxModel(pax));
            })
        }

    }

    logicNext() {
        this.propData.confirmationType = EnumConfirmationType.Bus;
        this.propData.previousPage.push(this.propData.currentPage);
        this.propData.listOptionSelected = [];
        if (this.propData.hasOption)
        {
            fetchOptionProduct(this.propData);
            this.propData.currentPage = EnumPagination.ProductOption;
        }
        else
        {
            this.propData.currentPage = EnumPagination.Confirmation;
        }
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

}


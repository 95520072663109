

















import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "./models/pcvBusModel";
import StructureMaker from "./components/structureMaker/structureMaker.vue";
import BusMain from "./components/busMain.vue";
import ListBusPax from "./components/listBusPax.vue";
import { InitialisationModel } from './models/initialisationModel';


@Component({
    components: {
        "structure-maker": StructureMaker,
        "bus-main": BusMain,
        "list-bus-pax": ListBusPax
    }
})
export default class PCVBus extends Vue {
    
    data: PCVBusModel = new PCVBusModel(null);

    @Prop({ type: Object }) readonly propInit!: InitialisationModel;

    isClear: boolean = false;

    beforeMount() {
      this.data = new PCVBusModel( new InitialisationModel(this.propInit));
    }

    beforeDestroy() {
        this.EmitPax();
    }

    @Watch("propInit")
    onWatchPropInit(newValue: InitialisationModel) {
        this.data = new PCVBusModel(new InitialisationModel(newValue));
    }

    get getIsConception(): boolean {
        if (this.data.listPax.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    @Emit("on-get-pax")
    EmitPax() {
        return this.getListPax();
    }

    getListPax() {
        return this.data.listPax;
    }

    getStructure() {
        return this.data.structureSeat.slice(0, parseInt(this.data.nbRowSelected));
    }

    clear() {
        this.isClear = true;
    }

    get getClassMenuTop(): string {
        if (this.$slots.default || this.$scopedSlots.default) {
            return "Right";
        } else {
            return "Full";
        }
    }

}


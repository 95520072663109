






































































































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import PCVImageGallery from "../pcvImageGallery/pcvImageGallery.vue";
import PCVImageGalleryWithCategoryAPI from "./utilities/api";

@Component({
    components: {
        "pcv-image-gallery": PCVImageGallery
    }
})
export default class PCVImageGalleryWithCategory extends Vue
{

    //#region Props
    @Prop({ type: String, default: "" }) private readonly imageBaseUrl!: string;
    @Prop({ type: Array, default: () => [] }) readonly listImage!: any[];
    @Prop({ type: String, default: "" }) readonly baseUrl!: string;
    @Prop({ type: Number, default: 0 }) readonly pageCode!: number;
    @Prop({ type: Number, default: 0 }) readonly canUpdateCategory!: number;
    @Prop({ type: Object, default: () => ({ title: "Groups", add: "Add", edit: "edit", delete: "delete" }) }) private readonly language!: any;

    @PropSync('listCategory', { type: Array }) syncedListCategory!: any[];
    @PropSync('categoryCodeSelected', { type: Number }) syncedCategoryCodeSelected!: number;
    //#endregion

    //#region Data
    m_IsClear: boolean = false;
    m_CategoryAddShow: boolean = false;
    m_CategoryAddValue: string = "";
    m_CategoryEditIndex: number = -1;
    m_CategoryEditValue: string = "";
    m_Api: PCVImageGalleryWithCategoryAPI = new PCVImageGalleryWithCategoryAPI(this.baseUrl);
    //#endregion

    //#region Getters
    //#endregion

    //#region Functions
    clear() 
    {
        this.m_IsClear = true;
        window.removeEventListener("click", this.onInputOutsideClick, true);
    }

    getListImageByCategory(p_Code: number): any[]
    {
        return this.listImage.filter((image) => image.category === p_Code);
    }
    //#endregion

    //#region Events

    onCategoryClick(p_Code: number)
    {
        this.syncedCategoryCodeSelected = p_Code;
    }

    onCategoryAddButtonClick()
    {
        this.m_CategoryAddShow = true;
        //@ts-ignore
        this.$nextTick(() => this.$refs.categoryInput.focus());
    }

    onInputOutsideClick(e: Event)
    {
        if (e.target)
        {
            let input = this.$refs.categoryInput as Element | Element[];

            if (input)
            {
                if (Array.isArray(input))
                {
                    if (input.length > 0)
                    {
                        if (!input[0].contains(<Node>e.target))
                        {
                            this.onCategoryEditInputSubmit();
                        }
                    }
                }
                else
                {      
                    if (!input.contains(<Node>e.target))
                    {
                        this.onCategoryAddInputSubmit();
                    }
                }
            }
            
        } 
    }

    async onCategoryAddInputSubmit()
    {
        let value = this.m_CategoryAddValue.trim();
        if (value.length > 0)
        {
            try 
            {
                let response = await this.m_Api.addCategoryAsync(value);
                if (response.success)
                {
                    let tmpListCategory = [...this.syncedListCategory];
                    tmpListCategory.push({ code: response.code, value: response.name});
                    this.syncedListCategory = tmpListCategory;

                    this.syncedCategoryCodeSelected = response.code;
                }
                else
                {
                    this.$emit("onError", { message: response.errorMessage, click: response.errorMessageClick });
                }
            } 
            catch (error) 
            {
            }
        }

        this.onCategoryAddInputClear();
    }

    onCategoryAddInputClear()
    {
        this.m_CategoryAddShow = false;
        this.m_CategoryAddValue = "";
    }

    async onCategoryRemoveButtonClick(p_Index: number)
    {
        if ((p_Index > -1) && (p_Index < this.syncedListCategory.length))
        {
            let response = await this.m_Api.deleteCategoryAsync(this.syncedListCategory[p_Index].code);
            if (response.success)
            {
                let tmpListCategory = [...this.syncedListCategory];
                tmpListCategory.splice(p_Index, 1);
                this.syncedListCategory = tmpListCategory;

                if (p_Index < tmpListCategory.length)
                {
                     this.syncedCategoryCodeSelected = tmpListCategory[p_Index].code;
                }
                else if (tmpListCategory.length > 0)
                {
                     this.syncedCategoryCodeSelected = tmpListCategory[tmpListCategory.length - 1].code;
                }
                else
                {
                     this.syncedCategoryCodeSelected = 0;
                }
            }
            else
            {
                this.$emit("onError", { message: response.errorMessage, click: response.errorMessageClick });
            }
        }
    }

    onCategoryEditButtonClick(p_Index: number)
    {
        this.m_CategoryEditValue = this.syncedListCategory[p_Index].value;
        this.m_CategoryEditIndex = p_Index;

        this.$nextTick(() => {
            let inputs = this.$refs.categoryInput as Element[];
            if (inputs && inputs.length > 0)
            {
                //@ts-ignore
                inputs[0].focus();
            }
        });
    }

    async onCategoryEditInputSubmit()
    {
        let index = this.m_CategoryEditIndex;
        let value = this.m_CategoryEditValue.trim();
        if ((value.length > 0) && (value !== this.syncedListCategory[index].value))
        {
            try 
            {
                let response = await this.m_Api.editCategoryAsync(this.syncedListCategory[index].code, value);
                if (response.success)
                {
                    this.syncedListCategory[index].value = response.name;
                }
                else
                {
                    this.$emit("onError", { message: response.errorMessage, click: response.errorMessageClick });
                }
            } 
            catch (error) 
            {
            }
        }

        this.onCategoryEditInputClear();
    }

    onCategoryEditInputClear()
    {
        this.m_CategoryEditIndex = -1;
        this.m_CategoryEditValue = "";
    }

    getListImageSelected()
    {

        //@ts-ignore
        return this.$refs.pcvImageGallery[0].getListImageSelected;
    }

    //#endregion

    //#region Hooks

    mounted()
    {
        window.addEventListener("click", this.onInputOutsideClick, true);
        this.m_Api.setBaseUrl(this.baseUrl);
    }

    beforeDestroy()
    {
        window.removeEventListener("click", this.onInputOutsideClick, true);
    }

    //#endregion
    
    //#region Watch

    @Watch("baseUrl", { immediate: true })
    onBaseUrlWatch()
    {
        this.m_Api.setBaseUrl(this.baseUrl);
    }

    @Watch("pageCode", { immediate: true })
    onPageCodeWatch()
    {
        this.m_Api.pageCode = this.pageCode;
    }

     //#endregion
}










































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "../models/pcvBusModel";
import { PaxModel } from '../../models/paxModel';
import { OptionSeatPaxModel } from '../models/optionSeatPaxModel';

@Component
export default class ListBusPax extends Vue {
    
    @Prop({ type: Object }) readonly propData!: PCVBusModel;

    get getStructurePax() {
        return this.propData.getStructurePax;
    }

    get getListPaxNotLocked(): PaxModel[] {
        return this.propData.listPax.filter((pax: PaxModel) => !pax.locked);
    }

    beforeMount() {
        if (this.getListPaxNotLocked.length > 0) {
            let paxNotAssignedFound = this.getListPaxNotLocked.find((pax: PaxModel) => pax.col === 0 && pax.row === 0);
            if (paxNotAssignedFound) {
                this.propData.itemClicked = paxNotAssignedFound;
            }  
        }
    }

    onClickRow(pax: PaxModel) {
        if (!pax.locked) {
            this.propData.itemClicked = pax;
        }
    }

    isDraggable(pax: PaxModel) {
        return !pax.locked;
    }

    onDragStart(event: DragEvent, pax: PaxModel) {

        if (event.dataTransfer) {
            this.propData.itemDrag = pax;

            this.propData.originSeatDrag = [pax.row, pax.col];
        }

    }

    getSeatName(pax: PaxModel): string {
        if (pax.row > 0 && pax.col > 0) {
            return this.propData.structureSeat[pax.row - 1][pax.col - 1]
        } else {
            return "";
        }
    }

    getClassPax(pax: PaxModel){

      if (this.propData.paxMouseOver === pax) {
        return "pcv-bus-pax-over";
      } else if (this.propData.itemClicked === pax) {
        return "pcv-bus-pax-selected";
      }
    
      return "";
    }

    onMouseOver(pax: PaxModel) {
        this.propData.paxMouseOver = pax;
    }

    onMouseLeave(){
        this.propData.paxMouseOver = null;
    }

}


import "es6-promise/auto";
import Axios, { AxiosInstance } from 'axios';
import moment from "moment";

export default class PCVImageGalleryWithCategoryAPI {

  private m_axios: AxiosInstance;
  public pageCode: number = 0;

  constructor(baseURL: string) {
    this.m_axios = Axios.create({
      baseURL: baseURL
    })
  }

  setBaseUrl(p_Value: string)
  {
    this.m_axios.defaults.baseURL = p_Value;
  }

  async addCategoryAsync(p_Name: string): Promise<any>
  {
    let promise = await this.m_axios.post("CreateGroup.ashx", { name: p_Name }, { params: { pageCode: this.pageCode } });
    return promise.data;
  }

  async editCategoryAsync(p_Code: number, p_Name: string): Promise<any>
  {
    let promise = await this.m_axios.post("UpdateGroup.ashx", { code: p_Code, name: p_Name }, { params: { pageCode: this.pageCode } });
    return promise.data;
  }

  async deleteCategoryAsync(p_Code: number): Promise<any>
  {
    let promise = await this.m_axios.post("DeleteGroup.ashx", { code: p_Code }, { params: { pageCode: this.pageCode } });
    return promise.data;
  }
}
































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "../../models/pcvBusModel";

@Component
export default class IconSetting extends Vue {
    
    @Prop({ type: Object }) readonly propData!: PCVBusModel;

    onDragStart(event: DragEvent, iconValue: string) {
        if (event.dataTransfer) {
            this.propData.itemDrag = iconValue;
            this.propData.originSeatDrag = [0,0];
        }
    }

    onClickIcon(iconValue: string){
        if (this.propData.itemClicked === iconValue) {
            this.propData.itemClicked = null;
        } else {
            this.propData.itemClicked = iconValue;
        }
    }

    getClassIconSelected(iconValue: string) {
        if (this.propData.itemClicked === iconValue) {
            return "pcv-bus-icon-selected";
        } else {
            return "";
        }
    }

}


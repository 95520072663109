import { RoomModel } from "../../models/roomModel";
import { initObject } from "../../utilities/utils";

export class ShareableRoomModel extends RoomModel {

    sel: boolean = true;

    name: string = "";
    availability: number = 0;
    gender: string = "";
    language: string = "";
    age: number = 0;

    constructor(value: any) {
        super(value);
        initObject(this, value);
    }

}
import { initObject } from "../../utilities/utils";
import { ProductModel } from "./productModel";
import { PaxModel } from "../../models/paxModel";
import { LanguageToursPaxAssignModel } from "./languageToursPaxAssignModel";

export class InitialisationToursPaxAssignModel {

    baseURL: string = "";
    pageCode: number = 0;
    listProduct: ProductModel[] = [];
    listPax: PaxModel[] = [];
    file: string = "";
    language: LanguageToursPaxAssignModel = new LanguageToursPaxAssignModel(null);
    dateFormat: string = "YYYY-MM-DD";

    constructor(value: any){
        initObject(this, value);
    }

}
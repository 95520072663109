






















































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import moment from 'moment';
import EditableInput from "@/components/common/EditableInput.vue";
import { InitEditCalendar } from "./models";

@Component({
    components: {
        "editable-input": EditableInput
    }
})
export default class PCVEditCalendar extends Vue {
    
    @Prop({ type: Object }) readonly propInit!: InitEditCalendar;
    IsClear: boolean = false;
    dateEdited: string = "";
    calendarMoment: moment.Moment = moment();
    valueInv: { [ date: string ]: number } = {};
    languageDaysOfWeekAbbr: string[] = [];
    languageMonth: string[] = [];
    isReadonly: boolean = false;
    
    @Watch("propInit", { deep: true, immediate: true})
    onWatchPropInit(value: any) {
        let tmpPropInit =  new InitEditCalendar(value);
        this.valueInv = tmpPropInit.inventory;
        this.languageDaysOfWeekAbbr = tmpPropInit.languageDate.daysOfWeekAbbr;
        this.languageMonth = tmpPropInit.languageDate.month;
        this.isReadonly = tmpPropInit.readonly;
        this.calendarMoment = moment(tmpPropInit.currentDate);
    }

    clear() {
        this.IsClear = true;
    }

    get monthSelected(): number {
        return this.calendarMoment.month() + 1;
    }

    set monthSelected(value: number) {
        this.calendarMoment = moment(this.calendarMoment.month(value - 1));
    }

    get yearSelected(): number {
        return this.calendarMoment.year();
    }

    set yearSelected(value: number) {
        this.calendarMoment = moment(this.calendarMoment.year(value));
    }

    get getCalendar() {
        const startWeek = this.calendarMoment.startOf('month').week();
        const endWeek = startWeek + 5;

        let calendar = []
        for (var week = startWeek; week <= endWeek; week++) {
            calendar.push({
                week: week,
                days: Array(7).fill(0).map((n, i) => moment(this.calendarMoment).week(week).startOf('week').add(n + i, 'day'))
            })
        }
        return calendar;
    }

    getClassDay(day: moment.Moment) {
        if (day.format('MM') !== this.calendarMoment.format('MM')) {
            return 'pcv-edit-calendar-not-in-month';
        } else {
            return "";
        }
    }

    onHideEdit() {
        this.dateEdited = "";
    }

    onClickDate(day: moment.Moment) {
        if (!this.isReadonly) {
            this.dateEdited = day.format('YYYY-MM-DD');
        }
    }

    onPressTab() {

        let nextDateEdited = moment(this.dateEdited, "YYYY-MM-DD").add(1, "days");

        if (nextDateEdited.month() !== this.calendarMoment.month()){
            this.calendarMoment = moment(this.calendarMoment.add(1, "months"));
        }

        this.dateEdited = moment(this.dateEdited, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }

    onPressShiftTab() {

        let nextDateEdited = moment(this.dateEdited, "YYYY-MM-DD").subtract(1, "days");

        if (nextDateEdited.month() !== this.calendarMoment.month()){
            this.calendarMoment = moment(this.calendarMoment.subtract(1, "months"));
        }

        this.dateEdited = moment(this.dateEdited, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD");
    }

    setInventory(value: string, day: moment.Moment) {

        let valueNumber = parseFloat(value);

        if ((!isNaN(valueNumber)) && Number.isInteger(valueNumber) && valueNumber > 0) {

            if (valueNumber > 999) {
                this.valueInv[day.format('YYYY-MM-DD')] = 999;
            } else {
                this.valueInv[day.format('YYYY-MM-DD')] = valueNumber;
            }

        } else {
            this.$delete(this.valueInv, day.format('YYYY-MM-DD'));
        }

        this.emitInventory(day.format('YYYY-MM-DD'));

    }

    getValue(day: moment.Moment) {
        let valueNumber = this.valueInv[day.format('YYYY-MM-DD')];
        if (valueNumber == 0) {
            return '';
        } else {
            return valueNumber;
        }
    }

    onClickPreviousMonth() {
        this.calendarMoment = moment(this.calendarMoment.subtract(1, "month"));
    }

    onClickNextMonth() {
        this.calendarMoment = moment(this.calendarMoment.add(1, "month"));
    }

    @Emit("inv-updated")
    emitInventory(date: string) {
        return date;
    }

    @Emit("current-date-updated")
    emitCurrentDateUpdated(date: string) {
        return date;
    }

    @Watch("calendarMoment")
    onWatchCalendarMoment(value: moment.Moment) {
        this.emitCurrentDateUpdated(value.format("YYYY-MM-DD"))
    }

    onDblClickCalendar() {
        this.$emit("on-dbl-click-calendar");
    }

}



































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import PCVEditCalendar from "@/components/pcvEditCalendar/pcvEditCalendar.vue";
import PCVDatepicker from "@/components/common/PCVDatepicker.vue";
import { EnumDayOfWeek } from "./enum";
import moment from 'moment';
import { InitInventory, InventoryModel } from "./models";
import { InitEditCalendar } from '../pcvEditCalendar/models';

@Component({
    components: {
        "edit-calendar": PCVEditCalendar,
        "date-picker": PCVDatepicker
    }
})
export default class PCVInventory extends Vue {

    @Prop({ type: Object, default: () => new InitInventory() }) readonly propInit!: InitInventory;
    dateFrom: string = "";
    dateTo: string = "";
    generateType: number = 0;
    inventory: { [ date: string ]: number } = { };
    dayOfWeekSelected: EnumDayOfWeek[] = [];
    quantity: number = 1;
    checkAllDayOfWeek: boolean = false;
    invReturned: { [ date: string ]: InventoryModel } = {};
    isClear: boolean = false;
    dateToCalendar: string = "";
    currentDate: string = "";

    get getInit(): InitInventory {
        return new InitInventory(this.propInit);
    }

    @Watch("getInit", {deep: true, immediate: true})
    onWatchGetInit(value: InitInventory) {
        this.inventory = JSON.parse(JSON.stringify(value.inventory));
        this.dateFrom = value.dateFrom;
        this.dateTo = value.dateTo;
        this.currentDate = value.currentDate;

        this.dayOfWeekSelected = [];
        value.defaultDays.forEach( (daySelected, index) => {
            if (daySelected) {
                this.dayOfWeekSelected.push(index);
            }
        });

        if (this.dayOfWeekSelected.length === 7) {
            this.checkAllDayOfWeek = true;
        } 
    }

    get getEnumDayOfWeek() : typeof EnumDayOfWeek {
        this.getInit.languageInventory.product
        return EnumDayOfWeek;
    }

    get initCalendar(): InitEditCalendar {
        let tmp = new InitEditCalendar();
        tmp.inventory = this.inventory;
        tmp.currentDate = this.currentDate;
        tmp.languageDate = this.getInit.languageInventory.languageDate;
        return tmp;
    }

    @Watch("dayOfWeekSelected")
    onWatchDayOfWeekSelected(value: EnumDayOfWeek[]) {
        this.checkAllDayOfWeek = (value.length === 7);
    }

    getInventory() {
        return this.invReturned;
    }

    clear() {
        this.isClear = true;
    }

    onUpdateQuantity(value: string) {

        let tmpValue: number = parseInt(value);

        if (tmpValue > 999) {
            this.quantity = 999;
        } else if (tmpValue != this.quantity) {
            this.quantity = tmpValue;
        }

    }

    onCheckAll() {
        if (this.checkAllDayOfWeek) {
            this.dayOfWeekSelected = [];
        } else {
            this.dayOfWeekSelected = [0,1,2,3,4,5,6];
        }
    }

    OnClickAddRelease() {
        if (this.quantity > 0 &&
            typeof this.quantity == 'number' &&
            Number​.isInteger(this.quantity) &&
            this.dateFrom.length > 0 &&
            this.dateTo.length > 0
        ){
            let action: Function[] = [this.addInventory, this.releaseInventory];

            var currDate = moment(this.dateFrom).startOf('day');
            var lastDate = moment(this.dateTo).startOf('day');
            this.currentDate = this.dateFrom;

            if (this.dayOfWeekSelected.includes(currDate.day())) {
                this.initDateInventory(currDate.format("YYYY-MM-DD"));
                action[this.generateType](currDate.format("YYYY-MM-DD"), this.quantity);
                this.editInvReturned(currDate.format("YYYY-MM-DD"))
            }

            while(currDate.add(1, 'days').diff(lastDate) < 0) {
                let cloneDate = currDate.clone();
                if (this.dayOfWeekSelected.includes(cloneDate.day())) {
                    this.initDateInventory(cloneDate.format("YYYY-MM-DD"));
                    action[this.generateType](cloneDate.format("YYYY-MM-DD"), this.quantity);
                    this.editInvReturned(cloneDate.format("YYYY-MM-DD"))
                }
            }

            if (this.dateFrom != this.dateTo && this.dayOfWeekSelected.includes(lastDate.day())) {
                this.initDateInventory(lastDate.format("YYYY-MM-DD"));
                action[this.generateType](lastDate.format("YYYY-MM-DD"), this.quantity);
                this.editInvReturned(lastDate.format("YYYY-MM-DD"))
            }

        }

    }

    addInventory(date : string, value: number) {
        this.inventory[date] += value;
    }

    releaseInventory(date : string, value: number) {
        this.inventory[date] -= value;
        if (this.inventory[date] <= 0) {
            this.$delete(this.inventory, date);
        }
    }

    editInvReturned(date: string) {

        if (typeof this.inventory[date] == 'undefined') {
            if (typeof this.getInit.inventory[date] == 'undefined') {
                this.$delete(this.invReturned, date);
            } else {
                this.invReturned[date] = new InventoryModel(0, true);
            }
        } else {
            if (typeof this.getInit.inventory[date] == 'undefined') {
                this.invReturned[date] = new InventoryModel(this.inventory[date], false);
            } else {
                if (this.inventory[date] != this.getInit.inventory[date]) {
                    let isReduced: boolean = false;
                    if (this.inventory[date] < this.getInit.inventory[date]) {
                        isReduced = true;
                    }
                    this.invReturned[date] = new InventoryModel(this.inventory[date], isReduced);
                } else {
                    this.$delete(this.invReturned, date);
                }
            }
        }
    }

    initDateInventory(date: string) {
        if (typeof this.inventory[date] == 'undefined') {
            this.$set(this.inventory, date, 0);
        }
    }

    @Watch("dateFrom")
    onWatchDateFrom(newValue: string){

        if (this.dateTo !== "") {
            var dateFrom = moment(newValue);
            var dateTo = moment(this.dateTo);

            if (dateFrom.isAfter(dateTo)) {
                this.dateTo = newValue;
            }
        }
    }

    @Watch("dateTo")
    onWatchDateTo(newValue: string){

        if (this.dateFrom !== "") {
            var dateFrom = moment(this.dateFrom);
            var dateTo = moment(newValue);
            if (dateFrom.isAfter(dateTo)) {
                this.$nextTick(() => {
                    this.dateTo = this.dateFrom;
                })
            }
        }

    }

    @Watch("currentDate")
    onWatchCalendarMoment(value: string) {
        this.emitCurrentDateUpdated(value)
    }

    @Emit("current-date-updated")
    emitCurrentDateUpdated(date: string) {
        return date;
    }

    currentDateCalendarUpdated(date: string)
    {
        this.currentDate = date;
    }

}


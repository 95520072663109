


















import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "../../models/pcvBusModel";
import StructureSetting from './structureSetting.vue';
import IconSetting from './iconSetting.vue';

@Component({
    components: {
        "structure-setting": StructureSetting,
        "icon-setting": IconSetting
    }
})
export default class StructureMaker extends Vue {
    
    @Prop({ type: Object }) readonly propData!: PCVBusModel;

    onClickGenerateStructure() {

        let tmpListSeat: string[][] = [];
        let seat: number = 1;
        let tmpListSeatRow: string[]
        let seatsToKeep: string[] = ["T", "X", "G"];
        let isLeftToRight: boolean = this.propData.seatNumberingDirection === "leftToRight";

        for (let row = 0; row < 20; row++) {
            tmpListSeatRow = [];

            if (isLeftToRight) {
                for (let col = 3; col >= 0; col--) {
                    if (seatsToKeep.includes(this.propData.structureSeat[row][col])) {
                        tmpListSeatRow[col] = this.propData.structureSeat[row][col];
                    } else {
                        tmpListSeatRow[col] = seat.toString();
                        seat++;
                    }
                }
            } else {
                for (let col = 0; col < 4; col++) {
                    if (seatsToKeep.includes(this.propData.structureSeat[row][col])) {
                        tmpListSeatRow[col] = this.propData.structureSeat[row][col];
                    } else {
                        tmpListSeatRow[col] = seat.toString();
                        seat++;
                    }
                }
            }

            tmpListSeat.push(tmpListSeatRow);
        }

        /*
        for (let row = 0; row < 20; row++) {
            let tmpListSeatRow: string[] = [];

            for (let col = 0; col < 4; col++) {

                switch (this.propData.seatNumberingType) {
                    case "00":
                        tmpListSeatRow.push(((row * 4 + col) + 1).toString());
                        break;
                    case "0A":
                        tmpListSeatRow.push((row + 1).toString() + (col + 10).toString(36).toUpperCase());
                        break;
                    case "A0":
                        tmpListSeatRow.push((row + 10).toString(36).toUpperCase() + (col + 1).toString());
                        break;
                    case "AA":
                        tmpListSeatRow.push((row + 10).toString(36).toUpperCase() + (col + 10).toString(36).toUpperCase());
                        break;
                }
            }

            switch (this.propData.seatNumberingOrder) {
                case "window":
                    var tmpSwitch = tmpListSeatRow[2];
                    tmpListSeatRow[2] = tmpListSeatRow[3];
                    tmpListSeatRow[3] = tmpSwitch;
                    break;
                case "center":
                    var tmpSwitch = tmpListSeatRow[0];
                    tmpListSeatRow[0] = tmpListSeatRow[1];
                    tmpListSeatRow[1] = tmpSwitch;
                    break;
            }

            if (this.propData.seatNumberingDirection === "leftToRight") {
                tmpListSeatRow.reverse();
            }

            for (let col = 0; col < 4; col++) {
                if (["T", "X", "G"].includes(this.propData.structureSeat[row][col])) {
                    tmpListSeatRow[col] = this.propData.structureSeat[row][col];
                }
            }

            tmpListSeat.push(tmpListSeatRow);
        }
        */

       this.propData.structureSeat = tmpListSeat;

    }

}


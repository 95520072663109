import Vue from "vue";
import { OptionProductModel } from "../pcvToursInvcprod/models/optionProduct";
import { PCVToursInvcprodModel } from "../pcvToursInvcprod/models/pcvToursInvcprodModel";
import { EnumConfirmationType } from "../pcvToursInvcprod/utilities/enum";

export function initObject(obj: any, value: any = null) {
    if (value) {
        for (let key in obj) {
            if (value.hasOwnProperty(key)) {
                obj[key] = value[key];
            }
        }
    }
}

export type typeFunctionNext = () => void;


export function numberFormat(p_Number: number, p_Precision: number): string
{
    let spl: string[] = p_Number.toFixed(p_Precision).split(".");
    spl[0] = spl[0].toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") ?? "0";
    if (spl.length > 1)
    {
        return spl[0] + "." + spl[1];
    }
    else
    {
        return spl[0];
    }
}

export function fetchOptionProduct(p_PropData: PCVToursInvcprodModel)
{
    let nbRoom: number = 0;
    let nbPax: number = 0;

    if ((p_PropData.confirmationType === EnumConfirmationType.NewSharedRoom) ||
        (p_PropData.confirmationType === EnumConfirmationType.ExistingSharedRoom) ||
        (p_PropData.confirmationType === EnumConfirmationType.NewRooms))
    {
        nbRoom = p_PropData.listRoom.length;
        nbPax = p_PropData.listRoom.reduce((accumulator, room) => accumulator + room.listPax.length, 0);
    }
    else if (p_PropData.confirmationType === EnumConfirmationType.Basic)
    {
        nbRoom = p_PropData.listRoom[0].listPax.length;
        nbPax = nbRoom;
    }
    else if (p_PropData.confirmationType === EnumConfirmationType.Bus)
    {
        nbRoom = p_PropData.listBusPaxSelected.length;
        nbPax = nbRoom;
    }
    else if (p_PropData.confirmationType === EnumConfirmationType.Children)
    {
        nbRoom = 1;
        nbPax = p_PropData.listChildren.length;
    }

    let promiseResult = p_PropData.api.fetchOptionProduct(p_PropData.pageCode, p_PropData.categorySelected.code, p_PropData.dateFrom, p_PropData.dateTo, p_PropData.dateReservation, nbRoom, nbPax);
    promiseResult.then((listOption: OptionProductModel[]) => {
        Vue.set(p_PropData, "listOption", listOption);
    }).catch((error) => {
        console.log(error);
    });
}
import { initObject } from "../../utilities/utils";
import { LanguageBusModel } from "../../pcvBus/models/languageBusModel";

export class LanguageToursPaxAssignModel {

    previous: string = "Précédent";
    next: string = "Suivant";
    complete: string = "Compléter";
    cancel: string = "Annuler";

    product: string = "Produit";
    class: string = "Classe";
    dateFrom: string = "Date du";
    dateTo: string = "Date au";

    listPax: string = "Liste des passagers";
    inventory: string = "Inventaire";
    available: string = "Disponible";
    newRoom: string = "Chambre";
    paxNotAssigned: string = "Passager non assignés";

    errorCloseButton: string = "Fermer";
    errorAttemptToChangeInventory: string = "Tentative de modification d'inventaire";
    errorNoChange: string = "Aucun changement effectué";
    errorRoomEmpty: string = "Aucune chambre ne doit être vide";
    errorLimitOccupancy: string = "Vous avez dépassé la capacité limite de la chambre";

    languageBus: LanguageBusModel = new LanguageBusModel(null);

    constructor(value: any){
        initObject(this, value);
    }
    
}
import { LanguageAvailabilityModel } from "./languageAvailabilityModel";

export class InitialisationAvailabilityModel {

    baseURL: string = "";
    pageCode: number = 0;
    dateFrom: string = "";
    dateTo: string = "";
    dateFormat: string = "";
    language: LanguageAvailabilityModel = new LanguageAvailabilityModel();

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }

}
import { initObject } from "../../utilities/utils";

export class LanguageBusModel {
    rightToLeft: string = "rightToLeft";
    leftToRight: string = "leftToRight";
    seats: string = "sièges";
    paxName: string = "NOM";
    paxFile: string = "DOSSIER";
    paxSeat: string = "SIÈGE";
    paxPickupPoint: string = "EMBARQUEMENT";
    generate: string = "Générer";
    labelNumberRow: string = "NB RANGÉES";
    labelSeatNumberingDirection: string = "NUMÉROTATION - ORIENTATION";
  
    constructor(value: any) {
        initObject(this, value);
    }
  
  }
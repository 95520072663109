import { PCVAvailabilityApi } from "../utilities/api";
import { InitialisationAvailabilityModel } from "./initialisationAvailabilityModel";
import { LanguageAvailabilityModel } from "./languageAvailabilityModel";

export class PCVAvailabilityModel {

    readonly api: PCVAvailabilityApi = new PCVAvailabilityApi("", 0);
    imgRefresh1: string = "../_Images/Reset4.png";
    imgRefresh2: string = "../_Images/Reset5.png";
    language: LanguageAvailabilityModel = new LanguageAvailabilityModel();

    constructor(init: InitialisationAvailabilityModel) {
        this.api = new PCVAvailabilityApi(init.baseURL, init.pageCode);
        if (init.language){
            this.language = init.language;
            if (!init.language.languageDate) {
                this.language = new LanguageAvailabilityModel();
            }
        }
    }

}
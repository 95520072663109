












import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import InitialisationToursRoomSharingModel from "./models/initialisationToursRoomSharingModel";
import PCVToursRoomSharingModel from "./models/pcvToursRoomSharingModel";
import RoomModel from "./models/roomModel";
import RoomSharingRoom from "./components/roomSharingRoom.vue";

@Component({
    components: {
        "roomsharing-room": RoomSharingRoom
    }
})
export default class PCVToursRoomSharing extends Vue {

    @Prop({ type: Object, default: () => new InitialisationToursRoomSharingModel(null) }) readonly propInit!: InitialisationToursRoomSharingModel;

    data: PCVToursRoomSharingModel = new PCVToursRoomSharingModel(new InitialisationToursRoomSharingModel(null));

    isClear: boolean = false;

    get getListRoom(): RoomModel[]
    {
        return this.data.listRoom;
    }

    @Watch("propInit", { immediate: true, deep: true })
    onWatchPropInit(newInit: InitialisationToursRoomSharingModel) {
        this.data = new PCVToursRoomSharingModel(new InitialisationToursRoomSharingModel(newInit));
    }

    clear() {
        this.isClear = true;
    }
}


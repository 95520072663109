




























































































































import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import moment from 'moment';
import { numberFormat, typeFunctionNext } from '../../utilities/utils';
import { PaxModel } from '../../models/paxModel';
import { EnumConfirmationType } from '../utilities/enum';
import { RoomModel } from '../../models/roomModel';
import { PaxByPickupPoint } from "../models/paxByPickupPoint";
import { OptionProductModel } from "../models/optionProduct";

@Component
export default class Confirmation extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    get getIsConfirmationNewRooms() {
      return this.propData.confirmationType === EnumConfirmationType.NewRooms;
    }

    get getIsConfirmationNewSharedRoom() {
      return this.propData.confirmationType === EnumConfirmationType.NewSharedRoom;
    }

    get getIsConfirmationExistingSharedRoom() {
      return this.propData.confirmationType === EnumConfirmationType.ExistingSharedRoom;
    }

    get getIsConfirmationBasic() {
      return this.propData.confirmationType === EnumConfirmationType.Basic;
    }

    get getIsConfirmationBus() {
      return this.propData.confirmationType === EnumConfirmationType.Bus;
    }

    get getIsConfirmationChildren() {
      return this.propData.confirmationType === EnumConfirmationType.Children;
    }

    get getDateFromFormatted() {
        return moment(this.propData.dateFrom).format(this.propData.dateFormat);
    }

    get getDateToFormatted() {
        return moment(this.propData.dateTo).format(this.propData.dateFormat);
    }

    get getBusPaxByPickupPoint(): PaxByPickupPoint[] {
      if (this.propData.listPickup.length > 0) {
        let listPaxByPickupPoint: PaxByPickupPoint[] = [];
        this.propData.listPickup.forEach((pickupPoint) => {
          let paxFound = this.propData.listBusPaxSelected.filter((pax) => pax.busPickup === pickupPoint.code);
          if (paxFound.length > 0) {
            let tmpPaxByPickupPoint = new PaxByPickupPoint(pickupPoint);
            tmpPaxByPickupPoint.listPax = paxFound;
            listPaxByPickupPoint.push(tmpPaxByPickupPoint);
          }
        })
        return listPaxByPickupPoint;
      } else {
        return [];
      }
    }

    get g_ListOptionSelected(): OptionProductModel[]
    {
      return this.propData.listOption.filter((option) => this.propData.listOptionSelected.includes(option.code));
    }

    getOptionInformation(p_Option: OptionProductModel): string
    {
      if ((p_Option.pricingClassName.length > 0) && (p_Option.pricingCategoryName.length > 0))
      {
        return `${ p_Option.quantity } x ${ p_Option.name } ( ${ p_Option.pricingClassName } - ${ p_Option.pricingCategoryName } )`;
      }
      else if (p_Option.pricingClassName.length > 0)
      {
        return `${ p_Option.quantity } x ${ p_Option.name } ( ${ p_Option.pricingClassName } )`;
      }
      else if (p_Option.pricingCategoryName.length > 0)
      {
        return `${ p_Option.quantity } x ${ p_Option.name } ( ${ p_Option.pricingCategoryName } )`;
      }
      else
      {
        return `${ p_Option.quantity } x ${ p_Option.name }`;
      }
    }

    beforeMount() {
        this.sendFunctionNext();
        this.emitShowCompleteButton(true);
    }

    beforeDestroy() {
        this.emitShowCompleteButton(false);
    }

    getSeat(pax: PaxModel): string {

        if (pax.col === 0 && pax.row === 0) {
            return this.propData.language.busPaxNotAssigned;
        } else {
            return this.propData.busData.busStructure[pax.row - 1][pax.col - 1]
        }
    }

    postComplete(isSharedRoom: boolean, listRoom: RoomModel[]) {
        let promiseResult = this.propData.api.postComplete(
            this.propData.pageCode,
            this.propData.categorySelected.code,
            this.propData.dateFrom,
            this.propData.dateTo,
            isSharedRoom,
            listRoom,
            this.propData.listOptionSelected,
        );

        promiseResult.then((result: any) => {
            if (result.data.success) {
                this.$emit("on-complete");
            } else {
                this.$emit("on-error", result.data.errorMessage);
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    logicNext() {

      if (this.getIsConfirmationNewSharedRoom || this.getIsConfirmationExistingSharedRoom) {
        this.postComplete(true, this.propData.listRoom);
      } else if (this.getIsConfirmationBasic) {
        let listRoom: RoomModel[] = [];
        this.propData.listRoom[0].listPax.forEach((pax) => {
          let tmpRoom = new RoomModel(null);
          tmpRoom.listPax.push(pax);
          listRoom.push(tmpRoom);
        })
        this.postComplete(false, listRoom);
      } else if (this.getIsConfirmationBus) {
        let listRoom: RoomModel[] = [];
        this.propData.listBusPaxSelected.forEach((pax) => {
          let tmpRoom = new RoomModel(null);
          tmpRoom.listPax.push(pax);
          listRoom.push(tmpRoom);
        })
        this.postComplete(false, listRoom);
      } else if (this.getIsConfirmationNewRooms) {
        this.postComplete(false, this.propData.listRoom);
      } else if (this.getIsConfirmationChildren) {
        let listRoom: RoomModel[] = [];
        listRoom.push(new RoomModel(this.propData.roomChildrenSelected));
        listRoom[0].listPax = this.propData.listChildren;
        this.postComplete(false, listRoom);
      }

    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    sendFunctionNext(){
        return this.functionNext;
    }

    get getDuration(): string {
        if (this.propData.dateFrom !== "" && this.propData.dateTo !== "") {
            var dateFrom = moment(this.propData.dateFrom);
            var dateTo = moment(this.propData.dateTo);
            return (dateTo.diff(dateFrom, "days") + 1).toString() + " " + this.propData.language.days + " / " + dateTo.diff(dateFrom, "days").toString() + " " + this.propData.language.nights;
        } else {
            return "";
        }
    }

    @Emit("update-show-complete-button")
    emitShowCompleteButton(value: boolean){
        return value;
    }


}


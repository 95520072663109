







































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";

import moment from 'moment';
import PCVDatepicker from '@/components/common/PCVDatepicker.vue';
import { ProductPriceClassModel } from '../models/productPriceClassModel';
import { ProductPriceCategoryModel } from '../models/productPriceCategoryModel';
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { typeFunctionNext } from '../../utilities/utils';
import { EnumPagination, EnumSharingType } from '../utilities/enum';
import DateWithFixedDurationModel from "../models/dateWithFixedDurationModel";
import { OptionProductModel } from "../models/optionProduct";
import { EnumRoomingType } from "../../utilities/enum";
import { RoomModel } from "../../models/roomModel";
import { PaxModel } from "../../models/paxModel";

@Component({
  components: {
    "pcv-datepicker": PCVDatepicker
  }
})
export default class DateClassCategory extends Vue {

  @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

  dateSelected: DateWithFixedDurationModel = new DateWithFixedDurationModel();

  get dateSelectedProxy(): DateWithFixedDurationModel
  {
      return this.dateSelected;
  }

  set dateSelectedProxy(value: DateWithFixedDurationModel)
  {
      this.propData.dateFrom = value.dateFrom;
      this.propData.dateTo = value.dateTo;
      this.dateSelected = value;
  }

  @Watch("propData.dateFrom", { immediate: true, deep: true })
  onWatchDateFrom(newValue: string){

    if ( (!this.propData.isFixedDuration) && this.propData.dateTo !== "") {
      var dateFrom = moment(newValue);
      var dateTo = moment(this.propData.dateTo);

      if (dateFrom.isAfter(dateTo)){
        this.propData.dateTo = newValue;
      }

    }

    this.updateListClasses();
  }

  @Watch("propData.dateTo", { immediate: true, deep: true })
  onWatchDateTo(newValue: string){

    if ( (!this.propData.isFixedDuration) && this.propData.dateFrom !== ""){
      var dateFrom = moment(this.propData.dateFrom);
      var dateTo = moment(newValue);
      if (dateFrom.isAfter(dateTo)){
        this.$nextTick(() => {
          this.propData.dateTo = this.propData.dateFrom;
        })
      } else {
        this.updateListClasses();
      }
    }

  }

  @Watch("propData.classSelected")
  onWatchClassSelected(){
    this.categorySelectedReassignment();
  }

  @Watch("propData.listDate")
  onWatchListDate()
  {
      if (this.propData.isFixedDuration) {
          if (this.propData.listDate.length > 0 && this.propData.defaultIndex > -1) {
              this.dateSelected = this.propData.listDate[this.propData.defaultIndex];
          }
      }
  }

  get getListCategory(): ProductPriceCategoryModel[]
  {

    if (this.propData.listClasses.length > 0 && this.propData.classSelected.code !== 0)
    {
      let productPriceClass = this.propData.listClasses.find((classProd: ProductPriceClassModel) => classProd.code === this.propData.classSelected.code);
      return (productPriceClass ? productPriceClass.listCategories : []);
    } else {
      return [];
    }

  }

  get getDuration(): string {
    if (this.propData.isFixedDuration) {
      if (this.propData.listDate.length > 0) {
        var dateFrom = moment(this.propData.listDate[0].dateFrom);
        var dateTo = moment(this.propData.listDate[0].dateTo);
        return (dateTo.diff(dateFrom, "days") + 1).toString() + " " + this.propData.language.days + " / " + dateTo.diff(dateFrom, "days").toString() + " " + this.propData.language.nights;
      } else {
        return "";
      }
    } else if (this.propData.dateFrom !== "" && this.propData.dateTo !== "") {
      var dateFrom = moment(this.propData.dateFrom);
      var dateTo = moment(this.propData.dateTo);
      return (dateTo.diff(dateFrom, "days") + 1).toString() + " " + this.propData.language.days + " / " + dateTo.diff(dateFrom, "days").toString() + " " + this.propData.language.nights;
    } else {
      return "";
    }
  }

  formatDate(value: string): string
  {
      return moment(value).format(this.propData.dateFormat);
  }

  beforeMount() {
    this.SendFunctionNext();
    this.propData.roomSharingTypeSelected = EnumSharingType.NotShared;
    this.propData.roomChildrenSelected = null;
    this.propData.listChildren = [];

    if (this.propData.isFixedDuration) {
      if (this.propData.listDate.length > 0 && this.propData.defaultIndex > -1) {
          this.dateSelected = this.propData.listDate[this.propData.defaultIndex];
      }
    }
  }

  categorySelectedReassignment(){

    if (this.propData.classSelected.listCategories.length > 0) {

      let findSamePricingCategory = this.propData.classSelected.listCategories.find((category: ProductPriceCategoryModel) => {
        return category.pricingCategory === this.propData.categorySelected.pricingCategory;
      } );

      if (this.propData.categorySelected.code !== 0 && findSamePricingCategory) {
        this.propData.categorySelected = findSamePricingCategory;
      } else {
        let findCategoryDouble = this.propData.classSelected.listCategories.find((category: ProductPriceCategoryModel) => category.name === "Double");

        if (findCategoryDouble) {
          this.propData.categorySelected = findCategoryDouble;
        } else {
          this.propData.categorySelected = this.propData.classSelected.listCategories[0];
        }

      }
    }
  }

  classSelectedReassignment() {
    if (this.propData.listClasses.length > 0) {

      let findSamePricingClass = this.propData.listClasses.find((productPriceClass: ProductPriceClassModel) => {
        return productPriceClass.pricingClass === this.propData.classSelected.pricingClass;
      } );

      if (this.propData.classSelected.code !== 0 && findSamePricingClass) {
        this.propData.classSelected = findSamePricingClass;
      } else {
        this.propData.classSelected = this.propData.listClasses[0];
      }
    }
  }

  updateListClasses() {
    if (this.propData.dateTo !== "" && this.propData.dateFrom !== "") {
      let result = this.propData.api.fetchClassesAndCategories(
        this.propData.pageCode,
        this.propData.product,
        this.propData.dateReservation,
        this.propData.dateFrom,
        this.propData.dateTo
      );

      result.then((listClasses: ProductPriceClassModel[]) => {
        this.propData.listClasses = listClasses;
        this.classSelectedReassignment();
      }).catch((error) => {
        if ((error) && (error.message)) {
          console.log(error);
        }
      })
    }
  }

  @Emit("receive-function-next")
  SendFunctionNext(){
    return this.functionNext;
  }

  @Emit("on-error")
  emitError(errorMsg: string){
    return errorMsg;
  }

  fetchFileRooms(p_Data: PCVToursInvcprodModel)
  {
    let promiseResult = p_Data.api.fetchFileRooms(p_Data.pageCode, p_Data.categorySelected.code, p_Data.dateFrom, p_Data.dateTo);
    promiseResult.then((listRoom: RoomModel[]) =>
      {
        Vue.set(p_Data, "listRoom", listRoom);
      }).catch((error) => {
        console.log(error);
      });
  }

  fetchPax(p_Data: PCVToursInvcprodModel)
  {
    let promiseResult = p_Data.api.fetchPax(p_Data.pageCode, p_Data.categorySelected.code, p_Data.dateFrom, p_Data.dateTo);
    promiseResult.then((listPax: PaxModel[]) => {
        Vue.set(p_Data, "listPax", listPax);
      }).catch((error) => {
        console.log(error);
      });
  }

  logicNext() {
    if (this.propData.classSelected.code !== 0 && this.propData.categorySelected.code !== 0)
    {
      switch (this.propData.roomingType)
      {
        case EnumRoomingType.Room:
        {
          if (this.propData.categorySelected.isChild)
          {
            this.fetchFileRooms(this.propData);
            this.propData.listRoom = [];
            this.fetchPax(this.propData);
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.ChildrenAssignment;
          }
          else
          {
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.RoomSharingType;
            this.fetchPax(this.propData);
          }
          break;
        }
        case EnumRoomingType.Basic:
        {
          this.fetchPax(this.propData);
          this.propData.listRoom = [];
          this.propData.listRoom.push(new RoomModel(null))
          this.propData.previousPage.push(this.propData.currentPage);
          this.propData.currentPage = EnumPagination.PaxSelection;
          break;
        }
        case EnumRoomingType.Car:
        {
          if (this.propData.hasInventory)
          {
            if (this.propData.classSelected.availability > 0)
            {
              this.fetchPax(this.propData);
              this.propData.listRoom = [];
              this.propData.listRoom.push(new RoomModel(null))
              this.propData.previousPage.push(this.propData.currentPage);
              this.propData.currentPage = EnumPagination.PaxSelection;
            }
            else
            {
              this.emitError(this.propData.language.errorInsufficientInventory);
            }
          }
          else
          {
            this.fetchPax(this.propData);
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.PaxSelection;
          }
          break;
        }
      }
    }
  }

  functionNext: typeFunctionNext = () => {
    this.logicNext();
  }


}


import { LanguageDate } from '@/models/languageDate';

export class LanguageAvailabilityModel {

    cost: string = "Coûtant";
    dateFrom: string = "Date du";
    dateTo: string = "Date au"
    class: string = "Classe";
    category: string = "Tarif";
    sale: string = "Prix";
    product: string = "Produit";
    availability: string = "Disponible";
    categories: string = "Liste des tarifs";
    products: string = "Liste des produits / sous-produits";
    unlimited: string = "Illimité";
    noPricing: string = "Aucune tarification pour ces dates";
    refresh: string = "Rafraîchir";
    duration: string = "durée";
    days: string = "jours";
    nights: string = "nuits";
    isOption: string = "Optional";

    languageDate: LanguageDate = new LanguageDate();

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }

}
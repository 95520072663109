








import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursPaxAssignModel } from '../models/pcvToursPaxAssignModel';
import { typeFunctionNext } from '../../utilities/utils';
import { PaxModel } from './../../models/paxModel';
import { InitialisationModel } from '../../pcvBus/models/initialisationModel';
import PCVBus from '../../pcvBus/pcvBus.vue';
import { RoomModel } from '../../models/roomModel';

@Component({
  components: {
    "pcv-bus-component": PCVBus
  }
})
export default class BusAssignment extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursPaxAssignModel;

    get getBusInit(): InitialisationModel {
        if (this.propData.busData && this.propData.productSelected ) {
            return new InitialisationModel({
                structure: this.propData.busData.busStructure,
                listPax: this.propData.busPaxEditable,
                language: this.propData.language.languageBus,
                listPickup: this.propData.productSelected.listPickup
            })
        } else {
            return new InitialisationModel({
                structure: [],
                listPax: [],
                language: this.propData.language.languageBus,
                listPickup: []
            })
        }
    }

    beforeMount() {
        this.SendFunctionNext();
        this.emitShowCompleteButton(true);
    }

    beforeDestroy() {
        this.emitShowCompleteButton(false);
    }

    postComplete(listRoom: RoomModel[], listRoomDeleted:  number[]) {
        if (this.propData.productSelected) {
            let resultPromise = this.propData.api.postComplete(
                this.propData.productSelected.productCode,
                this.propData.productSelected.pricingClassCode,
                this.propData.productSelected.pricingClassName,
                this.propData.productSelected.dateFrom,
                this.propData.productSelected.dateTo,
                listRoomDeleted,
                listRoom
            )

            resultPromise.then((result: any) => {
                if (result.data.success) {
                    this.$emit("on-complete");
                } else {
                    this.emitError(result.data.errorMessage);
                }
            }).catch((error) => {
                console.log(error);
            })

        }
    }

    logicNext() {

        let busPax: PaxModel[] = (<PCVBus>this.$refs.busComponent).getListPax();
        let listBusPaxNotLock = busPax.filter((pax) => !pax.locked);
        let listBusPaxModified: PaxModel[] = [];

        listBusPaxNotLock.forEach((busPax: PaxModel, index: number) => {
            let paxFound = this.propData.busPaxEditable.find((pax) => pax.code == busPax.code);

            if (paxFound) {
                if (paxFound.row != busPax.row || paxFound.col != busPax.col || paxFound.busPickup != busPax.busPickup) {
                    listBusPaxModified.push(busPax);
                }
            }

            let roomFound = this.propData.listRoomModified.find((room) => room.listPax[0].code == busPax.code);
            if (roomFound) {
                roomFound.listPax[0] = busPax;
            }

        })

        let listRoom: RoomModel[] = [];

        listBusPaxModified.forEach((busPax) => {
            let roomFound = this.propData.listRoomProductSelected.find((room) => room.listPax[0].code == busPax.code);
            if (roomFound) {
                let tmpRoom = new RoomModel(roomFound);
                tmpRoom.listPax = [];
                tmpRoom.listPax.push(busPax);
                listRoom.push(tmpRoom);
            }
        })

        this.propData.listRoomModified.forEach((room) => listRoom.push(room));

        this.postComplete(listRoom, this.propData.listRoomDeleted);

    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
        return errorMsg;
    }

    @Emit("update-show-complete-button")
    emitShowCompleteButton(value: boolean){
        return value;
    }

}


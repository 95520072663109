import { initObject } from "../../utilities/utils";
import { LanguageDate } from "../../../../models/languageDate";
import { LanguageBusModel } from "../../pcvBus/models/languageBusModel";

export class LanguageInvcprodModel {

  previous: string = "Précédent";
  next: string = "Suivant";
  complete: string = "Compléter";
  cancel: string = "Annuler";

  dates: string = "Dates";
  from: string = "Du";
  to: string = "Au";
  duration: string = "Durée";
  days: string = "jour(s)";
  nights: string = "nuit(s)";

  option: string = "Option";
  optionProduct: string = "Produit";
  optionName: string = "Nom";
  optionClassCategory: string = "Class / Category";
  optionTotal: string = "Total";
  optionAvailability: string = "Inventaire";
  optionRequested: string = "Demandé";
  optionInfinite: string = "Infini";

  listDateEmpty: string = "Aucune date disponible";
  classes: string = "Classes";
  categories: string = "Tarifs";

  completeRooms : string = "Chambres complètes";
  sharedRooms : string = "Chambres partagées";
  add: string = "Ajouter";
  newRooms: string = "nouvelle(s) chambre(s)";
  shareNewRoom: string = "Partager une nouvelle chambre";
  shareExistingRoom: string = "Partager une chambre existante";

  inventory: string = "Inventaire";
  available: string = "Disponible";

  file: string = "Dossier";
  name: string = "Nom";
  gender: string = "Genre";
  language: string = "Langue";
  age: string = "Age";
  availability: string = "Disponibilité";

  listPax: string = "Liste des passagers";
  paxNotAssigned: string = "Passager non assignés";
  newRoom: string = "Nouvelle chambre";

  busPaxNotAssigned: string = "Non assigné";
  seat: string = "Siège";

  errorCategoriesWithClass: string = "Tarif non compatible avec la classe";
  errorInsufficientInventory: string = "Inventaire insuffisant";
  errorNoPaxSelected: string = "Aucun passager sélectionné";
  errorRoomNotAvailablePax: string = "Place disponible dans la chambre insuffisante pour le nombre de passager sélectionnés";
  errorRoomNoPlaceShare: string = "Dois rester au moins une place de libre dans la nouvelle chambre partagée";
  errorRoomCapacity: string = "La capacité des chambres n'est pas valide";
  errorNoRoomSelected: string = "Aucune chambre sélectionnée";
  errorCloseButton: string = "Fermer";

  languageDatePicker: LanguageDate = new LanguageDate();
  languageBus: LanguageBusModel = new LanguageBusModel(null);

  constructor(value: any) {
    initObject(this, value);
  }

}
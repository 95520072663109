































import { Vue, Component, Model, Emit, Prop } from "vue-property-decorator";

@Component
export default class TableEditInput extends Vue {

  @Model('input', { type: [String, Number] }) value!: string | number;

  MyValue: string = "";

  @Prop({ type: String, default: "" }) propCustomClass!: string;
  @Prop({ type: String, default: "String" }) propType!: string;
  @Prop({ type: Array }) propOptions!: any;

  get getType(): string {

    let tmpReturnValue: string;

    switch (this.propType) {
      case 'String':
        tmpReturnValue = "text";
        break;
      case 'Date':
        tmpReturnValue = "date";
        break;
      case 'Currency':
        tmpReturnValue = "number";
        break;
      case 'Integer':
        tmpReturnValue = "number";
        break;
      case 'Select':
        tmpReturnValue = "select";
        break;
      default:
        tmpReturnValue = "text";
    }

    return tmpReturnValue;

  }

  get getStep(): string {
    if (this.propType === 'Currency') {
      return "0.01";
    } else {
      return "1";
    }
  }

  OnPressTab(event: Event) {
    event.preventDefault();
    this.OnInputChange();
    this.EmitPressTab();
  }

  OnPressShiftTab(event: Event) {
    event.preventDefault();
    this.OnInputChange();
    this.EmitPressShiftTab();
  }

  OnPressEsc(event: Event) {
    event.preventDefault();
    this.OnHideInput();
  }

  OnPressEnter(event: Event) {
    event.preventDefault();
    this.OnInputChange();
    this.OnHideInput();
  }

  formatingValueOut(): string | number {

    let tmpReturnValue: string | number;

    switch (this.propType) {
      case 'Date':
        tmpReturnValue = this.MyValue + this.value.toString().substring(10, this.value.toString().length);
        break;
      case 'Currency':
        if (!isNaN(Number(this.MyValue))) {
          tmpReturnValue = Number(this.MyValue);
        } else {
          tmpReturnValue = 0.00;
        }
        break;
      case 'Integer':
        if (!isNaN(Number(this.MyValue))) {
          tmpReturnValue = parseInt(this.MyValue);
        } else {
          tmpReturnValue = 0;
        }
        break;
      default:
        tmpReturnValue = this.MyValue;
    }

    return tmpReturnValue;

  }

  mounted() {

    if (this.propType === 'Date') {
      this.MyValue = (new Date(this.value)).toISOString().substring(0, 10);
    } else if (this.propType === 'Currency') {
      this.MyValue = Number(this.value).toFixed(2);
    } else {

      if (typeof this.value === "undefined") {
        this.MyValue = "";
      } else {
        this.MyValue = this.value.toString();
      }
    }

    window.addEventListener("click", this.OnClickExtern, true);
    let theInput = this.$refs["editableInput"];
    if (theInput) {
      this.$nextTick(() => (theInput as HTMLInputElement).focus());
    }
  }

  beforeDestroy() {
    window.removeEventListener("click", this.OnClickExtern, true);
  }

  OnClickExtern(e: Event) {
    // close dropdown when clicked outside
    if (e.target) {
      if (!this.$el.contains(<Node>e.target)) {
        this.OnInputChange();
        this.OnHideInput();
      }
    }
  }


  @Emit("tab")
  EmitPressTab() {
  }

  @Emit("shift-tab")
  EmitPressShiftTab() {
  }

  OnInputChange() {

    this.EmitInput();

    if (this.value !== this.formatingValueOut()) {
      this.$emit('data-change', this.value, this.formatingValueOut());
    }

  }


  @Emit("input")
  EmitInput() {
    return this.formatingValueOut();
  }

  @Emit("hide-input")
  OnHideInput() {
  }

}


import { IPCVTableColumn } from './IPCVTableColumn';

export class PCVTableColumn implements IPCVTableColumn {
  id: string = "";
  label: string = "";
  type: string = "";
  align: string | undefined;
  style: object | undefined;
  filters: any[] | undefined;

  constructor(p_Id: string, p_Label: string, p_Type: string, p_Align?: string, p_Style?: object, p_Filters?: any[]) {
    this.id = p_Id;
    this.label = p_Label;
    this.type = p_Type;

    if (p_Align) {
      this.align = p_Align;
    }

    if (p_Style) {
      this.style = p_Style;
    }

    if (p_Filters) {
      this.filters = p_Filters;
    }

  }

}
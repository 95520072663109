import Vue from 'vue';

import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

//Solid
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faFileCsv,
    faExchangeAlt,
    faSearch,
    faCalendarAlt,
    faSquare,
    faCheckSquare,
    faArrowLeft,
    faArrowRight,
    faUserTie,
    faRestroom,
    faTimes,
    faEdit,
    faTrash,
    faSyncAlt,
    faSave,
    faChartBar,
    faShoppingCart,
    faFileSearch,
    faPrint,
    faChevronUp,
    faChevronDown,
    faPencil as faPencilSolid,
    faTrashAlt as faTrashAltSolid
} from "@fortawesome/pro-solid-svg-icons";

//Regular
import {
    faSpinner as faSpinnerRegular,
    faDollarSign,
    faSyncAlt as faSyncAltRegular,
    faArrowLeft as faArrowLeftRegular,
    faShare,
    faReply
} from "@fortawesome/pro-regular-svg-icons";

//Light
import {

} from "@fortawesome/pro-light-svg-icons";

//Duotone
import { 
    faSpinner as faSpinnerDuo,
    faSortUp as faSortUpDuo,
    faSortDown as faSortDownDuo,
} from "@fortawesome/pro-duotone-svg-icons";

Vue.component('pcv-icon', FontAwesomeIcon);
Vue.component('pcv-layers', FontAwesomeLayers);
Vue.component('pcv-layers-text', FontAwesomeLayersText);

library.add(
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faShare,
    faReply,
    faFileCsv,
    faExchangeAlt,
    faSearch,
    faCalendarAlt,
    faSpinnerRegular,
    faSpinnerDuo,
    faSquare,
    faCheckSquare,
    faArrowLeft,
    faArrowLeftRegular,
    faArrowRight,
    faUserTie,
    faRestroom,
    faTimes,
    faSortUpDuo,
    faSortDownDuo,
    faEdit,
    faTrash,
    faSyncAlt,
    faSyncAltRegular,
    faSave,
    faChartBar,
    faDollarSign,
    faShoppingCart,
    faFileSearch,
    faPrint,
    faChevronUp,
    faChevronDown,
    faPencilSolid,
    faTrashAltSolid
);

























import { Component, Prop, Vue, Model } from 'vue-property-decorator';
import PCVTableColumnTypeEnum from './models/pcvTableColumnTypeEnum';

@Component
export default class PCVCellEdited extends Vue {

    @Model('input', { type: [String, Number] }) valueModel!: string | number;
    @Prop({ type: Number, default: PCVTableColumnTypeEnum.Text }) type!: PCVTableColumnTypeEnum;
    @Prop({ type: Array, default: () => [] }) options!: any[];

    valueInput: string | number = "";

    get getPCVTableColumnTypeEnum(): typeof PCVTableColumnTypeEnum{
        return PCVTableColumnTypeEnum;
    }

    get getNumberStep(): string {
      if (this.type == PCVTableColumnTypeEnum.Currency) {
        return "0.01";
      } else {
        return "1";
      }
    }

    mounted(){
        if (typeof this.valueModel !== "undefined" && this.valueModel !== null) {
            this.valueInput = this.valueModel;
        }

        window.addEventListener("click", this.onClickExtern, true);
        let theInput: HTMLInputElement = (this.$refs["inputEdited"]) as HTMLInputElement;
        if (theInput) {
            this.$nextTick(() => {
                theInput.focus();
                theInput.select();
            });
        }

    }

    beforeDestroy() {
      window.removeEventListener("click", this.onClickExtern, true);
    }

    onClickExtern(e: Event) {
      // close dropdown when clicked outside
      if (e.target) {
        if (!this.$el.contains(<Node>e.target)) {
          this.emitInputChange();
          this.emitClose();
        }
      }
    }

    onPressTab(event: Event) {
        event.preventDefault();
        this.emitInputChange();
        this.emitPressTab();
    }

    onPressShiftTab(event: Event) {
        event.preventDefault();
        this.emitInputChange();
        this.emitPressShiftTab();
    }

    onPressEsc(event: Event) {
        event.preventDefault();
        this.emitClose();
    }

    onPressEnter(event: Event) {
        event.preventDefault();
        this.emitInputChange();
        this.emitClose();
    }

    formattingValueOut(): string | number {

        let tmpReturnValue: string | number;

        switch (this.type) {
            case PCVTableColumnTypeEnum.Date:
                tmpReturnValue = this.valueInput; //set momentjs
                break;
            case PCVTableColumnTypeEnum.Currency:
                if (this.valueInput === "") {
                    tmpReturnValue = 0;
                } else {
                    tmpReturnValue = Math.round(Number(this.valueInput) * 100) / 100;
                }
                break;
            case PCVTableColumnTypeEnum.Integer:
                if (this.valueInput === "") {
                    tmpReturnValue = 0;
                } else {
                    tmpReturnValue = parseInt(this.valueInput.toString());
                }
                break;
            default:
                tmpReturnValue = this.valueInput;
        }

        return tmpReturnValue;

    }

    emitInputChange() {

        let valueFormated = this.formattingValueOut();

        this.$emit("input", valueFormated);

        this.$emit("table-data-change", this.valueModel, valueFormated);

    }

    emitPressTab() {
        this.$emit("tab");
    }

    emitPressShiftTab() {
        this.$emit("shift-tab");
    }

    emitClose() {
        this.$emit("close");
    }


}











import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { fetchOptionProduct, typeFunctionNext } from '../../utilities/utils';
import { EnumPagination, EnumConfirmationType } from '../utilities/enum';
import { EnumRoomingType } from "../../utilities/enum";
import { ShareableRoomModel } from '../models/shareableRoomModel';
import { RoomModel } from '../../models/roomModel';
import { BusDataModel } from '../../models/busDataModel';
import PaxSelectionView from "../../components/paxSelectionView.vue";
import { PaxSelectionViewModel } from '../../models/paxSelectionViewModel';
import { PaxModel } from '../../models/paxModel';

@Component({
    components: {
        "pax-selection-view": PaxSelectionView
    }
})
export default class PaxSelection extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    get getPaxSelectionData(): PaxSelectionViewModel {
        if (this.propData.roomingType === EnumRoomingType.Basic && this.propData.hasInventory) {
            if (this.propData.withBus) {
                let inventory: number = Math.min(this.propData.classSelected.availability, (this.propData.busData.nbPlaces - this.propData.busData.listPax.length));
                return new PaxSelectionViewModel(this.propData.listRoom[0].listPax, this.propData.listPax, this.propData.language, true, inventory);
            } else {
                return new PaxSelectionViewModel(this.propData.listRoom[0].listPax, this.propData.listPax, this.propData.language, true, this.propData.classSelected.availability);
            }
        } else if (this.propData.withBus) {
            return new PaxSelectionViewModel(this.propData.listRoom[0].listPax, this.propData.listPax, this.propData.language, true, (this.propData.busData.nbPlaces - this.propData.busData.listPax.length));
        } else {
            return new PaxSelectionViewModel(this.propData.listRoom[0].listPax, this.propData.listPax, this.propData.language);
        }
    }

    @Watch("propData.listPax")
    onWatchListPax(){
        if (this.propData.listRoom[0].listPax.length === 0) {
            this.propData.listPax.forEach((pax: PaxModel) => this.propData.listRoom[0].listPax.push(pax));
        }
    }

    beforeMount() {
        this.SendFunctionNext();

        if (this.propData.listRoom[0].listPax.length === 0) {
            this.propData.listPax.forEach((pax: PaxModel) => this.propData.listRoom[0].listPax.push(pax));
        }

        if (this.propData.withBus) {
            this.fetchBusData();
        }
    }

    fetchBusData() {
        let fetchPromise = this.propData.api.fetchBusData(this.propData.pageCode, this.propData.categorySelected.code, this.propData.dateFrom, this.propData.dateTo);

        fetchPromise.then((response: BusDataModel) => {
            this.propData.busData = response;
        }).catch((error) => console.log(error));

    }

    logicNextOption()
    {
        this.propData.listOptionSelected = [];
        if (this.propData.hasOption)
        {
            fetchOptionProduct(this.propData);
            this.propData.currentPage = EnumPagination.ProductOption;
        }
        else
        {
            this.propData.currentPage = EnumPagination.Confirmation;
        }
    }

    logicNext() {

        this.propData.listRoom[0].listPax =  this.getPaxSelectionData.listPaxSelected;

        if (this.propData.listRoom[0].listPax.length > 0) {
            switch (this.propData.roomingType) {
                case EnumRoomingType.Room: {
                    if (this.propData.listRoom[0] instanceof ShareableRoomModel) {
                        if (this.propData.listRoom[0].listPax.length <= (<ShareableRoomModel>this.propData.listRoom[0]).availability) {
                            this.propData.confirmationType = EnumConfirmationType.ExistingSharedRoom;
                            this.propData.previousPage.push(this.propData.currentPage);
                            this.logicNextOption();
                        } else {
                            this.emitError(this.propData.language.errorRoomNotAvailablePax)
                        }
                    } else if (this.propData.listRoom[0] instanceof RoomModel) {
                        if (this.propData.listRoom[0].listPax.length <= this.propData.categorySelected.nbPaxMaximum - 1) {
                            this.propData.confirmationType = EnumConfirmationType.NewSharedRoom;
                            this.propData.previousPage.push(this.propData.currentPage);
                            this.logicNextOption();
                        } else {
                            this.emitError(this.propData.language.errorRoomNoPlaceShare);
                        }
                    } else {
                        this.propData.previousPage.push(this.propData.currentPage);
                        this.logicNextOption();
                    }
                    break;
                }
                case EnumRoomingType.Basic: {

                    let isValid: boolean = false;

                    if (this.propData.hasInventory) {
                         if (this.propData.withBus) {
                            if (this.propData.classSelected.availability >= this.propData.listRoom[0].listPax.length
                                && (this.propData.busData.nbPlaces - this.propData.busData.listPax.length) >= this.propData.listRoom[0].listPax.length) {
                                isValid = true;
                            }
                        } else {
                            if (this.propData.classSelected.availability >= this.propData.listRoom[0].listPax.length) {
                                isValid = true;
                            }
                        }
                    } else {
                        if (this.propData.withBus) {
                            if ((this.propData.busData.nbPlaces - this.propData.busData.listPax.length) >= this.propData.listRoom[0].listPax.length) {
                                isValid = true;
                            }
                        } else {
                            isValid = true;
                        }
                    }

                    if (isValid) {
                        if (this.propData.withBus) {
                            this.propData.listBusPaxSelected = [];
                            this.propData.previousPage.push(this.propData.currentPage);
                            this.propData.currentPage = EnumPagination.BusAssignment;
                        } else {
                            this.propData.confirmationType = EnumConfirmationType.Basic;
                            this.propData.previousPage.push(this.propData.currentPage);
                            this.logicNextOption();
                        }
                    } else {
                        this.emitError(this.propData.language.errorInsufficientInventory);
                    }
                    break;
                }
                case EnumRoomingType.Car: {
                    this.propData.confirmationType = EnumConfirmationType.Basic;
                    this.propData.previousPage.push(this.propData.currentPage);
                    this.logicNextOption();
                    break;
                }
            }
        } else {
            this.emitError(this.propData.language.errorNoPaxSelected);
        }

    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
        console.log(errorMsg)
        return errorMsg;
    }

}

import { initObject } from "../../utilities/utils";

export class ProductPriceCategoryModel {

    code: number = 0;
    disabled: boolean = false;
    isShareable: boolean = false;
    name: string = "";
    nbPaxMinimum: number = 0;
    nbPaxMaximum: number = 0;
    pricingCategory: number = 0;
    isChild: boolean = false;

    constructor(value: any = null) {
        initObject(this, value);
    }

}
enum PCVTableColumnTypeEnum {
    Text = 0,
    Currency = 1,
    Integer = 2,
    Date = 3,
    Datetime = 4,
    Icon = 5,
    Image = 6,
    Checkbox = 7,
    HTML = 8
}

export default PCVTableColumnTypeEnum;
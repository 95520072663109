import { initObject } from "../utilities/utils";

export class PaxModel{

  code: string = "";
  name: string = "";
  file: string = "";
  roomCode: number = 0;
  row: number = 0;
  col: number = 0;
  locked: boolean = false;
  busPickup: number = 0;

  constructor(value: any)
  {
    initObject(this, value);
  }
}
import { ProductPriceCategoryModel } from "./productPriceCategoryModel";
import { ProductPriceClassModel } from "./productPriceClassModel";
import { EnumPagination, EnumSharingType, EnumConfirmationType } from "../utilities/enum";
import { EnumRoomingType } from "../../utilities/enum";
import { InitialisationModel } from "./initialisationModel";
import { PaxModel } from "../../models/paxModel";
import { RoomModel } from "../../models/roomModel";
import { BusDataModel } from '../../models/busDataModel';
import { LanguageInvcprodModel } from "./languageInvcprodModel";
import { pcvToursInvcprodAPI } from "../utilities/api";
import { PCVToursModel } from "../../models/pcvToursModel";
import { PickupPointModel } from "../../models/pickupPointModel";
import DateWithFixedDurationModel from "./dateWithFixedDurationModel";
import { OptionProductModel } from "./optionProduct";

export class PCVToursInvcprodModel extends PCVToursModel {

    readonly dateReservation: string = "";
    readonly product: string = "";
    readonly nbPax: number = 1;
    readonly roomingType: EnumRoomingType = EnumRoomingType.Basic;
    readonly withBus: boolean = false;
    readonly hasInventory: boolean = false;
    readonly pageCode: number = 0;
    readonly dateFormat: string = "YYYY-MM-DD";
    readonly language: LanguageInvcprodModel = new LanguageInvcprodModel(null);
    readonly api: pcvToursInvcprodAPI = new pcvToursInvcprodAPI("", "");
    readonly listPickup: PickupPointModel[] = [];
    readonly defaultIndex: number = -1;

    readonly listDate: DateWithFixedDurationModel[] = [];
    readonly isFixedDuration: boolean = false;
    readonly hasOption: boolean = false;

    constructor(init: InitialisationModel) {
        super();

        this.dateReservation = init.dateReservation;
        this.product = init.product;
        this.nbPax = init.nbPax;
        this.roomingType = init.roomingType;
        this.withBus = init.withBus;
        this.hasInventory = init.hasInventory;
        this.pageCode = init.pageCode;
        this.dateFormat = init.dateFormat;
        this.hasOption = init.hasOption;

        this.language = init.language;
        this.api = new pcvToursInvcprodAPI(init.baseURL, init.file);
        this.listPickup = init.listPickup;

        this.listDate = init.listDate;
        this.isFixedDuration = init.isFixedDuration;
        this.defaultIndex = init.defaultIndex;

        if (this.isFixedDuration)
        {
            if (this.listDate.length > 0 && this.defaultIndex > -1)
            {
                this.dateFrom = this.listDate[this.defaultIndex].dateFrom;
                this.dateTo = this.listDate[this.defaultIndex].dateTo;
            } else {
                this.dateFrom = "";
                this.dateTo = "";
            }
        } else {
            this.dateFrom = init.dateFrom;
            this.dateTo = init.dateTo;
        }


        let maVariable : undefined | string;
    }

    private _currentPage: EnumPagination = EnumPagination.DateClassCategory;
    // #region currentPage
    get currentPage(): EnumPagination {
        return this._currentPage;
    }
    set currentPage(value: EnumPagination) {
        this._currentPage = value;
    }
    // #endregion

    private _previousPage: EnumPagination[] = [];
    // #region previousPage
    get previousPage(): EnumPagination[] {
        return this._previousPage;
    }
    set previousPage(value: EnumPagination[]) {
        this._previousPage = value;
    }
    // #endregion

    private _listClasses: ProductPriceClassModel[] = [];
    // #region listClass
    get listClasses(): ProductPriceClassModel[] {
        return this._listClasses;
    }
    set listClasses(value: ProductPriceClassModel[]) {
        this._listClasses = value;
    }
    // #endregion

    private _dateFrom: string = "";
    // #region dateFrom
    get dateFrom(): string {
        return this._dateFrom;
    }
    set dateFrom(value: string) {
        this._dateFrom = value;
    }
    // #endregion

    private _dateTo: string = "";
    // #region dateTo
    get dateTo(): string {
        return this._dateTo;
    }
    set dateTo(value: string) {
        this._dateTo = value;
    }
    // #endregion

    private _classSelected: ProductPriceClassModel = new ProductPriceClassModel();
    // #region classSelected
    get classSelected(): ProductPriceClassModel {
        return this._classSelected;
    }
    set classSelected(value: ProductPriceClassModel) {
        this._classSelected = value;
    }
    // #endregion

    private _categorySelected: ProductPriceCategoryModel = new ProductPriceCategoryModel();
    // #region categorySelected
    get categorySelected(): ProductPriceCategoryModel {
        return this._categorySelected;
    }
    set categorySelected(value: ProductPriceCategoryModel) {
        this._categorySelected = value;
    }
    // #endregion

    private _roomSharingTypeSelected: EnumSharingType = EnumSharingType.NotShared;
    // #region roomSharingTypeSelected
    get roomSharingTypeSelected(): EnumSharingType {
        return this._roomSharingTypeSelected;
    }
    set roomSharingTypeSelected(value: EnumSharingType) {
        this._roomSharingTypeSelected = value;
    }
    // #endregion

    private _listPax: PaxModel[] = [];
    // #region listPax
    get listPax(): PaxModel[] {
        return this._listPax;
    }
    set listPax(value: PaxModel[]) {
        this._listPax = value;
    }
    // #endregion

    private _listRoom: RoomModel[] = [];
    // #region listRoom
    get listRoom(): RoomModel[] {
        return this._listRoom;
    }
    set listRoom(value: RoomModel[]) {
        this._listRoom = value;
    }
    // #endregion

    private _busData: BusDataModel = new BusDataModel(null);
    // #region busData
    get busData(): BusDataModel {
        return this._busData;
    }
    set busData(value: BusDataModel) {
        this._busData = value;
    }
    // #endregion

    private _listBusPaxSelected: PaxModel[] = [];
    // #region listBusPaxSelected
    get listBusPaxSelected(): PaxModel[] {
        return this._listBusPaxSelected;
    }
    set listBusPaxSelected(value: PaxModel[]) {
        this._listBusPaxSelected = value;
    }
    // #endregion

    private _confirmationType: EnumConfirmationType = EnumConfirmationType.NewRooms;
    // #region confirmationType
    get confirmationType(): EnumConfirmationType {
      return this._confirmationType;
    }
    set confirmationType(value: EnumConfirmationType) {
      this._confirmationType = value;
    }
    // #endregion

    private _listChildren: PaxModel[] = [];
    // #region listChildren
    get listChildren(): PaxModel[] {
      return this._listChildren;
    }
    set listChildren(value: PaxModel[]) {
      this._listChildren = value;
    }
    // #endregion

    private _roomChildrenSelected: RoomModel | null = null;
    // #region roomChildrenSelected
    get roomChildrenSelected(): RoomModel | null {
      return this._roomChildrenSelected;
    }
    set roomChildrenSelected(value: RoomModel | null) {
      this._roomChildrenSelected = value;
    }
    // #endregion

    // #region listOption
    private _listOption: OptionProductModel[] = [];
    get listOption(): OptionProductModel[] {
        return this._listOption;
    }
    set listOption(value: OptionProductModel[]) {
        this._listOption = value;
    }
    // #endregion

    // #region listOption
    private _listOptionSelected: number[] = [];
    get listOptionSelected(): number[] {
        return this._listOptionSelected;
    }
    set listOptionSelected(value: number[]) {
        this._listOptionSelected = value;
    }
    // #endregion

}











import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { ShareableRoomModel } from '../models/shareableRoomModel';
import PCVTable from '@/components/pcvOldTable/PCVTable.vue';
import { PCVTableColumn } from '@/components/pcvOldTable/models/PCVTableColumn';
import { typeFunctionNext } from '../../utilities/utils';
import { EnumPagination } from '../utilities/enum';
import { RoomModel } from '../../models/roomModel';

@Component({
    components: {
        "pcv-table": PCVTable
    }
})
export default class ShareableRoom extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    test: string = "okidoki";
    shareableRoomSelected: ShareableRoomModel | null = null;
    listShareableRoom: ShareableRoomModel[] = [];
    columns: PCVTableColumn[] = [
        new PCVTableColumn("file", this.propData.language.file, "String"),
        new PCVTableColumn("availability", this.propData.language.availability, "Number"),
        new PCVTableColumn("name", this.propData.language.name, "String"),
        new PCVTableColumn("language", this.propData.language.language, "String"),
        new PCVTableColumn("gender", this.propData.language.gender, "String"),
        new PCVTableColumn("age", this.propData.language.age, "String")
    ];

    beforeMount() {
        this.SendFunctionNext();
        this.fetchSharedRooms();
    }

    fetchSharedRooms() {
        let promiseResult = this.propData.api.fetchSharedRooms(this.propData.pageCode, this.propData.categorySelected.code, this.propData.dateFrom, this.propData.dateTo);

        promiseResult.then((result: ShareableRoomModel[]) => {
            let roomSelected = this.propData.listRoom[0];
            if (roomSelected) {
                let roomFound = result.find((room: RoomModel) => room.code === roomSelected.code);
                if (roomFound) {
                    roomFound.sel = true;
                    this.shareableRoomSelected = roomFound;
                }
            }
            this.listShareableRoom = result;
        }).catch((error) => console.log(error));

    }

    logicNext() {
        if (this.shareableRoomSelected){
            this.propData.listRoom = [];
            this.propData.listRoom.push(this.shareableRoomSelected);
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.PaxSelection;
        } else {
            this.emitError(this.propData.language.errorNoRoomSelected)
        }
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
        return errorMsg;
    }

}

import "es6-promise/auto";
import Axios, { AxiosInstance } from 'axios';
import { ClassModel } from "../models/classModel";
import { ProductModel } from "../models/productModel";
import { CategoryModel } from "../models/categoryModel";
import moment from "moment";

export class PCVAvailabilityApi {

    private axios: AxiosInstance;
    private pageCode: number = 0;

    constructor(baseURL: string, pageCode: number) {
        this.axios = Axios.create({
            baseURL: baseURL
        })
        this.pageCode = pageCode;
    }

    fetchClasses(
        product: string,
        dateFrom: string,
        dateTo: string,
    ): Promise<any> {
    
    return this.axios.post("GetClasses.ashx", 
        {
            product: product,
            dateFrom : moment(dateFrom).format("YYYY-MM-DD"),
            dateTo : moment(dateTo).format("YYYY-MM-DD")
        },
        {
          params: {
              pageCode: this.pageCode
          }
        }
    ).then((response) => {
  
      let listClass: ClassModel[] = [];

      if (response.data && Array.isArray(response.data.listClass)) {

        response.data.listClass.forEach((classFromServer : any) => {
          listClass.push(new ClassModel(classFromServer));
        });

      }
      
      return listClass;
  
    })
  }

    fetchDates(p_Product: string) {
        return this.axios.post("GetDates.ashx", { product: p_Product }, { params: { pageCode: this.pageCode } }).then( (response) => {
           return response.data; 
        })
    }

}


























import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "../../models/pcvBusModel";

@Component
export default class StructureSetting extends Vue {
    
    @Prop({ type: Object }) readonly propData!: PCVBusModel;

    

}


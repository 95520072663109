import { CategoryModel } from "./categoryModel";

export class ClassModel {
    
    code: number = 0;
    name: string = "";
    listCategory: CategoryModel[] = [];

    constructor(value: any = null) {
        if (value) {
            for (let key in this) {
                if (value.hasOwnProperty(key)) {
                    this[key] = value[key];
                }
            }
        }
    }
}
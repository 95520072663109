import { LanguageBusModel } from "./languageBusModel";
import { initObject } from "../../utilities/utils";
import { PaxModel } from "../../models/paxModel";
import { PickupPointModel } from "../../models/pickupPointModel";

export class InitialisationModel {

    language: LanguageBusModel = new LanguageBusModel(null);
    structure: string[][] = [];
    listPax: (PaxModel | null)[] = [];
    listPickup: PickupPointModel[] = [];

    constructor(value: any) {
        initObject(this, value);
    }
  
  }










































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { PaxSelectionViewModel } from "../models/paxSelectionViewModel";

@Component
export default class paxSelectionView extends Vue {

  @Prop({ type: Object }) readonly propData!: PaxSelectionViewModel;
  selectAllChecked: boolean = false;

  @Watch("selectAllChecked")
  onWatchSelectAllChecked(value: boolean) {

    if (value) {
      this.propData.listPaxSelected = [];
      this.propData.listPaxSelected = this.propData.listPax;
    } else if (this.propData.listPaxSelected.length === this.propData.listPax.length) {
      this.propData.listPaxSelected = [];
    }

  }

  onChange() {
    if (this.selectAllChecked) {
      if (this.propData.listPaxSelected.length !== this.propData.listPax.length) {
        this.selectAllChecked = false;
      }
    }
  }

}


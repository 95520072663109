import { initObject } from "../../utilities/utils";

export default class LanguageToursRoomSharingModel {

    file: string = "Dossier";
    name: string = "Nom";
    language: string = "Langue";
    gender: string = "Genre";
    age: string = "Âge"

    constructor(value: any) {
        initObject(this, value);
    }
}
export interface IPCVTableColumn {
  id: string;
  label: string;
  type: string;
  align?: string;
  style?: object;
  editable?: any[];
}

export class PCVTableColumn implements IPCVTableColumn {
  id: string;
  label: string;
  type: string;
  align: string | undefined;
  style: object | undefined;
  editable: any[] | undefined;

  constructor(id: string, label: string, type: string, align?: string, style?: object, editable?: any[]) {
    this.id = id;
    this.label = label;
    this.type = type;

    if (align) {
      this.align = align;
    }

    if (style) {
      this.style = style;
    }

    if (editable) {
      this.editable = editable;
    }

  }

}

export interface IPCVTableFilterMethod {
  (data: any[], columns: IPCVTableColumn[], filterTerms: string | object): any[];
}
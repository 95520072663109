






    import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
    import PaxSelectionView from "../../components/paxSelectionView.vue";
    import { PaxSelectionViewModel } from '../../models/paxSelectionViewModel';
    import { PCVToursPaxAssignModel } from '../models/pcvToursPaxAssignModel';
    import { typeFunctionNext } from '../../utilities/utils';
    import { RoomModel } from '../../models/roomModel';
    import { PaxModel } from '../../models/paxModel';
import { EnumPaginationToursPaxAssign } from '../utilities/enum';

    @Component({
        components: {
            "pax-selection-view": PaxSelectionView
        }
    })
    export default class PaxSelection extends Vue {

        @Prop({ type: Object }) readonly propData!: PCVToursPaxAssignModel;
        listPaxSelected: PaxModel[] = [];

        get getPaxSelectionData(): PaxSelectionViewModel {
            /*if (this.propData.productSelected && this.propData.productSelected.withBus) {
                //(this.propData.busData.nbPlaces - this.propData.busData.listPax.length)
                return new PaxSelectionViewModel(this.listPaxSelected, this.propData.listPax, this.propData.language);
            } else {
                return new PaxSelectionViewModel(this.listPaxSelected, this.propData.listPax, this.propData.language)
            }*/
            return new PaxSelectionViewModel(this.listPaxSelected, this.propData.listPax, this.propData.language);
        }

        beforeMount() {
            this.SendFunctionNext();

            if (this.propData.productSelected) {
                if (this.propData.productSelected.withBus) {
                    this.fetchBusData();
                } else {
                    this.emitShowCompleteButton(true);
                }
            }

            let tmpRoom: RoomModel = new RoomModel(null);
            let listBusPax = this.propData.busPaxEditable.filter((busPax) => !busPax.locked);   

            if (listBusPax.length > 0) {

                listBusPax.forEach((busPax: PaxModel) => {
                    let paxFound = this.propData.listPax.find((pax) => pax.code === busPax.code);
                    if (paxFound) {
                        this.listPaxSelected.push(paxFound);
                    }
                })
            } else {
                this.propData.listRoomProductSelected.forEach((room: RoomModel) => {
                    room.listPax.forEach((paxInRoom: PaxModel) => {
                        let paxFound = this.propData.listPax.find((pax) => pax.code === paxInRoom.code);
                        if (paxFound) {
                            this.listPaxSelected.push(paxFound);
                        }
                    })
                })
            }

            this.propData.busPaxEditable = [];
            this.propData.listRoomDeleted = [];
            this.propData.listRoomModified = [];
        }

        beforeDestroy() {
            if (this.propData.productSelected) {
                if (!this.propData.productSelected.withBus) {
                    this.emitShowCompleteButton(false);
                }
            }
        }

        fetchBusData() {
            let productSelected = this.propData.productSelected;
            if (productSelected) {
                let resultPromise = this.propData.api.fetchBusData(productSelected.productCode, productSelected.pricingClassCode, productSelected.dateFrom, productSelected.dateTo);
                resultPromise.then((busData) => {
                    this.propData.busData = busData;
                }).catch((error) => {
                    console.log(error);
                })
            }
        }

        postComplete(listRoom: RoomModel[], listRoomDeleted:  number[]) {
            if (this.propData.productSelected) {
                let resultPromise = this.propData.api.postComplete(
                    this.propData.productSelected.productCode,
                    this.propData.productSelected.pricingClassCode,
                    this.propData.productSelected.pricingClassName,
                    this.propData.productSelected.dateFrom,
                    this.propData.productSelected.dateTo,
                    listRoomDeleted,
                    listRoom
                )

                resultPromise.then((result: any) => {
                    if (result.data.success) {
                        this.$emit("on-complete");
                    } else {
                        this.emitError(result.data.errorMessage);
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        }

        logicNext() {

            let listPaxSelected: PaxModel[] = this.getPaxSelectionData.listPaxSelected;
            let listRoom: RoomModel[] = [];
            let listRoomDeleted: number[] = [];
            let listPaxDeleted: PaxModel[] = [];

            listPaxSelected.forEach((pax) => {
                let roomFound = this.propData.listRoomProductSelected.find((room) => room.listPax[0].code === pax.code);
                if (roomFound) {
                    //listRoomDelete.push(roomFound.code);
                } else {
                    let newRoom = new RoomModel(null);
                    newRoom.listPax.push(pax);
                    listRoom.push(newRoom);
                }
            })

            this.propData.listRoomProductSelected.forEach((room) => {
                let paxFound = listPaxSelected.find((pax) => room.listPax[0].code === pax.code);

                if (!paxFound) {
                    listRoomDeleted.push(room.code);
                    listPaxDeleted.push(room.listPax[0]);
                }

            })

            if (this.propData.productSelected && this.propData.productSelected.withBus && this.propData.busData && listRoomDeleted.length === listRoom.length) {
                    
                let tmpListBusPax: PaxModel[] = [];
                
                listPaxSelected.forEach((paxSelected) => {
                    if (this.propData.productSelected) {
                        let paxFound = this.propData.productSelected.listRoom.find((room) => room.listPax[0].code == paxSelected.code);
                        let tmpBusPax;
                        if (paxFound) {
                            tmpBusPax = new PaxModel(paxFound.listPax[0]);
                        } else {
                            tmpBusPax = new PaxModel(paxSelected);
                            tmpBusPax.file = this.propData.file;
                        }
                        tmpListBusPax.push(tmpBusPax);
                    }
                })

                this.propData.busPaxEditable = this.propData.busData.listPax.concat(tmpListBusPax);

                this.propData.listRoomModified = listRoom;
                this.propData.listRoomDeleted = listRoomDeleted;
                this.propData.previousPage.push(this.propData.currentPage);
                this.propData.currentPage = EnumPaginationToursPaxAssign.BusAssignment;

            } else {
                if (listRoomDeleted.length === listRoom.length && listRoom.length > 0) {
                    this.postComplete(listRoom, listRoomDeleted)
                } else {
                    if (listRoomDeleted.length !== listRoom.length) {
                        this.emitError(this.propData.language.errorAttemptToChangeInventory);
                    } else {
                        this.emitError(this.propData.language.errorNoChange);
                    }
                }
            }

        }

        functionNext: typeFunctionNext = () => {
            this.logicNext();
        }

        @Emit("receive-function-next")
        SendFunctionNext(){
            return this.functionNext;
        }

        @Emit("on-error")
        emitError(errorMsg: string){
            return errorMsg;
        }

        @Emit("update-show-complete-button")
        emitShowCompleteButton(value: boolean){
            return value;
        }

    }

import "es6-promise/auto";
import Axios, { AxiosInstance } from 'axios';
import { ProductPriceClassModel } from "../models/productPriceClassModel";
import { PaxModel } from "../../models/paxModel";
import { ShareableRoomModel } from "../models/shareableRoomModel";
import { RoomModel } from "../../models/roomModel";
import { BusDataModel } from "../../models/busDataModel";
import moment from "moment";
import { OptionProductModel } from "../models/optionProduct";

const _cancelToken = Axios.CancelToken;
let _sourceCancelFetchClassesAndCategories = _cancelToken.source();


export class pcvToursInvcprodAPI {

  private axios: AxiosInstance;
  private file: string;

  constructor(baseURL: string, file: string) {
    this.file = file;
    this.axios = Axios.create({
      baseURL: baseURL,
    })
  }

  fetchClassesAndCategories(pageCode: number, product: string, dateReservation: string, dateFrom: string, dateTo: string) {

    _sourceCancelFetchClassesAndCategories.cancel();
    _sourceCancelFetchClassesAndCategories = _cancelToken.source();

    return this.axios.post("getclassescategories.ashx",
      { product: product,
        dateReservation: moment(dateReservation).format("YYYY-MM-DD"),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD")
      },{
        cancelToken: _sourceCancelFetchClassesAndCategories.token,
        params: {
          pageCode: pageCode
        }
    }).then((response): ProductPriceClassModel[] => {

      if (response.data.listClasses) {
        let tmpListClass: ProductPriceClassModel[] = [];
        response.data.listClasses.forEach((productPriceClass: any) => tmpListClass.push(new ProductPriceClassModel(productPriceClass)))
        return tmpListClass;
      } else {
        return [];
      }

    });

  }

  fetchPax(pageCode: number, categorySelectedCode: number, dateFrom: string, dateTo: string): Promise<any> {

    return this.axios.post("getpassengers.ashx",
    {
      prodPriceCategoryCode: categorySelectedCode,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD"),
      file: this.file
    },
    {
      params: {
        pageCode: pageCode
      }
    }).then((response) => {

      if (response.data.listPax) {
        let tmpListPassenger: PaxModel[] = [];
        response.data.listPax.forEach((passenger: PaxModel) => tmpListPassenger.push(new PaxModel(passenger)))
        return tmpListPassenger;
      } else {
        return [];
      }
    })
  }

  fetchSharedRooms(pageCode: number, categorySelectedCode: number, dateFrom: string, dateTo: string): Promise<any> {

    return this.axios.post("getsharedrooms.ashx",
    {
      prodPriceCategoryCode: categorySelectedCode,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD")
    },
    {
      params: {
        pageCode: pageCode
      }
    }).then((response) => {

      if (response.data.listRoom) {
        let tmpListSharableRoom: ShareableRoomModel[] = [];
        response.data.listRoom.forEach((sharableRoom: ShareableRoomModel) => tmpListSharableRoom.push(new ShareableRoomModel(sharableRoom)));
        return tmpListSharableRoom;
      } else {
        return [];
      }
    })
  }

  fetchBusData(pageCode: number, categorySelectedCode: number, dateFrom: string, dateTo: string): Promise<any> {

    return this.axios.post("getbusdata.ashx",
    {
      prodPriceCategoryCode: categorySelectedCode,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD")
    },
    {
      params: {
        pageCode: pageCode
      }
    }).then((response) => {

      if (response.data) {
        return new BusDataModel(response.data);
      } else {
        return new BusDataModel(null);
      }
    })
  }

  postComplete(
    pageCode: number,
    categorySelectedCode: number,
    dateFrom: string,
    dateTo: string,
    isSharedRoom: boolean,
    listRoom: RoomModel[],
    listOption: number[],
  ): Promise<any> {

    return this.axios.post("Complete.ashx",
      {
        prodPriceCategoryCode: categorySelectedCode,
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        isSharedRoom: isSharedRoom,
        listRoom: listRoom,
        listOption: listOption,
      },
      {
        params: {
          pageCode: pageCode
        }
      }
    ).then((response) => {

      return response;

    })
  }

  fetchFileRooms(pageCode: number, categorySelectedCode: number, dateFrom: string, dateTo: string): Promise<any> {

    return this.axios.post("getfilerooms.ashx",
    {
      prodPriceCategoryCode: categorySelectedCode,
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(dateTo).format("YYYY-MM-DD")
    },
    {
      params: {
        pageCode: pageCode
      }
    }).then((response) => {

      if (response.data.listRoom) {
        let tmpListFileRooms: RoomModel[] = [];
        response.data.listRoom.forEach((fileRoom: RoomModel) => tmpListFileRooms.push(new RoomModel(fileRoom)));
        return tmpListFileRooms;
      } else {
        return [];
      }

    })
  }

  fetchOptionProduct(p_PageCode: number, p_ProductPriceCategoryCode: number, p_DateFrom: string, p_DateTo: string, p_DateReserv: string, p_NbRoom: number, p_NbPax: number)
  {
    return this.axios.post("getoptionproducts.ashx",
    {
      prodPriceCategoryCode: p_ProductPriceCategoryCode,
      dateFrom: moment(p_DateFrom).format("YYYY-MM-DD"),
      dateTo: moment(p_DateTo).format("YYYY-MM-DD"),
      dateReserv: moment(p_DateReserv).format("YYYY-MM-DD"),
      nbRoom: p_NbRoom,
      nbPax: p_NbPax
    },
    {
      params: {
        pageCode: p_PageCode
      }
    }).then((response) => {

      if (response.data.listProduct) {
        return response.data.listProduct;
      } else {
        return [];
      }

    })
  }

}


export class PCVToursModel {

    readonly imgPrevious1: string = '../_Images/Previous4.png';
    readonly imgPrevious2: string = '../_Images/Previous5.png';
    readonly imgNext1: string = '../_Images/Next4.png';
    readonly imgNext2: string = '../_Images/Next5.png';
    readonly imgComplete1: string = '../_Images/OK4.png';
    readonly imgComplete2: string = '../_Images/OK5.png';
    readonly imgCancel1: string = '../_Images/Cancel4.png';
    readonly imgCancel2: string = '../_Images/Cancel5.png';

}
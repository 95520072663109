
















































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { numberFormat, typeFunctionNext } from '../../utilities/utils';
import RoomPaxManagement from "../../components/roomPaxManagement.vue";
import { EnumPagination } from "../utilities/enum";
import { OptionProductModel } from "../models/optionProduct";

@Component({
    components: {
        "room-pax-management": RoomPaxManagement
    }
})
export default class ProductOption extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    beforeMount() {
        this.SendFunctionNext();
    }

    @Emit("receive-function-next")
    SendFunctionNext(){
        return this.functionNext;
    }

    getAvailability(p_Option: OptionProductModel): string
    {
        if (p_Option.hasInventory)
        {
            return p_Option.availability.toString();
        }
        else
        {
            return this.propData.language.optionInfinite;
        }
    }

    logicNext() {
        this.propData.previousPage.push(this.propData.currentPage);
        this.propData.currentPage = EnumPagination.Confirmation;
    }

    functionNext: typeFunctionNext = () => {
        this.logicNext();
    }

    totalFormat(p_Total: number): string
    {
        return numberFormat(p_Total, 2);
    }

    getOptionClassCategory(p_Option: OptionProductModel): string
    {
        if ((p_Option.pricingClassName.length > 0) && (p_Option.pricingCategoryName.length > 0))
        {
            return `${ p_Option.pricingClassName } / ${ p_Option.pricingCategoryName }`;
        }
        else if (p_Option.pricingClassName.length > 0)
        {
            return p_Option.pricingClassName;
        }
        else if (p_Option.pricingCategoryName.length > 0)
        {
            return p_Option.pricingCategoryName;
        }
        else
        {
            return "";
        }
    }

    onClick(p_Option: OptionProductModel)
    {
        if (!((p_Option.hasInventory) && (p_Option.availability < p_Option.nbRequested)))
        {
            let index: number = this.propData.listOptionSelected.findIndex((optionCode) => optionCode === p_Option.code);
            if (index !== -1)
            {
                this.$delete(this.propData.listOptionSelected, index);
            }
            else
            {
                this.propData.listOptionSelected.push(p_Option.code);
            }
        }
    }


    @Emit("on-error")
    emitError(errorMsg: string) {
        return errorMsg;
    }

}


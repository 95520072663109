




































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { PCVBusModel } from "../models/pcvBusModel";
import EditableInput from "@/components/common/EditableInput.vue";
import { PaxModel } from '../../models/paxModel';
import { iconValueArray } from '../models/iconValueArray';

@Component({
  components: {
    "editable-input": EditableInput
  }
})
export default class BusMain extends Vue {

  @Prop({ type: Object }) readonly propData!: PCVBusModel;

  seatEditSelected: [number, number] = [0,0]

  get getStructurePax() {
    return this.propData.getStructurePax;
  }

  getPax(row: string, col: string) {
    if (this.getStructurePax.length > 0) {
      return this.getStructurePax[parseInt(row) - 1][parseInt(col) - 1];
    } else {
      return null;
    }
  }

  get getIsConception(): boolean {
    return this.propData.getIsConception;
  }

  onHideEdit() {
    this.seatEditSelected = [0,0];
  }

  isDraggableSeat(row: string, col: string) {

    if (this.getIsConception) {
      let item: string = this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1];
      if (item === 'T' || item === 'G' || item === 'X') {
        return true;
      } else {
        return false
      }
    } else {
      let pax: PaxModel | null = this.getStructurePax[parseInt(row) - 1][parseInt(col) - 1];
      if (pax) {
        if (!pax.locked) {
          return true;
        }
      }
      return false;
    }

  }

  onDragStartSeat(event: DragEvent, row: string, col: string) {
    if (event.dataTransfer) {

      if (this.getIsConception) {
        this.propData.itemDrag = this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1];
      } else {
        this.propData.itemDrag = this.getStructurePax[parseInt(row) - 1][parseInt(col) - 1];
      }

      this.propData.originSeatDrag = [parseInt(row), parseInt(col)];
    }
  }

  onDropSeat(event: DragEvent, row: string, col: string) {

    event.preventDefault();

    if (event.dataTransfer) { 

      if (this.getIsConception) {
        let destinationValue: string = this.propData.structureSeat[parseInt(row) -1][parseInt(col) -1];

        if (this.propData.originSeatDrag[0] == 0 && this.propData.originSeatDrag[1] == 0) {
          //originSeatValue = this.propData.structureSeat[this.propData.originSeatDrag[0] -1][this.propData.originSeatDrag[1] -1];
        } else {
          if (iconValueArray.includes(destinationValue)) {
            Vue.set(this.propData.structureSeat[this.propData.originSeatDrag[0] -1], this.propData.originSeatDrag[1] -1, destinationValue)
          } else {
            Vue.set(this.propData.structureSeat[this.propData.originSeatDrag[0] -1], this.propData.originSeatDrag[1] -1, "")
          }
        }

        if (typeof this.propData.itemDrag === "string") {
          Vue.set(this.propData.structureSeat[parseInt(row) -1], parseInt(col) -1,  this.propData.itemDrag)
        }

      } else {

        let destinationPax: PaxModel | null = this.getStructurePax[parseInt(row) -1][parseInt(col) -1];


        if (destinationPax) {
          Vue.set(destinationPax, "row", 0);
          Vue.set(destinationPax, "col", 0);
        }

        if (this.propData.itemDrag instanceof PaxModel) {
          Vue.set(this.propData.itemDrag, "row", parseInt(row));
          Vue.set(this.propData.itemDrag, "col", parseInt(col));
        }

      }

    }
  }

  onDragOverSeat(event: Event, row: string, col: string) {
    if (this.getIsConception) {
      event.preventDefault();
    } else {
      
      let destinationSeatValue: string = this.propData.structureSeat[parseInt(row) -1][parseInt(col) -1];
      let pax: PaxModel | null = this.getStructurePax[parseInt(row) -1][parseInt(col) -1];

      if (!iconValueArray.includes(destinationSeatValue)) {
        if (pax) {
          if (!pax.locked) {
            event.preventDefault();
          }
        } else {
          event.preventDefault();
        }
      }

    }
    
  }

  onPressTab() {

    let row = this.seatEditSelected[0];
    let col = this.seatEditSelected[1];

    col++;

    if (col > 4){
      col = 1;
      row++;
      if (row > parseInt(this.propData.nbRowSelected)) {
        row = 1;
      }
    }

    this.seatEditSelected = [row, col];

  }

  onPressShiftTab() {

    let row = this.seatEditSelected[0];
    let col = this.seatEditSelected[1];

    col--;

    if (col < 1){
      col = 4;
      row--;
      if (row < 1) {
        row = parseInt(this.propData.nbRowSelected);
      }
    }

    this.seatEditSelected = [row, col];

  }

  onLeftClickSeat(row: string, col: string) {
    if (this.getIsConception) {
      if (iconValueArray.includes(this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1])) {
        Vue.set(this.propData.structureSeat[parseInt(row) - 1], (parseInt(col) - 1), "");
      }
    }
  }

  onDblClickSeat(row: string, col: string) {
    if (this.getIsConception) {
      if (iconValueArray.includes(this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1])) {
        Vue.set(this.propData.structureSeat[parseInt(row) - 1], (parseInt(col) - 1), "");
      }
    }
  }

  onClickSeat(row: string, col: string){

    if (this.getIsConception) {
      if (this.propData.itemClicked) {
        Vue.set(this.propData.structureSeat[parseInt(row) - 1], (parseInt(col) - 1), this.propData.itemClicked);
      } else {
        if (!iconValueArray.includes(this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1])) {
          this.seatEditSelected = [parseInt(row), parseInt(col)];
        }
      }
    } else {

      let paxClicked: PaxModel | null = this.getStructurePax[parseInt(row) - 1][parseInt(col) - 1];
      if (paxClicked) {
        if (!paxClicked.locked) {
          if (paxClicked === this.propData.itemClicked) {
            this.$set(paxClicked, "row", 0);
            this.$set(paxClicked, "col", 0);
          } else {
            this.propData.itemClicked = paxClicked;
          }
          
        }
      } else if (this.propData.itemClicked instanceof PaxModel) {
        let seatValue: string = this.propData.structureSeat[parseInt(row) - 1][parseInt(col) - 1];
        if (!iconValueArray.includes(seatValue)) {
          Vue.set(this.propData.itemClicked, "row", parseInt(row));
          Vue.set(this.propData.itemClicked, "col", parseInt(col));

          let paxNotAssignedFound = this.propData.listPax.find((pax: PaxModel) => !pax.locked && pax.col === 0 && pax.row === 0 );

          if (paxNotAssignedFound) {
            this.propData.itemClicked = paxNotAssignedFound;
          }

        }
      }
    }
  }

  getClassPax(row: string, col: string){

    let pax = this.getPax(row, col);

    if (pax) {
      if (pax.locked) {
        return "pcv-bus-seat-seat-locked";
      } else if (this.propData.paxMouseOver === pax) {
        return "pcv-bus-seat-seat-hover";
      } else {
        return "pcv-bus-seat-seat-taken";
      }
    }
    
    return "";

  }

  onMouseOver(row: string, col: string){
    let pax = this.getPax(row, col);

    if (pax) {
      this.propData.paxMouseOver = pax;
    }

  }

  onMouseLeave() {
    this.propData.paxMouseOver = null;
  }

}
























































import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { PCVToursInvcprodModel } from '../models/pcvToursInvcprodModel';
import { EnumSharingType, EnumPagination } from '../utilities/enum';
import { typeFunctionNext } from '../../utilities/utils';
import { RoomModel } from '../../models/roomModel';

@Component
export default class RoomSharingType extends Vue {

    @Prop({ type: Object }) readonly propData!: PCVToursInvcprodModel;

    nbNewRoom: string = "1";

    get getEnumSharingType(): typeof EnumSharingType {
        return EnumSharingType;
    }

    beforeMount() {
        this.sendFunctionNext();
        if (this.propData.listRoom.length > 0){
          this.nbNewRoom = this.propData.listRoom.length.toString();
        } else {
          let nbPax: number = Math.max(this.propData?.nbPax ?? 1, 1);
          let nbPerRoom: number = Math.max(this.propData?.categorySelected?.nbPaxMinimum ?? 1, 1);
          this.nbNewRoom = Math.trunc(nbPax / nbPerRoom).toString();
        }
    }

    @Emit("receive-function-next")
    sendFunctionNext(){
        return this.functionNext;
    }

    @Emit("on-error")
    emitError(errorMsg: string){
      return errorMsg;
    }

    get getNbNewRoom(): number
    {
        let value = parseInt(this.nbNewRoom);
        return value || 0;
    }

    setListRoom() {
      this.propData.listRoom = [];

      for (let nbRoom = 0; nbRoom < this.getNbNewRoom; nbRoom++) {
          this.propData.listRoom.push(new RoomModel(null));
      }

    }

    logicNext() {

      switch (this.propData.roomSharingTypeSelected) {
        case EnumSharingType.NotShared: {
          if (this.propData.hasInventory) {
            if (this.propData.classSelected.availability >= this.getNbNewRoom) {
              this.setListRoom();
              this.propData.previousPage.push(this.propData.currentPage);
              this.propData.currentPage = EnumPagination.RoomPax;
            } else {
              this.emitError(this.propData.language.errorInsufficientInventory);
            }
          } else {
            this.setListRoom();
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.RoomPax;
          }
          break;
        }
        case EnumSharingType.NewShared: {
          if (this.propData.hasInventory) {
            if (this.propData.classSelected.availability > 0) {
              this.propData.listRoom = [];
              this.propData.listRoom.push(new RoomModel(null));
              this.propData.previousPage.push(this.propData.currentPage);
              this.propData.currentPage = EnumPagination.PaxSelection;
            } else {
              this.emitError(this.propData.language.errorInsufficientInventory);
            }
          } else {
            this.propData.listRoom = [];
            this.propData.listRoom.push(new RoomModel(null));
            this.propData.previousPage.push(this.propData.currentPage);
            this.propData.currentPage = EnumPagination.PaxSelection;
          }
          break;
        }
        case EnumSharingType.ExistingShared: {
          this.propData.previousPage.push(this.propData.currentPage);
          this.propData.currentPage = EnumPagination.SharableRoom;
          break;
        }
      }
    }

    functionNext: typeFunctionNext = () => {
      this.logicNext();
    }

}


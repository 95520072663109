







import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import Cropper from 'cropperjs';

@Component
export default class PCVCropper extends Vue {

  Cropper: Cropper | null = null;
  IsClear: boolean = false;

  @Prop({ type: String, default: "" }) propImage!: string;
  @Prop({ type: Number, default: 0 }) propWidth!: number;
  @Prop({ type: Number, default: 0 }) propHeight!: number;
  @Prop({ type: Object, default: () => { return {} } }) propOption!: any;

  beforeDestroy() {
    if (this.Cropper) {
      this.Cropper.destroy();
    }
  }

  clear() {
    this.IsClear = true;
    if (this.Cropper) {
      this.Cropper.destroy();
    }
  }

  onLoad(event: Event) {

    if (this.Cropper) {
      this.Cropper.destroy();
    }

    var image: HTMLImageElement = event.target as HTMLImageElement;

    var self = this;

    this.Cropper = new Cropper(image, {
      viewMode: (1 as Cropper.ViewMode),
      dragMode: (self.propOption.dragMode as Cropper.DragMode) || ("move" as Cropper.DragMode),
      data: {
        width: self.propWidth,
        height: self.propHeight,
        x: ((image as HTMLImageElement).naturalWidth) / 2 - (self.propWidth / 2),
        y: ((image as HTMLImageElement).naturalHeight) / 2 - (self.propHeight / 2),
        rotate: 0,
        scaleX: 1,
        scaleY: 1
      },
      scalable: false,
      restore: false,
      guides: false,
      center: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      responsive: false,
      rotatable: false,
      zoomable: self.propOption.zoomable || true

    });
  }

  @Watch("propWidth")
  OnChangePropWidth(newValue: number) {
    if (this.Cropper) {
      this.Cropper.setData({
        width: newValue,
        height: this.propHeight,
        x: (this.Cropper.getImageData().naturalWidth / 2) - (newValue / 2),
        y: (this.Cropper.getImageData().naturalHeight / 2)  - (this.propHeight / 2),
        rotate: 0,
        scaleX: 1,
        scaleY: 1
      })
    }
  }

  @Watch("propHeight")
  OnChangePropHeight(newValue: number) {
    if (this.Cropper) {
      this.Cropper.setData({
        width: this.propWidth,
        height: newValue,
        x: (this.Cropper.getImageData().naturalWidth / 2) - (this.propWidth / 2),
        y: (this.Cropper.getImageData().naturalHeight / 2) - (newValue / 2),
        rotate: 0,
        scaleX: 1,
        scaleY: 1
      })
    }
  }


  getCroppedCanvas() {
    if (this.Cropper) {
      return this.Cropper.getCroppedCanvas({ width: this.propWidth, height: this.propHeight });
    } else {
      return null;
    }
  }

}



import { initObject } from "../../utilities/utils";
import { ProductPriceCategoryModel } from "./productPriceCategoryModel";

export class ProductPriceClassModel {

    code: number = 0;
    name: string = "";
    availability: number = 0;
    pricingClass: number = 0;
    listCategories: ProductPriceCategoryModel[] = [];


    constructor(value: any = null) {
        initObject(this, value);
    }

}